import React from "react";
import ResultFormSigning from "./ResultFormSigning";

const ResultFormTableSummary = ({ info, pageNumber, formTitle }) => {
  return (
    <>
      <div className="final-result">
        <div className="final-comments">
          <div className="final-comment-header">
            <h3>Special Instructions</h3>
            <span>Page &nbsp; {pageNumber} </span>
          </div>
          <p>
            1. %50 down payment to place an order is required, this amount is non-refundable and %50 remaining balance
            is due before delivery.
          </p>
          <p>2. All Sales are final, No changes or cancellation shall be accepted after placing the order.</p>
          <p>
            3. Blinds Factory.ca Inc. shall not be responsible for delay of product due to any circumstances beyond its
            control.
          </p>
          <p>
            4. For E-Transfers use info@blindsfactory.ca and for Cheques pay to the order of : Blinds Factory.ca Inc.
          </p>
          <p>
            5. Items that do not meetBlinds Factory.ca product specifications will be manufactured with a void warranty.
          </p>
          <p>
            6. Customers are encouraged to review and confirm the remaining terms and conditions available at
            <a href="https://blindsfactory.ca/terms-and-condition" style={{ fontWeight: "bold" }}>
              {" "}
              www.blindsfactory.ca/terms-and-condition
            </a>
            .
          </p>
          <ResultFormSigning formTitle={formTitle} />
        </div>

        {formTitle !== "FACTORY" && formTitle !== "Delivery Note" ? (
          <div className="result-summary">
            {/* Sub Total */}
            <div className="result-line">
              <div className="result-line-title result-line-title-first">Blinds Sub Total</div>
              <div className="result-line-title result-line-title-second"></div>
              <div className="result-line-title result-line-title-last">
                {formTitle !== "FACTORY" && formTitle !== "Delivery Note" ? `$${info.result.subTotal}` : "---"}
              </div>
            </div>
            {/* Custome Order */}
            <div className="result-line">
              <div className="result-line-title result-line-title-first">Custom Items</div>
              <div className="result-line-title result-line-title-second"></div>
              <div className="result-line-title result-line-title-last">
                {formTitle !== "FACTORY" && formTitle !== "Delivery Note" ? `$${info.result.customeItems}` : "---"}
              </div>
            </div>
            {/* Delievery */}
            <div className="result-line">
              <div className="result-line-title result-line-title-first">Other</div>
              <div className="result-line-title result-line-title-second"></div>
              <div className="result-line-title result-line-title-last">
                {formTitle !== "FACTORY" && formTitle !== "Delivery Note" ? `$${info.other.delivery}` : "---"}
              </div>
            </div>
            {/* Installation */}
            <div className="result-line">
              <div className="result-line-title result-line-title-first">Installation</div>
              <div className="result-line-title result-line-title-second"></div>
              <div className="result-line-title result-line-title-last">
                {formTitle !== "FACTORY" && formTitle !== "Delivery Note" ? `$${info.other.installation}` : "---"}
              </div>
            </div>
            {/* Discount */}
            <div className="result-line">
              <div className="result-line-title result-line-title-first">Discount</div>
              <div className="result-line-title result-line-title-second">
                {formTitle !== "FACTORY" && formTitle !== "Delivery Note" ? `%${info.other.discount}` : "---"}
              </div>
              <div className="result-line-title result-line-title-last">
                {formTitle !== "FACTORY" && formTitle !== "Delivery Note" ? `$${info.result.discount}` : "---"}
              </div>
            </div>
            {/* Accessories */}
            <div className="result-line">
              <div className="result-line-title result-line-title-first">Accessories</div>
              <div className="result-line-title result-line-title-second"></div>
              <div className="result-line-title result-line-title-last">
                {" "}
                {formTitle !== "FACTORY" && formTitle !== "Delivery Note" ? `$${info.result.accessories}` : "---"}
              </div>
            </div>
            {/* Total Before Tax */}
            <div className="result-line">
              <div className="result-line-title result-line-title-first" style={{ fontWeight: 850 }}>
                Total Before Tax
              </div>
              <div className="result-line-title result-line-title-second"></div>
              <div className="result-line-title result-line-title-last" style={{ fontWeight: 850 }}>
                {formTitle !== "FACTORY" && formTitle !== "Delivery Note" ? `$${info.result.totalBeforeTax}` : "---"}
              </div>
            </div>
            {/* HST/GST */}
            <div className="result-line">
              <div className="result-line-title result-line-title-first">HST/GST</div>
              <div className="result-line-title result-line-title-second">
                {formTitle !== "FACTORY" && formTitle !== "Delivery Note" ? `%${info.other.tax}` : "---"}
              </div>
              <div className="result-line-title result-line-title-last">
                {" "}
                {formTitle !== "FACTORY" && formTitle !== "Delivery Note" ? `$${info.result.tax}` : "---"}
              </div>
            </div>
            <div className="result-line" style={{ border: "none" }}>
              <div className="result-line-title result-line-title-first">Payment Fee</div>
              <div className="result-line-title result-line-title-second">
                {" "}
                {formTitle !== "FACTORY" && formTitle !== "Delivery Note" ? `%${info.other.paymentFee}` : "---"}
              </div>
              <div className="result-line-title result-line-title-last">
                {" "}
                {formTitle !== "FACTORY" && formTitle !== "Delivery Note" ? `$${info.result.paymentFee}` : "---"}
              </div>
            </div>
            {/* final */}
            <div className="result-divider"></div>
            {/* Total */}
            <div className="result-line">
              <div className="result-line-title result-line-title-first">
                <strong>Total</strong>
              </div>
              <div className="result-line-title result-line-title-second"></div>

              <div className="result-line-title result-line-title-last">
                <strong>
                  {" "}
                  {formTitle !== "FACTORY" && formTitle !== "Delivery Note" ? `$${info.result.total}` : "---"}
                </strong>
              </div>
            </div>
            {/* payment */}
            <div className="result-line">
              <div className="result-line-title result-line-title-first">
                <strong>Payment</strong>
              </div>
              <div className="result-line-title result-line-title-second">
                <strong>
                  {formTitle !== "FACTORY" && formTitle !== "Delivery Note"
                    ? `%${Math.round((info.result.payment / info.result.total) * 100)}`
                    : "---"}
                </strong>
              </div>
              <div className="result-line-title result-line-title-last">
                <strong>
                  {formTitle !== "FACTORY" && formTitle !== "Delivery Note"
                    ? `$${Math.round(info.result.payment)}`
                    : "---"}
                </strong>
              </div>
            </div>
            {/* balance */}
            <div className="result-line" style={{ border: "none", color: "crimson" }}>
              <div className="result-line-title result-line-title-first">
                <strong>Balance Due</strong>
              </div>
              <div className="result-line-title result-line-title-second"></div>

              <div className="result-line-title result-line-title-last">
                <strong>
                  {formTitle !== "FACTORY" && formTitle !== "Delivery Note" ? `$${info.result.balance}` : "---"}
                </strong>
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export default ResultFormTableSummary;
