import { useState } from "react";
import { useNavigate } from "react-router-dom";
import "../../styles/InvoiceTableRowData.css";

const InvoiceTableRowCustomeOrder = ({
  customeOrderInfo,
  index,
  editOrderHandlerCustome,
  editOrderCustome,
  deleteOrder,
}) => {
  const navigate = useNavigate();
  const [rowInfo, setRowInfo] = useState({ ...editOrderCustome });

  const onClickHandler = (e) => {
    e.preventDefault();
    const newRowInfo = { ...rowInfo };
    newRowInfo.index = e.target.dataset.index;
    newRowInfo.customeItem = e.target.dataset.customeitem;
    newRowInfo.comment = e.target.dataset.comment;
    newRowInfo.discount = e.target.dataset.discount;
    newRowInfo.unitPrice = e.target.dataset.unitprice;
    newRowInfo.totalPrice = e.target.dataset.totalprice;
    newRowInfo.quantity = e.target.dataset.quantity;
    editOrderHandlerCustome({ ...newRowInfo });
    setRowInfo({ ...newRowInfo });
    navigate("/custome");
  };
  // delete order ////////////////////////////////////////////////////////
  const onClickHandlerDelete = (e) => {
    e.preventDefault();
    deleteOrder(e.target.dataset.index, "custome");
  };

  return (
    <tr className="table-data-parent">
      <td
        className="table-data edit"
        onClick={(e) => {
          onClickHandler(e);
        }}
        data-index={index}
        data-customeitem={customeOrderInfo.customeitem.inputData}
        data-discount={customeOrderInfo.discount.inputData}
        data-unitprice={customeOrderInfo.unitPrice.inputData}
        data-comment={customeOrderInfo.comment.inputData}
        data-totalprice={customeOrderInfo.totalPrice.inputData}
        data-quantity={customeOrderInfo.quantity.inputData}
      >
        <i className=" fa fa-edit edit-icon"></i>
      </td>

      <td className="table-data  row">{index + 1}</td>
      <td className="table-data area">{customeOrderInfo.customeitem.inputData}</td>
      <td className="table-data  quantity">{customeOrderInfo.quantity.inputData}</td>
      <td className="table-data  width  "> </td>
      <td className="table-data  length custome-width"> </td>
      <td className="table-data accessories-table placement"></td>
      <td className="table-data accessories-table color"></td>
      <td className="table-data accessories-table manufacture"></td>
      <td className="table-data accessories-table category"></td>
      <td className="table-data accessories-table lightcontrol"></td>
      <td className="table-data accessories-table fabric"></td>
      <td className="table-data accessories-table Manual"></td>
      <td className="table-data accessories-table position"></td>
      <td className="table-data accessories-table cassette-type"></td>
      <td className="table-data accessories-table cassette-color"></td>
      <td className="table-data accessories-table layout"></td>
      <td className="table-data accessories-table comment custome-comment"> {customeOrderInfo.comment.inputData}</td>
      <td className="table-data accessories-table unit-price"> $ {customeOrderInfo.unitPrice.inputData}</td>
      <td className="table-data  total-price">$ {customeOrderInfo.totalPrice.inputData}</td>
      <td
        className="table-data delete"
        onClick={(e) => {
          onClickHandlerDelete(e);
        }}
        data-index={index}
      >
        <i className=" fa fa-trash edit-icon"></i>
      </td>
    </tr>
  );
};

export default InvoiceTableRowCustomeOrder;
