import "../../styles/AppHeader.css";
import AppMenu from "./AppMenu";

const AppHeader = ({ setResetData, authenticatedUser }) => {
  return (
    <>
      {/* <div className="dashboard">
        <img src={"resources/images/nilshades-logo.png"} alt="Nili Group"></img>
        <h1>Smart Blinds & Shades Measurement Application</h1>
        <h2>Empowering Your Windows with Cutting-Edge Technology</h2>
      </div> */}
      <AppMenu setResetData={setResetData} authenticatedUser={authenticatedUser} />
    </>
  );
};
export default AppHeader;
