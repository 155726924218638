import "../../styles/InvoiceTableHeading.css";
const InvoiceTableHeadingAccessories = () => {
  return (
    <>
      <tr>
        <td className="table-header">Accessories</td>
      </tr>
      <tr className="table-heading-parent accessories-table-data-parent">
        {/* <th className="table-heading edit">Edit</th>
        <th className="table-heading row">Row</th>
        <th className="table-heading area">Item</th>
        <th className="table-heading quantity">Quantity</th>
        <th className="table-heading manufacture">Manufacture</th>
        <th className="table-heading model">Model</th>
        <th className="table-heading unit-price">Unit Price</th>
        <th className="table-heading total-price">Total Price</th> */}

        <th className="table-heading edit">Edit</th>
        <th className="table-heading row">Row</th>
        <th className="table-heading area">Item</th>
        <th className="table-heading quantity">QTY</th>
        <th className="table-heading width">Factory</th>
        <th className="table-heading length">Model</th>
        <th className="table-heading placement"></th>
        <th className="table-heading color"></th>
        <th className="table-heading manufacture"></th>
        <th className="table-heading category"></th>
        <th className="table-heading lightcontrol"></th>
        <th className="table-heading fabric"></th>
        <th className="table-heading Manual"></th>
        <th className="table-heading position"></th>
        <th className="table-heading cassette-type"></th>
        <th className="table-heading cassette-color"></th>
        <th className="table-heading layout"></th>
        <th className="table-heading comment"></th>
        <th className="table-heading unit-price">Unit Price</th>
        <th className="table-heading total-price">Total Price</th>
        <th className="table-heading delete">Delete</th>
      </tr>
    </>
  );
};

export default InvoiceTableHeadingAccessories;
