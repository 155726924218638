import { useState } from "react";
import { useNavigate } from "react-router-dom";
import "../../styles/InvoiceTableRowData.css";

const InvoiceTableRowDataAccessories = ({
  accessoriesInformation,
  index,
  editOrderHandlerAccessories,
  editOrderAccessories,
  deleteOrder,
}) => {
  const navigate = useNavigate();
  const [rowInfo, setRowInfo] = useState({ ...editOrderAccessories });
  const onClickHandler = (e) => {
    e.preventDefault();
    const newRowInfo = { ...rowInfo };
    newRowInfo.index = e.target.dataset.index;
    newRowInfo.accessories = e.target.dataset.accessories;
    newRowInfo.manufactures = e.target.dataset.manufactures;
    newRowInfo.models = e.target.dataset.models;
    newRowInfo.discount = e.target.dataset.discount;
    newRowInfo.unitPrice = e.target.dataset.unitprice;
    newRowInfo.totalPrice = e.target.dataset.totalprice;
    newRowInfo.quantity = e.target.dataset.quantity;

    editOrderHandlerAccessories({ ...newRowInfo });
    setRowInfo({ ...newRowInfo });
    // console.log("new info", newRowInfo);
    navigate(`/accessories`);
  };
  // delete order ////////////////////////////////////////////////////////
  const onClickHandlerDelete = (e) => {
    e.preventDefault();
    deleteOrder(e.target.dataset.index, "accessory");
  };

  return (
    <tr className="table-data-parent">
      <td
        className="table-data edit"
        onClick={(e) => {
          onClickHandler(e);
        }}
        data-index={index}
        data-accessories={accessoriesInformation.accessories.id}
        data-manufactures={accessoriesInformation.manufactures.id}
        data-models={accessoriesInformation.models.id}
        data-discount={accessoriesInformation.discount.inputData}
        data-unitprice={accessoriesInformation.unitPrice.inputData}
        data-totalprice={accessoriesInformation.totalPrice.inputData}
        data-quantity={accessoriesInformation.quantity.inputData}
      >
        <i className=" fa fa-edit edit-icon"></i>
      </td>

      <td className="table-data  row">{index + 1}</td>
      <td className="table-data area">{accessoriesInformation.accessories.inputData}</td>
      <td className="table-data  quantity">{accessoriesInformation.quantity.inputData}</td>
      <td className="table-data  width"> {accessoriesInformation.manufactures.inputData}</td>
      <td className="table-data  length">{accessoriesInformation.models.inputData}</td>
      <td className="table-data accessories-table placement"></td>
      <td className="table-data accessories-table color"></td>
      <td className="table-data accessories-table manufacture"></td>
      <td className="table-data accessories-table category"></td>
      <td className="table-data accessories-table lightcontrol"></td>
      <td className="table-data accessories-table fabric"></td>
      <td className="table-data accessories-table Manual"></td>
      <td className="table-data accessories-table position"></td>
      <td className="table-data accessories-table cassette-type"></td>
      <td className="table-data accessories-table cassette-color"></td>
      <td className="table-data accessories-table layout"></td>
      <td className="table-data accessories-table comment"></td>
      <td className="table-data accessories-table unit-price"> $ {accessoriesInformation.unitPrice.inputData}</td>
      <td className="table-data  total-price">$ {accessoriesInformation.totalPrice.inputData}</td>
      <td
        className="table-data delete"
        onClick={(e) => {
          onClickHandlerDelete(e);
        }}
        data-index={index}
      >
        <i className=" fa fa-trash edit-icon"></i>
      </td>
    </tr>
  );
};
export default InvoiceTableRowDataAccessories;
