import { useEffect, useState } from "react";
import DropDownMaker from "../others/DropDownMaker";
import getDropDownItems from "../handler/getDropDownInfo";
import { useNavigate } from "react-router";
import "../../styles/Accessories.css";
const Accessories = ({
  accessoriesInformationHandler,
  editOrderAccessories,
  editOrderHandlerAccessories,
  accessoriesInformation,
  setAccessoriesInformation,
}) => {
  const { accessories } = getDropDownItems;
  const inputDataItem = {
    accessories: {
      requieredState: {
        isRequiered: true,
        theState: false,
      },
      inputData: "",
      id: "",
    },
    manufactures: {
      requieredState: {
        isRequiered: true,
        theState: false,
      },
      inputData: "",
      id: "",
    },
    models: {
      requieredState: {
        isRequiered: true,
        theState: false,
      },
      inputData: "",
      id: "",
    },
    quantity: {
      requieredState: {
        isRequiered: true,
        theState: false,
      },
      inputData: "",
      id: "",
    },
    discount: {
      requieredState: {
        isRequiered: false,
        theState: true,
      },
      inputData: "",
      id: "",
    },
    unitPrice: {
      requieredState: {
        isRequiered: false,
        theState: true,
      },
      inputData: "",
      id: "",
    },
    totalPrice: {
      requieredState: {
        isRequiered: false,
        theState: true,
      },
      inputData: "",
      id: "",
    },
  };
  const navigate = useNavigate();

  // Edit Order  //////////////////////////////////////////////////////////////////////////
  const [editedOrder, setEditedOrder] = useState(false);
  useEffect(() => {
    if (editOrderAccessories.index) {
      setEditedOrder(true);
      const newInputData = { ...inputData };
      // console.log(editOrderAccessories);
      accessories.accessories.forEach((item) => {
        // eslint-disable-next-line
        if (item.id == editOrderAccessories.accessories) {
          selectedItemHandler(item.title, "accessories", item.id);
          dropDownHandler("accessories");
          item.manufactures.forEach((manu) => {
            // eslint-disable-next-line
            if (manu.id == editOrderAccessories.manufactures) {
              selectedItemHandler(manu.title, "manufactures", manu.id);
              dropDownHandler("manufactures");
              manu.items.forEach((mod) => {
                // eslint-disable-next-line
                if (mod.id == editOrderAccessories.models) {
                  selectedItemHandler(mod.title, "models", mod.id);
                  dropDownHandler("models");
                }
              });
            }
          });
        }
      });

      // newInputData.quantity.inputData = editOrderAccessories.quantity;
      dataSetter("quantity", editOrderAccessories.quantity, "");
      // newInputData.discount.inputData = editOrderAccessories.discount;
      dataSetter("discount", editOrderAccessories.discount, "");
      setDisabledItems({
        manufactures: true,
        models: true,
        quantity: true,
        discount: true,
      });
      setInputData({ ...newInputData });
      setIsRequiered(true);
    }
    // eslint-disable-next-line
  }, [editOrderAccessories]);

  // data setter /////////////////////////////////////////////////////////////////////////////////
  const dataSetter = (dataName, dataValue, elementId) => {
    // console.log(dataName, dataValue, elementId);
    const newInputData = { ...inputData };
    // console.log(dataName);
    newInputData[dataName].inputData = dataValue;
    newInputData[dataName].id = elementId;
    newInputData[dataName].inputData
      ? (newInputData[dataName].requieredState.theState = true)
      : (newInputData[dataName].requieredState.theState = false);

    // change dropdown lock
    const newDropdownStatus = { ...dropdownStatus };

    if (dataName === "accessories") {
      if (newInputData[dataName].inputData) {
        newDropdownStatus["manufactures"].state = true;
      } else {
        newDropdownStatus["manufactures"].state = false;
      }
    }
    if (dataName === "manufactures") {
      if (newInputData[dataName].inputData) {
        newDropdownStatus["models"].state = true;
      } else {
        newDropdownStatus["models"].state = false;
      }
    }
    if (dataName === "models") {
      newInputData.quantity.inputData = "";
      newInputData.discount.inputData = "";
    }

    Object.keys(newDropdownStatus).forEach((key) => {
      newDropdownStatus[key].display = { display: "none" };
      newDropdownStatus[key].transform = { transform: "rotate(0deg)" };
    });
    // console.log(newDropdownStatus);
    setDropdownStatus({ ...newDropdownStatus });
    setInputData({ ...newInputData });
    requiedHandler();
  };

  // is require handler + input Data Handler //////////////////////////////////////////////////////
  const initialInputData = { ...inputDataItem };
  const [isRequiered, setIsRequiered] = useState(false);
  const requiedHandler = () => {
    setIsRequiered(true);
    const newInputData = { ...inputData };
    // console.log(newInputData);
    const requierds = Object.keys(newInputData).map((item) => {
      if (newInputData[item].requieredState.isRequiered) {
        return newInputData[item].requieredState.theState;
      } else {
        return true;
      }
    });
    // console.log(requierds);
    requierds.find((item) => {
      if (item === false) {
        setIsRequiered(false);
      }
      return null;
    });
    // console.log(isRequiered)
  };
  // Drop down filter handler - selected item handler ///////////////////////////////////////////
  const subDropdownItem = {
    accessories: {
      id: null,
      manufactures: [],
    },
    manufactures: {
      id: null,
      manufactures: [],
    },
    models: {
      id: null,
      models: [],
    },
  };
  const [filteredCategory, setFilteredCategory] = useState({ ...subDropdownItem });

  // select item handler
  const selectedItemHandler = (dataSelected, dataName, elementId) => {
    // console.log(dataSelected, dataName, elementId);
    const newInputData = { ...inputData };
    dataSetter(dataName, dataSelected, elementId);
    // console.log(elementId);
    // const x = manufactures.manufactures;
    const newFilteredCategory = { ...filteredCategory };
    if (dataName === "accessories") {
      newFilteredCategory.accessories.id = elementId;
      // console.log(newFilteredCategory.accessories.id);
      accessories.accessories.forEach((item) => {
        // eslint-disable-next-line
        if (item.id == elementId) {
          newFilteredCategory.manufactures.manufactures = item.manufactures;
          newFilteredCategory.models.models = [];
          dataSetter("manufactures", "", "");
          dataSetter("models", "", "");
          newInputData.discount.inputData = "";
          newInputData.quantity.inputData = "";
        }
      });
    }

    if (dataName === "manufactures") {
      // console.log("dataname", dataName);
      // console.log(newFilteredCategory.manufactures.manufactures);
      newFilteredCategory.manufactures.id = elementId;
      newFilteredCategory.manufactures.manufactures.forEach((item) => {
        // eslint-disable-next-line
        if (item.id == elementId) {
          newFilteredCategory.models.models = item.items;
          dataSetter("models", "", "");
          newInputData.discount.inputData = "";
          newInputData.quantity.inputData = "";
        }
      });
    }
    setInputData({ ...newInputData });
    setFilteredCategory({ ...newFilteredCategory });
    disableHandler(dataName);
  };

  // console.log(accessories.accessories);
  useEffect(() => {
    // console.log(filteredCategory);
  }, [filteredCategory]);

  // Form Submit Handler ////////////////////////////////////////////////
  const [invoiceData, setInvoiceData] = useState([]);
  const formSubmitHandler = (e) => {
    e.preventDefault();
    // console.log("form submited");
    // console.log(isRequiered);
    requiedHandler();
    if (!isRequiered) {
      alert("Fill all required fields");
    } else {
      if (!editedOrder) {
        setIsRequiered(false);
        // console.log(invoiceData);
        const newInvoiceData = [...invoiceData];
        const newInputData = { ...inputData };
        const price = priceCalculator(newInputData);
        newInputData.unitPrice.inputData = price.unitPrice;
        newInputData.totalPrice.inputData = price.totalPrice;
        newInvoiceData.push({ ...newInputData });
        setInvoiceData([...newInvoiceData]);
        resetForm();
      } else {
        const newAccessoriesInformation = [...accessoriesInformation];
        const newInputData = { ...inputData };
        const price = priceCalculator(newInputData);
        newInputData.unitPrice.inputData = price.unitPrice;
        newInputData.totalPrice.inputData = price.totalPrice;
        newAccessoriesInformation[editOrderAccessories.index * 1] = { ...newInputData };

        setAccessoriesInformation([...newAccessoriesInformation]);
        editOrderHandlerAccessories({
          index: "",
          accessories: "",
          manufactures: "",
          models: "",
          discount: "",
          unitPrice: "",
          totalPrice: "",
          quantity: "",
        });
        setEditedOrder(false);
        navigate("/result");
      }
    }
  };

  useEffect(() => {
    if (invoiceData.length > 0) {
      // console.log(invoiceData);
      // console.log("invoice data", invoiceData);

      accessoriesInformationHandler(invoiceData);
      setInvoiceData([]);
    }
    // eslint-disable-next-line
  }, [invoiceData]);

  // useEffect(() => {
  //   if (isRequiered) {
  //     const newInvoiceData = [...invoiceData];
  //     const newInputData = { ...inputData };
  //     newInvoiceData.push({ ...newInputData });
  //     setInvoiceData([...newInvoiceData]);
  //     resetForm();
  //     // console.log(finalSize.width, finalSize.length);
  //   } else {
  //     //   console.log("error", finalSize.width, finalSize.length, isRequiered, validData);
  //   }
  // }, [isRequiered]);

  // Reset Form Handler  ////////////////////////////////////////////////
  const resetForm = () => {
    setIsRequiered(false);
    const newDropdownStatus = { ...dropdownStatus };
    Object.keys(newDropdownStatus).forEach((key) => {
      newDropdownStatus[key].display = { display: "none" };
      newDropdownStatus[key].transform = { transform: "rotate(0deg)" };
    });
    newDropdownStatus["manufactures"].state = false;
    newDropdownStatus["models"].state = false;
    setDropdownStatus({ ...newDropdownStatus });
    setInputData({ ...initialInputData });
    setDisabledItems({ ...disabledItem });
    // setFinalSize({
    //   width: 0,
    //   widthDec: 0,
    //   length: 0,
    //   lengthDec: 0,
    //   controllerPrice: 0, // motorized or manual
    //   price: 0,
    //   unitPrice: 0,
    // });
  };

  // Price Calculator ////////////////////////////////////////////////////
  const priceCalculator = (data) => {
    let unitPrice = 0;
    let totalPrice = 0;
    accessories.accessories.forEach((acc) => {
      // eslint-disable-next-line
      if (data.accessories.id == acc.id) {
        acc.manufactures.forEach((man) => {
          // eslint-disable-next-line
          if (man.id == data.manufactures.id) {
            man.items.forEach((item) => {
              // eslint-disable-next-line
              if (item.id == data.models.id) {
                // console.log(item.price);
                // console.log(data.quantity.inputData * 1);
                unitPrice = Math.round(item.price);
                totalPrice = Math.round(unitPrice * data.quantity.inputData);
                if (data.discount.inputData) {
                  unitPrice = Math.round(unitPrice - (unitPrice * data.discount.inputData) / 100);
                  totalPrice = Math.round(unitPrice * data.quantity.inputData);
                }
              }
            });
          }
        });
        // console.log(acc);
      }
    });
    return { unitPrice, totalPrice };
  };

  // Drop Down Handler //////////////////////////////////////////////////
  const dropdownItems = {
    accessories: {
      display: { display: "none" },
      transform: { transform: "rotate(0deg)" },
      state: true,
    },
    manufactures: {
      display: { display: "none" },
      transform: { transform: "rotate(0deg)" },
      state: false,
    },
    models: {
      display: { display: "none" },
      transform: { transform: "rotate(0deg)" },
      state: false,
    },
  };

  const [dropdownStatus, setDropdownStatus] = useState({ ...dropdownItems });
  const dropDownHandler = (data) => {
    // const item = e.target.dataset.name;
    const item = data;
    // console.log("item:", item);
    dropdownStatus[item].state && dropDownHelper(item);
    if (item === "manufactures" && !dropdownStatus[item].state) {
      alert("Select accessory first");
    }
    if (item === "models" && !dropdownStatus[item].state) {
      alert("Select manufacture first");
    }
    if (item === "quantity" && !dropdownStatus[item].state) {
      alert("Select ligh model first");
    }

    // console.log("DropDown Handler");
    // console.log(e.target.dataset.name);
    disableHandler(item);
  };

  const dropDownHelper = (item) => {
    // console.log("helper", item);
    // console.log(dropdownItems);
    // Check if the element exists in the dropdownItems object
    if (dropdownItems.hasOwnProperty(item)) {
      // console.log('check')
      // If the element is already visible, toggle its display and rotation
      if (
        dropdownStatus[item].display.display !== "none" &&
        dropdownStatus[item].transform.transform !== "rotate(0deg)"
      ) {
        // Create a new object to hold the updated dropdownStatus
        const updatedDropdownStatus = { ...dropdownStatus };

        // Toggle display and rotation for the specified element
        updatedDropdownStatus[item] = {
          ...updatedDropdownStatus[item],
          display: { display: "none" },
          transform: { transform: "rotate(0deg)" },
        };

        // Log the updated dropdownStatus object
        // console.log(updatedDropdownStatus);

        // Update the state with the updated dropdownStatus
        setDropdownStatus(updatedDropdownStatus);

        // Update selected value
      } else {
        // If the item is not visible, update its properties as before
        const updatedDropdownItems = { ...dropdownStatus };

        // Update the specific properties for the specified item
        updatedDropdownItems[item] = {
          ...updatedDropdownItems[item],
          display: { display: "grid" },
          transform: { transform: "rotate(180deg)" },
        };

        // Reset other properties to their original values
        Object.keys(updatedDropdownItems).forEach((key) => {
          if (key !== item) {
            updatedDropdownItems[key].display = { display: "none" };
            updatedDropdownItems[key].transform = { transform: "rotate(0deg)" };
          }
        });

        // Log the updated dropdownItems object
        // console.log(updatedDropdownItems);

        // Update the state with the updated dropdownStatus
        // console.log(updatedDropdownItems)
        setDropdownStatus(updatedDropdownItems);
        // setSelectedValues({ ...selectedValues, [element]: selectedValue });
      }
      // console.log(dropdownItems)
    } else {
      console.log("Element not found in dropdownItems object");
    }
  };

  // element disabler //////////////////////////////////////////////////////////////////////////////
  const disabledItem = {
    manufactures: false,
    models: false,
    quantity: false,
    discount: false,
  };
  const [disabledItems, setDisabledItems] = useState({ ...disabledItem });
  const disableHandler = (item) => {
    // console.log("item", item);
    const newDisabledItems = { ...disabledItems };
    if (item === "accessories" && inputData.accessories.inputData) {
      newDisabledItems["manufactures"] = true;
    }
    if (item === "manufactures" && inputData.manufactures.inputData) {
      newDisabledItems["models"] = true;
    }
    if (item === "models" && inputData.models.inputData) {
      newDisabledItems["quantity"] = true;
    }
    if (item === "quantity" && inputData.quantity.inputData) {
      newDisabledItems["discount"] = true;
    }
    if (!inputData.quantity.inputData) {
      newDisabledItems["discount"] = false;
    }
    if (!inputData.models.inputData) {
      newDisabledItems["quantity"] = false;
      newDisabledItems["discount"] = false;
    }
    if (!inputData.manufactures.inputData) {
      newDisabledItems["models"] = false;
    }
    // console.log("newDisabledItems", newDisabledItems);
    setDisabledItems({ ...newDisabledItems });
  };

  // On Change Handler //////////////////////////////////////////////////
  const [inputData, setInputData] = useState({ ...inputDataItem });
  const onChangeHandler = (e) => {
    // console.log("On change Handler");
    const newInputData = { ...inputData };
    // console.log(e.target.dataset.name, e.target.value, "");
    dataSetter(e.target.dataset.name, e.target.value, "");
    disableHandler(e.target.dataset.name);
    if (newInputData.quantity.inputData === "") {
      newInputData.discount.inputData = "";
      setInputData({ ...newInputData });
      dataSetter("discount", "", "");
    }
  };

  // JSX Handler  ///////////////////////////////////////////////////////
  return (
    <>
      <form
        className="get-accessories-form"
        onSubmit={(e) => {
          formSubmitHandler(e);
        }}
      >
        {/* Accessories */}
        <div className="dropdown-parent">
          <div className="form-input-dropdown-wrapper">
            <div className="form-input-dropdown-wrapper-header">
              <input
                onClick={(e) => dropDownHandler(e.target.dataset.name)}
                data-name="accessories"
                className="form-input-dropdown"
                type="text"
                placeholder="Accessories"
                readOnly
                value={inputData.accessories.inputData}
                data-id={inputData.accessories.id}
                onChange={(e) => {
                  onChangeHandler(e);
                }}
              ></input>
              <i className="fa fa-angle-down" style={dropdownStatus.accessories.transform}></i>
            </div>
            <div className="dropdown-content" style={dropdownStatus.accessories.display}>
              <DropDownMaker
                dropDownList={accessories.accessories}
                categoryTitle={"accessories"}
                selectedItemHandler={selectedItemHandler}
              />
            </div>
          </div>
        </div>
        {/* Manufacture */}
        <div className={`dropdown-parent ${!disabledItems.manufactures ? "disable-item" : ""}`}>
          <div className="form-input-dropdown-wrapper">
            <div className="form-input-dropdown-wrapper-header">
              <input
                onClick={(e) => dropDownHandler(e.target.dataset.name)}
                data-name="manufactures"
                className="form-input-dropdown"
                type="text"
                placeholder="Manufacture"
                readOnly
                value={inputData.manufactures.inputData}
                data-id={inputData.manufactures.id}
                onChange={(e) => {
                  onChangeHandler(e);
                }}
              ></input>
              <i className="fa fa-angle-down" style={dropdownStatus.manufactures.transform}></i>
            </div>
            <div className="dropdown-content" style={dropdownStatus.manufactures.display}>
              <DropDownMaker
                dropDownList={filteredCategory.manufactures.manufactures}
                categoryTitle={"manufactures"}
                selectedItemHandler={selectedItemHandler}
              />
            </div>
          </div>
        </div>
        {/* Model */}
        <div className={`dropdown-parent ${!disabledItems.models ? "disable-item" : ""}`}>
          <div className="form-input-dropdown-wrapper">
            <div className="form-input-dropdown-wrapper-header">
              <input
                onClick={(e) => dropDownHandler(e.target.dataset.name)}
                data-name="models"
                className="form-input-dropdown"
                type="text"
                placeholder="Model"
                readOnly
                value={inputData.models.inputData}
                data-id={inputData.models.id}
                onChange={(e) => {
                  onChangeHandler(e);
                }}
              ></input>
              <i className="fa fa-angle-down" style={dropdownStatus.models.transform}>
                {" "}
              </i>
            </div>
            <div className="dropdown-content" style={dropdownStatus.models.display}>
              <DropDownMaker
                dropDownList={filteredCategory.models.models}
                categoryTitle={"models"}
                selectedItemHandler={selectedItemHandler}
              />
            </div>
          </div>
        </div>
        {/* QTY */}
        <div className={`dropdown-parent ${!disabledItems.quantity ? "disable-item" : ""}`}>
          <div className="form-input-dropdown-wrapper">
            <input
              data-name="quantity"
              className="form-input"
              type="number"
              min="1"
              max="5000"
              placeholder="Quantity"
              value={inputData.quantity.inputData}
              data-id={inputData.quantity.id}
              onChange={(e) => {
                onChangeHandler(e);
              }}
            ></input>
          </div>
        </div>
        {/* Discount */}
        <div className={`dropdown-parent ${!disabledItems.discount ? "disable-item" : ""}`}>
          <div className="form-input-dropdown-wrapper">
            <input
              data-name="discount"
              className="form-input"
              type="number"
              min="0"
              max="100"
              placeholder="Discount"
              value={inputData.discount.inputData}
              data-id={inputData.discount.id}
              onChange={(e) => {
                onChangeHandler(e);
              }}
            ></input>
          </div>
        </div>
        {/* button */}
        <div className="form-button">
          <button className="button-reset" type="reset" onClick={() => resetForm()}>
            Clear
          </button>
          <button className="button-submit" type="submit" onClick={(e) => formSubmitHandler(e)}>
            Submit
          </button>
        </div>
      </form>
    </>
  );
};
export default Accessories;
