export const ROLE_DEVELOPPER = 101;
export const ROLE_MANAGER = 102;
export const ROLE_ADMINISTRATOR = 103;
export const ROLE_SALES = 104;
export const ROLE_INSTALLER = 105;
export const ROLE_MEASURER = 106;
export const ROLE_ACCOUNTANT = 107;
export const ROLE_CONSULTANT = 108;
export const ROLE_SUPPLIER = 109;
export const ROLE_DEALER = 110;
export const ROLE_NEWCOMER = 111;
export const ROLE_FRANCHISE = 112;
export const ROLE_CUSTOMER = 113;
export const ROLE_TRAINER = 114;
