import { useEffect, useState } from "react";

import { useNavigate } from "react-router";
import readFromDB from "../../firebase/readFromDB.js";
import writeToDB from "../../firebase/writeToDB.js";
import {
  userResetPassword,
  userEmailVerification,
  userSignUp,
  userSignIn,
  userEmailVerified,
} from "../../firebase/authentication.js";
import "../../styles/Login.css";

const Login = ({ user, setUser, loginState, setAutenticatedUser, setLoginState, setPoNumber, PoNumber }) => {
  const navigate = useNavigate();
  // login //////////////////////////////////////////////////////////////////
  // const usersList = getUsers.users;
  const [accessMethod, setAccessMethod] = useState(true);
  const [checkInput, setCheckInput] = useState(false);

  const onChangeHandler = (e) => {
    const newUser = { ...user };
    if (e.target.dataset.type === "username") {
      newUser.username = e.target.value.trim().toLowerCase();
    }
    if (e.target.dataset.type === "password") {
      newUser.password = e.target.value;
    }
    if (e.target.dataset.type === "name") {
      newUser.name = e.target.value;
    }
    if (e.target.dataset.type === "phone") {
      newUser.phone = e.target.value;
    }
    if (e.target.dataset.type === "address") {
      newUser.address = e.target.value;
    }
    if (e.target.dataset.type === "postal") {
      newUser.postal = e.target.value;
    }
    setUser({ ...newUser });
  };

  const onSignInHandler = (e) => {
    e.preventDefault();
    setCheckInput(true);
  };

  const onSignUpHandler = async (e) => {
    e.preventDefault();
    const newUser = await userSignUp(user.username, user.password, user.name);
    const userId = await readFromDB(`counters`);
    const userData = {
      username: user.username,
      name: user.name,
      phone: user.phone,
      address: user.address,
      postal: user.postal,
      uid: newUser.uid,
      isActive: true,
      role: 111,
      createAccount: newUser.metadata.creationTime,
      lastSignIn: newUser.metadata.lastSignInTime,
      userId: userId.userCounter,
      referral: null,
      referrer: null,
    };
    writeToDB(userId.userCounter + 1, "counters", "userCounter");

    writeToDB(userData, "users", newUser.uid);

    setAccessMethod(true);
    // setUser({ username: "", password: "", name: "", phone: "", address: "", postal: "" });
  };

  const loginHandler = () => {
    const fetchData = async () => {
      try {
        const authenticatedUser = await userSignIn(user.username, user.password);

        if (authenticatedUser) {
          if (authenticatedUser.emailVerified) {
            const userData = await readFromDB(`users/${authenticatedUser.uid}`);
            // console.log(userData);
            await setAutenticatedUser({
              role: userData.role,
              userId: userData.userId,
              uid: authenticatedUser.uid,
            });
            // console.log(PoNumber);
            await setPoNumber({ ...PoNumber, userId: userData.userId });
            await setLoginState(true);
            navigate("/");
          } else {
            userEmailVerification(authenticatedUser);
            alert("Verify Your Email Before Login Please. Email Verification Sent.");
          }
        } else {
          setLoginState(false);
        }
      } catch (error) {
        alert(error, "Error fetching data:"); // Handle any errors
      }
    };
    fetchData(); // Invoke the async function
  };

  // Your `useEffect` that captures verification parameters
  useEffect(() => {
    // Detect verification parameters in URL
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const oobCode = urlParams.get("oobCode");
    const mode = urlParams.get("mode");

    const verifyEmail = () => {
      if (mode && oobCode) {
        // Apply email verification only if both mode and oobCode are present
        userEmailVerified(oobCode, mode); // Ensure both are passed
        navigate("/");
      }
    };

    verifyEmail(); // Call the email verification function

    if (checkInput) {
      loginHandler();
      setCheckInput(false); // Reset the checkInput flag
    }
    // eslint-disable-next-line
  }, [checkInput, navigate]);
  return (
    <>
      {accessMethod ? (
        <div className="login-wrapper" style={{ display: loginState ? "none" : "flex" }}>
          <div className="login">
            <img src="resources/images/nilshades-logo.png" alt="logo"></img>
            <form
              onSubmit={(e) => {
                onSignInHandler(e);
              }}
            >
              <input
                type="text"
                data-type="username"
                placeholder="Email"
                autoComplete="username"
                value={user.username}
                required
                onChange={(e) => {
                  onChangeHandler(e);
                }}
              ></input>
              <input
                type="password"
                data-type="password"
                autoComplete="current-password"
                placeholder="Password"
                value={user.password}
                required
                onChange={(e) => {
                  onChangeHandler(e);
                }}
              ></input>
              <span onClick={() => userResetPassword(user.username)}>Reset Password</span>
              <button name="signin" type="Submit">
                submit
              </button>

              <span
                className="sign-up"
                onClick={() => {
                  setAccessMethod(!accessMethod);
                }}
              >
                Don't have an account? &nbsp; <strong> Sign Up</strong>
              </span>
            </form>
          </div>
        </div>
      ) : (
        <div className="signup-wrapper" style={{ display: loginState ? "none" : "flex" }}>
          <div className="login">
            <img src="resources/images/nilshades-logo.png" alt="logo"></img>
            <form
              onSubmit={(e) => {
                onSignUpHandler(e);
              }}
            >
              <input
                type="text"
                data-type="name"
                placeholder="Name"
                value={user.name}
                required
                onChange={(e) => {
                  onChangeHandler(e);
                }}
              ></input>
              <input
                type="text"
                data-type="phone"
                placeholder="Phone"
                value={user.phone}
                required
                maxLength={10}
                minLength={10}
                onChange={(e) => {
                  onChangeHandler(e);
                }}
              ></input>
              <input
                type="text"
                data-type="address"
                placeholder="Address"
                value={user.address}
                required
                onChange={(e) => {
                  onChangeHandler(e);
                }}
              ></input>
              <input
                type="text"
                data-type="postal"
                placeholder="Postal Code"
                value={user.postal}
                maxLength={7}
                minLength={7}
                required
                onChange={(e) => {
                  onChangeHandler(e);
                }}
              ></input>

              <input
                type="text"
                data-type="username"
                placeholder="Email"
                value={user.username}
                autoComplete="username"
                required
                onChange={(e) => {
                  onChangeHandler(e);
                }}
              ></input>
              <input
                type="text"
                data-type="password"
                placeholder="Password"
                autoComplete="new-password"
                value={user.password}
                required
                onChange={(e) => {
                  onChangeHandler(e);
                }}
              ></input>
              <button name="signup" type="Submit">
                submit
              </button>
              <span
                className="sign-up"
                onClick={() => {
                  setAccessMethod(!accessMethod);
                }}
              >
                Do you have an account? &nbsp; <strong> Sign In</strong>
              </span>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default Login;
