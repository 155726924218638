import { useState, useEffect } from "react";
import "../../styles/OrderInfo.css";
import getDropDownItems from "../handler/getDropDownInfo";
import DropDownMaker from "../others/DropDownMaker";
import { useNavigate } from "react-router";

const OrderInfo = ({
  orderInformationHandler,
  setEditOrderBlinds,
  editOrderBlinds,
  setOrderInformation,
  orderInformation,
  editedOrder,
  isEditedOrderHandler,
}) => {
  const {
    manufactures,
    cassetteColors,
    categories,
    cassetteTypes,
    widthDecimals,
    lengthDecimals,
    lightControls,
    manuals,
    fabrics,
    positions,
    layouts,
    placements,
    motorizeds,
    controller,
  } = getDropDownItems;
  const navigate = useNavigate();

  const exchangeRate = 1.375;
  // console.log(orderInformation);
  // Edit Order  //////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    if (editOrderBlinds.index && editedOrder) {
      // console.log("existance order");
      // console.log(editedOrder, editOrderBlinds);

      // console.log("editOrderBlinds", editOrderBlinds);
      // setEditedOrder(true);
      const newInputData = { ...inputData };
      // manufacture
      manufactures.manufactures.forEach((item) => {
        // eslint-disable-next-line
        if (item.id == editOrderBlinds.manufactures) {
          selectedItemHandler(item.title, "manufactures", item.id);
          dropDownHandler("manufactures");
        }
      });
      // categories
      categories.categories.forEach((item) => {
        // eslint-disable-next-line
        if (item.id == editOrderBlinds.categories) {
          selectedItemHandler(item.title, "categories", item.id);
          dropDownHandler("categories");
        }
      });

      // cassetteTypes
      cassetteTypes.cassetteTypes.forEach((item) => {
        // eslint-disable-next-line
        if (item.id == editOrderBlinds.cassetteTypes) {
          selectedItemHandler(item.title, "cassetteTypes", item.id);
          dropDownHandler("cassetteTypes");
        }
      });

      // cassetteColors
      cassetteColors.cassetteColors.forEach((item) => {
        // eslint-disable-next-line
        if (item.id == editOrderBlinds.cassetteColors) {
          selectedItemHandler(item.title, "cassetteColors", item.id);
          dropDownHandler("cassetteColors");
        }
      });

      // widthDecimals
      widthDecimals.widthDecimals.forEach((item) => {
        // eslint-disable-next-line
        if (item.id == editOrderBlinds.widthDecimals) {
          selectedItemHandler(item.title, "widthDecimals", item.id);
          dropDownHandler("widthDecimals");
        }
      });

      // lengthDecimals
      lengthDecimals.lengthDecimals.forEach((item) => {
        // eslint-disable-next-line
        if (item.id == editOrderBlinds.lengthDecimals) {
          selectedItemHandler(item.title, "lengthDecimals", item.id);
          dropDownHandler("lengthDecimals");
        }
      });

      // lightControls
      lightControls.lightControls.forEach((item) => {
        // eslint-disable-next-line
        if (item.id == editOrderBlinds.lightControls) {
          selectedItemHandler(item.title, "lightControls", item.id);
          dropDownHandler("lightControls");
        }
      });

      // fabrics
      fabrics.fabrics.forEach((item) => {
        // eslint-disable-next-line
        if (item.id == editOrderBlinds.manufactures) {
          // console.log(item.id, editOrderBlinds.manufactures);
          item.items.forEach((elem) => {
            // eslint-disable-next-line
            if (elem.id == editOrderBlinds.categories) {
              elem.items.forEach((lig) => {
                // eslint-disable-next-line
                if (lig.id == editOrderBlinds.lightControls) {
                  lig.items.forEach((fab) => {
                    // eslint-disable-next-line
                    if (fab.id == editOrderBlinds.fabrics) {
                      selectedItemHandler(fab.title, "fabrics", fab.id);
                      dropDownHandler("fabrics");
                      // console.log(fab.items);
                    }
                  });
                }
              });
            }
          });
        }
      });

      // positions
      positions.positions.forEach((item) => {
        // eslint-disable-next-line
        if (item.id == editOrderBlinds.positions) {
          selectedItemHandler(item.title, "positions", item.id);
          dropDownHandler("positions");
        }
      });

      // layouts
      layouts.layouts.forEach((item) => {
        // eslint-disable-next-line
        if (item.id == editOrderBlinds.layouts) {
          selectedItemHandler(item.title, "layouts", item.id);
          dropDownHandler("layouts");
        }
      });

      // placements
      placements.placements.forEach((item) => {
        // eslint-disable-next-line
        if (item.id == editOrderBlinds.placements) {
          selectedItemHandler(item.title, "placements", item.id);
          dropDownHandler("placements");
        }
      });
      // console.log("controller:");

      // controller
      controller.controller.forEach((item) => {
        // console.log(item);
        if (orderInformation[editOrderBlinds.index].manuals.inputData) {
          // console.log("manual:", item);
          selectedItemHandler("Manual", "controller", item.id);
          dropDownHandler("controller");
        }
        if (orderInformation[editOrderBlinds.index].motorizeds.inputData) {
          // console.log("motor:", item);
          selectedItemHandler("Motorized", "controller", item.id);
          dropDownHandler("controller");
        }
      });
      // manuals
      manuals.manuals.forEach((item) => {
        // eslint-disable-next-line
        if (item.id == editOrderBlinds.manuals && orderInformation[editOrderBlinds.index].manuals.inputData) {
          // console.log("manual:", item);
          selectedItemHandler(item.title, "manuals", item.id);
          dropDownHandler("manuals");
        }
      });
      // motorizeds
      motorizeds.motorizeds.forEach((item) => {
        // eslint-disable-next-line
        if (item.id == editOrderBlinds.motorizeds && orderInformation[editOrderBlinds.index].motorizeds.inputData) {
          // console.log("motor:", item);
          selectedItemHandler(item.title, "motorizeds", item.id);
          dropDownHandler("motorizeds");
        }
      });

      newInputData.width.inputData = editOrderBlinds.width;
      dataSetter("width", editOrderBlinds.width, "");
      newInputData.length.inputData = editOrderBlinds.length;
      dataSetter("length", editOrderBlinds.length, "");
      newInputData.area.inputData = editOrderBlinds.area;
      dataSetter("area", editOrderBlinds.area, "");
      newInputData.quantity.inputData = editOrderBlinds.quantity;
      dataSetter("quantity", editOrderBlinds.quantity, "");
      newInputData.color.inputData = editOrderBlinds.color;
      dataSetter("color", editOrderBlinds.color, "");
      newInputData.comment.inputData = editOrderBlinds.comment;
      dataSetter("comment", editOrderBlinds.comment, "");

      setDisabledItems({
        categories: true,
        lightControls: true,
        fabrics: true,
        cassetteTypes: true,
        cassetteColors: true,
        manuals: true,
        motorizeds: true,
      });

      // newInputData.measurement.inputData = editOrderBlinds.measurement;

      // measurement(newInputData.measurement.inputData);
      setInputData({ ...newInputData });
      // console.log("newInputData", newInputData);
      setIsRequiered(true);
    }
    // eslint-disable-next-line
  }, [editOrderBlinds]);

  // dropDownHandler ////////////////////////////////////////////////////////////////////////////////
  const dropdownItems = {
    manufactures: {
      display: { display: "none" },
      transform: { transform: "rotate(0deg)" },
      state: true,
    },
    categories: {
      display: { display: "none" },
      transform: { transform: "rotate(0deg)" },
      state: false,
    },
    lightControls: {
      display: { display: "none" },
      transform: { transform: "rotate(0deg)" },
      state: false,
    },
    fabrics: {
      display: { display: "none" },
      transform: { transform: "rotate(0deg)" },
      state: false,
    },
    placements: {
      display: { display: "none" },
      transform: { transform: "rotate(0deg)" },
      state: true,
    },
    controller: {
      display: { display: "none" },
      transform: { transform: "rotate(0deg)" },
      state: true,
    },
    manuals: {
      display: { display: "none" },
      transform: { transform: "rotate(0deg)" },
      state: true,
    },
    motorizeds: {
      display: { display: "none" },
      transform: { transform: "rotate(0deg)" },
      state: true,
    },
    positions: {
      display: { display: "none" },
      transform: { transform: "rotate(0deg)" },
      state: true,
    },
    cassetteTypes: {
      display: { display: "none" },
      transform: { transform: "rotate(0deg)" },
      state: true,
    },
    cassetteColors: {
      display: { display: "none" },
      transform: { transform: "rotate(0deg)" },
      state: true,
    },
    layouts: {
      display: { display: "none" },
      transform: { transform: "rotate(0deg)" },
      state: true,
    },
    widthDecimals: {
      display: { display: "none" },
      transform: { transform: "rotate(0deg)" },
      state: true,
    },
    lengthDecimals: {
      display: { display: "none" },
      transform: { transform: "rotate(0deg)" },
      state: true,
    },
  };
  const [dropdownStatus, setDropdownStatus] = useState({ ...dropdownItems });

  const dropDownHandler = (element) => {
    const item = element;
    dropdownStatus[item].state && dropDownHelper(item);
    if (item === "categories" && !dropdownStatus[item].state) {
      alert("Select manufacture first");
    }
    if (item === "lightControls" && !dropdownStatus[item].state) {
      alert("Select categories first");
    }
    if (item === "fabrics" && !dropdownStatus[item].state) {
      alert("Select ligh controls first");
    }
  };

  const dropDownHelper = (item) => {
    // Check if the element exists in the dropdownItems object
    if (dropdownItems.hasOwnProperty(item)) {
      // console.log('check')
      // If the element is already visible, toggle its display and rotation
      if (
        dropdownStatus[item].display.display !== "none" &&
        dropdownStatus[item].transform.transform !== "rotate(0deg)"
      ) {
        // Create a new object to hold the updated dropdownStatus
        const updatedDropdownStatus = { ...dropdownStatus };

        // Toggle display and rotation for the specified element
        updatedDropdownStatus[item] = {
          ...updatedDropdownStatus[item],
          display: { display: "none" },
          transform: { transform: "rotate(0deg)" },
        };

        // Log the updated dropdownStatus object
        // console.log(updatedDropdownStatus);

        // Update the state with the updated dropdownStatus
        setDropdownStatus(updatedDropdownStatus);

        // Update selected value
      } else {
        // If the item is not visible, update its properties as before
        const updatedDropdownItems = { ...dropdownStatus };

        // Update the specific properties for the specified item
        updatedDropdownItems[item] = {
          ...updatedDropdownItems[item],
          display: { display: "grid" },
          transform: { transform: "rotate(180deg)" },
        };

        // Reset other properties to their original values
        Object.keys(updatedDropdownItems).forEach((key) => {
          if (key !== item) {
            updatedDropdownItems[key].display = { display: "none" };
            updatedDropdownItems[key].transform = { transform: "rotate(0deg)" };
          }
        });

        // Log the updated dropdownItems object
        // console.log(updatedDropdownItems);

        // Update the state with the updated dropdownStatus
        // console.log(updatedDropdownItems)
        setDropdownStatus(updatedDropdownItems);
        // setSelectedValues({ ...selectedValues, [element]: selectedValue });
      }

      // console.log(dropdownItems)
    } else {
      console.log("Element not found in dropdownItems object");
    }
  };

  // element disabler //////////////////////////////////////////////////////////////////////////////
  const disabledItem = {
    categories: false,
    lightControls: false,
    fabrics: false,
    cassetteTypes: false,
    cassetteColors: false,
    manuals: false,
    motorizeds: false,
  };

  const [controllerSetting, setControllerSetting] = useState("");
  const [disabledItems, setDisabledItems] = useState({ ...disabledItem });

  const disableHandler = (item) => {
    const newDisabledItems = { ...disabledItems };
    if (item === "manufactures" && inputData.manufactures.inputData) {
      newDisabledItems["categories"] = true;
    }
    if (item === "categories" && inputData.categories.inputData) {
      newDisabledItems["lightControls"] = true;
      newDisabledItems["cassetteTypes"] = true;
    }
    if (item === "lightControls" && inputData.lightControls.inputData) {
      newDisabledItems["fabrics"] = true;
    }
    if (item === "cassetteTypes" && inputData.cassetteTypes.inputData) {
      const newInputData = { ...inputData };

      if (inputData.cassetteTypes.inputData !== "Open" && inputData.cassetteTypes.inputData !== "RF-Insert") {
        newDisabledItems["cassetteColors"] = true;
        newInputData.cassetteColors.requieredState.isRequiered = true;
        newInputData.cassetteColors.requieredState.theState = false;
      } else {
        newInputData.cassetteColors.requieredState.isRequiered = false;
        newInputData.cassetteColors.requieredState.theState = true;
        newInputData.cassetteColors.inputData = "";
        newDisabledItems["cassetteColors"] = false;
      }

      // console.log(newInputData)
      setInputData({ ...newInputData });
      requiedHandler();
    }
    if (!inputData.lightControls.inputData) {
      newDisabledItems["fabrics"] = false;
    }
    if (!inputData.categories.inputData) {
      newDisabledItems["lightControls"] = false;
      newDisabledItems["cassetteTypes"] = false;
    }
    if (!inputData.manufactures.inputData) {
      newDisabledItems["categories"] = false;
    }
    if (item === "manuals" && inputData.controller.inputData) {
      dataSetter("motorizeds", "", "");
      setControllerSetting("manuals");
    }
    if (item === "motorizeds" && inputData.controller.inputData) {
      dataSetter("manuals", "", "");
      setControllerSetting("motorizeds");
    }
    // console.log(newDisabledItems);
    setDisabledItems({ ...newDisabledItems });
  };

  // is require handler + input Data Handler //////////////////////////////////////////////////////
  const inputDataItem = {
    width: {
      requieredState: {
        isRequiered: true,
        theState: false,
      },
      inputData: "",
      id: "",
    },
    widthDecimals: {
      requieredState: {
        isRequiered: false,
        theState: true,
      },
      inputData: "",
      id: "",
    },
    length: {
      requieredState: {
        isRequiered: true,
        theState: false,
      },
      inputData: "",
      id: "",
    },
    lengthDecimals: {
      requieredState: {
        isRequiered: false,
        theState: true,
      },
      inputData: "",
      id: "",
    },
    manufactures: {
      requieredState: {
        isRequiered: true,
        theState: false,
      },
      inputData: "",
      id: "",
    },
    categories: {
      requieredState: {
        isRequiered: true,
        theState: false,
      },
      inputData: "",
      id: "",
    },
    lightControls: {
      requieredState: {
        isRequiered: true,
        theState: false,
      },
      inputData: "",
      id: "",
    },
    fabrics: {
      requieredState: {
        isRequiered: true,
        theState: false,
      },
      inputData: "",
      id: "",
    },
    area: {
      requieredState: {
        isRequiered: true,
        theState: false,
      },
      inputData: "",
      id: "",
    },
    quantity: {
      requieredState: {
        isRequiered: true,
        theState: false,
      },
      inputData: "",
      id: "",
    },
    placements: {
      requieredState: {
        isRequiered: true,
        theState: false,
      },
      inputData: "",
      id: "",
    },
    color: {
      requieredState: {
        isRequiered: true,
        theState: false,
      },
      inputData: "",
      id: "",
    },
    controller: {
      requieredState: {
        isRequiered: true,
        theState: false,
      },
      inputData: "",
      id: "",
    },
    manuals: {
      requieredState: {
        isRequiered: true,
        theState: false,
      },
      inputData: "",
      id: "",
    },
    motorizeds: {
      requieredState: {
        isRequiered: true,
        theState: false,
      },
      inputData: "",
      id: "",
    },
    positions: {
      requieredState: {
        isRequiered: true,
        theState: false,
      },
      inputData: "",
      id: "",
    },
    cassetteTypes: {
      requieredState: {
        isRequiered: true,
        theState: false,
      },
      inputData: "",
      id: "",
    },
    cassetteColors: {
      requieredState: {
        isRequiered: true,
        theState: true,
      },
      inputData: "",
      id: "",
    },
    layouts: {
      requieredState: {
        isRequiered: true,
        theState: false,
      },
      inputData: "",
      id: "",
    },
    comment: {
      requieredState: {
        isRequiered: false,
        theState: true,
      },
      inputData: "",
      id: "",
    },
    unitPrice: {
      requieredState: {
        isRequiered: false,
        theState: true,
      },
      inputData: "",
      id: "",
    },
    totalPrice: {
      requieredState: {
        isRequiered: false,
        theState: true,
      },
      inputData: "",
      id: "",
    },
  };
  const initialInputData = { ...inputDataItem };
  const [inputData, setInputData] = useState({ ...inputDataItem });
  const [isRequiered, setIsRequiered] = useState(false);
  const requiedHandler = () => {
    setIsRequiered(true);
    const newInputData = { ...inputData };
    // console.log("==============================================");
    const requierds = Object.keys(newInputData).map((item) => {
      // console.log(item, newInputData[item]);

      if (newInputData[item].requieredState.isRequiered) {
        return newInputData[item].requieredState.theState;
      } else {
        return true;
      }
    });
    requierds.find((item) => {
      if (item === false) {
        setIsRequiered(false);
      }
      return null;
    });
  };

  // data setter /////////////////////////////////////////////////////////////////////////////////
  const dataSetter = (dataName, dataValue, elementId) => {
    // console.log(dataName, dataValue, elementId);
    const newInputData = { ...inputData };
    newInputData[dataName].inputData = dataValue;
    newInputData[dataName].id = elementId;
    newInputData[dataName].inputData
      ? (newInputData[dataName].requieredState.theState = true)
      : (newInputData[dataName].requieredState.theState = false);

    // change dropdown lock
    const newDropdownStatus = { ...dropdownStatus };
    if (dataName === "manufactures") {
      if (newInputData[dataName].inputData) {
        newDropdownStatus["categories"].state = true;
      } else {
        newDropdownStatus["categories"].state = false;
      }
    }
    if (dataName === "categories") {
      if (newInputData[dataName].inputData) {
        newDropdownStatus["lightControls"].state = true;
      } else {
        newDropdownStatus["lightControls"].state = false;
      }
    }
    if (dataName === "lightControls") {
      if (newInputData[dataName].inputData) {
        newDropdownStatus["fabrics"].state = true;
      } else {
        newDropdownStatus["fabrics"].state = false;
      }
    }
    if (dataName === "cassetteTypes") {
      newInputData.cassetteColors.inputData = "";
      if (newInputData[dataName].inputData) {
        newDropdownStatus["cassetteColors"].state = true;
      } else {
        newDropdownStatus["cassetteColors"].state = false;
      }
    }
    if (dataName === "controller") {
      // newInputData.controller.inputData = "";
      if (newInputData[dataName].inputData === "Manual") {
        newDropdownStatus["manuals"].state = true;
        newDropdownStatus["motorizeds"].state = false;
        disableHandler("manuals");
      } else if (newInputData[dataName].inputData === "Motorized") {
        newDropdownStatus["manuals"].state = false;
        newDropdownStatus["motorizeds"].state = true;
        disableHandler("motorizeds");
      }
    }

    Object.keys(newDropdownStatus).forEach((key) => {
      newDropdownStatus[key].display = { display: "none" };
      newDropdownStatus[key].transform = { transform: "rotate(0deg)" };
    });
    setDropdownStatus({ ...newDropdownStatus });
    setInputData({ ...newInputData });
    // console.log(newInputData);
    requiedHandler();
    // console.log(newInputData);
  };

  // form data chanege handler ///////////////////////////////////////////////////////////////////
  const onChangeHandler = (e) => {
    dataSetter(e.target.dataset.name, e.target.value, "");
  };

  // filter Items - dropdown selected item handler //////////////////////////////////////////////////////////////
  const subDropdownItem = {
    manufactures: {
      id: null,
      categories: [],
    },
    categories: {
      id: null,
      categories: [],
    },
    lightControls: {
      id: null,
      lightControls: [],
    },
    fabrics: {
      id: null,
      fabrics: [],
    },
    cassetteTypes: {
      id: null,
      cassetteTypes: [],
    },
    controller: {
      id: null,
      controller: [],
    },
  };
  const [filteredCategory, setFilteredCategory] = useState({ ...subDropdownItem });
  const selectedItemHandler = (dataSelected, dataName, elementId) => {
    dataSetter(dataName, dataSelected, elementId);
    contorlToggeler(dataName);
    // console.log(elementId);
    // const x = manufactures.manufactures;
    const newFilteredCategory = { ...filteredCategory };
    if (dataName === "manufactures") {
      newFilteredCategory.manufactures.id = elementId;
      manufactures.manufactures.map((item) => {
        // eslint-disable-next-line
        if (item.id == elementId) {
          newFilteredCategory.categories.categories = [];
          dataSetter("categories", "", "");
          newFilteredCategory.lightControls.lightControls = [];
          dataSetter("lightControls", "", "");
          newFilteredCategory.fabrics.fabrics = [];
          dataSetter("fabrics", "", "");
          newFilteredCategory.cassetteTypes.cassetteTypes = [];
          dataSetter("cassetteTypes", "", "");

          item.categories.map((cat) => {
            categories.categories.map((elem) => {
              // eslint-disable-next-line
              if (elem.id == cat) {
                newFilteredCategory.categories.categories.push(elem);
              }
              return null;
            });
            return null;
          });
        }
        return null;
      });
    }
    if (dataName === "categories") {
      newFilteredCategory.categories.id = elementId;
      categories.categories.map((item) => {
        // eslint-disable-next-line
        if (item.id == elementId) {
          newFilteredCategory.lightControls.lightControls = [];
          dataSetter("lightControls", "", "");
          newFilteredCategory.fabrics.fabrics = [];
          dataSetter("fabrics", "", "");
          newFilteredCategory.cassetteTypes.cassetteTypes = [];
          dataSetter("cassetteTypes", "", "");

          item.lightControls.map((lgt) => {
            lightControls.lightControls.map((elem) => {
              // eslint-disable-next-line
              if (lgt == elem.id) {
                newFilteredCategory.lightControls.lightControls.push(elem);
              }
              return null;
            });
            return null;
          });
        }
        return null;
      });

      //filter cassette
      newFilteredCategory.cassetteTypes.cassetteTypes = [];
      cassetteTypes.cassetteTypes.forEach((cassette) => {
        if (cassette.manufactures.hasOwnProperty(newFilteredCategory.manufactures.id)) {
          cassette.manufactures[newFilteredCategory.manufactures.id].categories.forEach((elem) => {
            // eslint-disable-next-line
            if (elem == newFilteredCategory.categories.id) {
              newFilteredCategory.cassetteTypes.cassetteTypes.push(cassette);
            }
          });
        }
      });
    }
    if (dataName === "lightControls") {
      newFilteredCategory.lightControls.id = elementId;
      // console.log(fabrics);
      fabrics.fabrics.forEach((manufacture) => {
        // eslint-disable-next-line
        if (manufacture.id == newFilteredCategory.manufactures.id) {
          manufacture.items.forEach((category) => {
            // eslint-disable-next-line
            if (category.id == newFilteredCategory.categories.id) {
              newFilteredCategory.fabrics.fabrics = [];
              dataSetter("fabrics", "", "");

              category.items.forEach((lightcontrol) => {
                // eslint-disable-next-line
                if (lightcontrol.id == newFilteredCategory.lightControls.id) {
                  lightcontrol.items.forEach((fabric) => {
                    newFilteredCategory.fabrics.fabrics.push(fabric);
                  });
                }
              });
            }
          });
        }
      });
    }

    // console.log(newFilteredCategory)
    setFilteredCategory({ ...newFilteredCategory });
    disableHandler(dataName);
  };

  // motorized and manual toggeler ////////////////////////////////////////////////////////////////
  const contorlToggeler = (dataName) => {
    const newInputData = { ...inputData };
    if (dataName === "motorizeds") {
      newInputData.manuals.inputData = "";
      newInputData.manuals.requieredState.isRequiered = false;
      newInputData.manuals.requieredState.theState = true;
    }
    if (dataName === "manuals") {
      newInputData.motorizeds.inputData = "";
      newInputData.motorizeds.requieredState.isRequiered = false;
      newInputData.motorizeds.requieredState.theState = true;
    }
    setInputData({ ...newInputData });
  };

  // form submit handler /////////////////////////////////////////////////////////////////////////
  const [invoiceData, setInvoiceData] = useState([]);
  const [validData, setValidData] = useState(false);
  const formSubmitHandler = (e) => {
    e.preventDefault();
    requiedHandler();
    if (isRequiered) {
      priceCalculator(inputData);
      // console.log(inputData);
    } else {
      alert("Fill all required fields");
    }
    // setIsRequiered(false)
  };

  useEffect(() => {
    if (invoiceData.length > 0) {
      // console.log(invoiceData);
      orderInformationHandler(invoiceData);
      setInvoiceData([]);
    }
    // eslint-disable-next-line
  }, [invoiceData]);

  useEffect(() => {
    // eslint-disable-next-line
    if (validData && isRequiered) {
      const newInputData = { ...inputData };
      Object.keys(newInputData).forEach((key) => {
        let data = newInputData[key];
        if (!data.inputData && data.inputData !== 0) {
          if (key !== "widthDecimals" && key !== "lengthDecimals" && key !== "motorizeds" && key !== "manuals") {
            // console.log(data.inputData);
            data.inputData = "---";
          }
          // console.log(key, data);
        }
      });
      // console.log("editedOrder", editedOrder);
      if (!editedOrder) {
        const newInvoiceData = [...invoiceData];
        newInvoiceData.push({ ...newInputData });
        setInvoiceData([...newInvoiceData]);
      } else {
        // console.log("Edited Order", orderInformation);
        const newOrderInformation = [...orderInformation];
        // console.log(newOrderInformation[editOrderBlinds.index * 1]);

        newOrderInformation[editOrderBlinds.index * 1] = { ...inputData };
        // console.log("result:", newOrderInformation[editOrderBlinds.index * 1]);
        // console.log(newOrderInformation);
        setOrderInformation([...newOrderInformation]);
        // setEditOrderBlinds([...newOrderInformation]);
        // orderInformationHandler([...newOrderInformation]);
        setEditOrderBlinds({
          index: "",
          area: "",
          quantity: "",
          width: "",
          widthDecimals: "",
          length: "",
          lengthDecimals: "",
          placements: "",
          color: "",
          manufactures: "",
          categories: "",
          lightControls: "",
          fabrics: "",
          manuals: "",
          controller: "",
          motorizeds: "",
          positions: "",
          cassetteTypes: "",
          cassetteColors: "",
          layouts: "",
          comment: "",
          unitPrice: "",
          totalPrice: "",
        });
        // setEditedOrder(false);
        navigate("/result");
      }
      resetForm();
    }
    // eslint-disable-next-line
  }, [validData]);

  // rest form handler //////////////////////////////////////////////////////////////////////////
  const resetForm = () => {
    setIsRequiered(false);
    setValidData(false);
    const newDropdownStatus = { ...dropdownStatus };
    Object.keys(newDropdownStatus).forEach((key) => {
      newDropdownStatus[key].display = { display: "none" };
      newDropdownStatus[key].transform = { transform: "rotate(0deg)" };
    });
    newDropdownStatus["categories"].state = false;
    newDropdownStatus["lightControls"].state = false;
    newDropdownStatus["fabrics"].state = false;
    setDropdownStatus({ ...newDropdownStatus });
    setInputData({ ...initialInputData });
    setDisabledItems({ ...disabledItem });
    setControllerSetting("");
    // setEditedOrder(false);
    setIsDuplicate(false);
    isEditedOrderHandler(false);
    setFinalSize({
      width: 0,
      widthDec: 0,
      length: 0,
      lengthDec: 0,
      controllerPrice: 0, // motorized or manual
      price: 0,
      unitPrice: 0,
    });
  };

  // Price Calculator  //////////////////////////////////////////////////////////////////////////

  const [finalSize, setFinalSize] = useState({
    width: 0,
    widthDec: 0,
    length: 0,
    lengthDec: 0,
    controllerPrice: 0, // motorized or manual
    price: 0,
    unitPrice: 0,
    cassettePrice: 0,
  });

  const priceCalculator = (newData) => {
    const data = newData;
    const newFinalSize = { ...finalSize };

    // newFinalSize.width = 0;
    // newFinalSize.length = 0;
    // newFinalSize.price = 0;
    // console.log(data);
    // console.log(newFinalSize);
    // console.log(measure);
    if (measure === "CM") {
      data.width.inputData = `${Math.round(data.width.inputData * 0.3937 * 100) / 100}`;
      data.length.inputData = `${Math.round(data.length.inputData * 0.3937 * 100) / 100}`;
    } else if (measure === "FT") {
      data.width.inputData = `${Math.round(data.width.inputData * 12 * 100) / 100}`;
      data.length.inputData = `${Math.round(data.length.inputData * 12 * 100) / 100}`;
    }

    fabrics.fabrics.forEach((factory) => {
      // eslint-disable-next-line
      if (factory.id == data.manufactures.id) {
        // eslint-disable-next-line
        factory.items.forEach((cat) => {
          // eslint-disable-next-line
          if (cat.id == data.categories.id) {
            // eslint-disable-next-line
            cat.items.forEach((lgt) => {
              // eslint-disable-next-line
              if (lgt.id == data.lightControls.id) {
                lgt.items.forEach((fab) => {
                  // eslint-disable-next-line
                  if (fab.id == data.fabrics.id) {
                    const priceindex = {
                      width: 0,
                      length: 0,
                    };
                    // get withd desimal
                    // eslint-disable-next-line
                    widthDecimals.widthDecimals.find((dec) => {
                      // eslint-disable-next-line
                      // eslint-disable-next-line
                      if (dec.id == inputData.widthDecimals.id) {
                        // eslint-disable-next-line
                        // console.log(dec.value);
                        newFinalSize.widthDec = dec.value;
                      }
                    });
                    // get length decimal
                    lengthDecimals.lengthDecimals.find((dec) => {
                      // eslint-disable-next-line
                      if (dec.id == inputData.lengthDecimals.id) {
                        // console.log(dec.value);
                        newFinalSize.lengthDec = dec.value;
                      }
                      return null;
                    });

                    // Calculate width and lenght
                    // eslint-disable-next-line
                    if (data.manufactures.id != 102) {
                      // calculate Width
                      for (let i = fab.width.length - 1; i >= 0; i--) {
                        const wd = fab.width[i];
                        // console.log(data.width.inputData * 1 + newFinalSize.widthDec);
                        if (data.width.inputData * 1 + newFinalSize.widthDec < wd) {
                          // console.log(data.width.inputData * 1 + newFinalSize.widthDec);
                          newFinalSize.width = wd;
                          priceindex.width = i;
                          // console.log(fab.length.priceTable[i]);
                        } else if (fab.width[fab.width.length - 1] < data.width.inputData * 1) {
                          alert("Width is out of range");
                          setValidData(false);
                          newFinalSize.width = 0;
                          break;
                        }
                      }
                      // calculate length
                      for (let i = fab.length.length - 1; i >= 0; i--) {
                        const lt = fab.length[i];
                        // console.log(data.length.inputData * 1 + newFinalSize.lengthDec);
                        if (data.length.inputData * 1 + newFinalSize.lengthDec <= lt) {
                          // console.log(data.length.inputData * 1 + newFinalSize.lengthDec);
                          newFinalSize.length = lt;
                          priceindex.length = i;
                          // console.log(fab.length.priceTable[i]);
                        } else if (fab.length[fab.length.length - 1] < data.length.inputData * 1) {
                          alert("Length is out of range");
                          setValidData(false);
                          newFinalSize.length = 0;
                          break;
                        }
                      }
                    } else {
                      if (data.width.inputData > fab.width) {
                        alert("Width is out of range");
                      } else {
                        newFinalSize.widthDec = data.widthDecimals.inputData;
                        newFinalSize.width = data.width.inputData;
                      }

                      if (data.length.inputData > fab.length) {
                        alert("Length is out of range");
                      } else {
                        newFinalSize.lengthDec = data.lengthDecimals.inputData;
                        newFinalSize.length = data.length.inputData;
                      }
                    }

                    // newFinalSize.price = fab.length[newFinalSize.length];
                    // console.log(newFinalSize.width > 0);
                    // console.log(newFinalSize.length > 0);
                    if (newFinalSize.width > 0 && newFinalSize.length > 0) {
                      const newInputData = { ...inputData };
                      //   console.log(motorizeds.motorizeds, manuals.manuals);
                      if (newInputData.motorizeds.inputData) {
                        // console.log(newInputData.motorizeds, newInputData.motorizeds.id);
                        motorizeds.motorizeds.forEach((motor) => {
                          // eslint-disable-next-line
                          if (motor.id == newInputData.motorizeds.id) {
                            // console.log("motor price", motor.price);
                            newFinalSize.controllerPrice = motor.price;
                          }
                        });
                      }
                      // console.log(newInputData.manuals.inputData);
                      if (newInputData.manuals.inputData) {
                        // console.log(newInputData.manuals, newInputData.manuals.id);
                        manuals.manuals.forEach((manual) => {
                          // eslint-disable-next-line
                          if (manual.id == newInputData.manuals.id) {
                            // console.log("manual price:", manual.price);
                            newFinalSize.controllerPrice = manual.price;
                          }
                        });
                      }

                      // Sun Light Cassette price calculator
                      // eslint-disable-next-line
                      if (data.manufactures.id == 101) {
                        newFinalSize.cassettePrice = 0;
                        // console.log("sunlight 101");
                        // console.log("data.cassetteTypes.id:", data.cassetteTypes.id);
                        let coefficient = 0;
                        // eslint-disable-next-line
                        if (data.categories.id == 101) {
                          // eslint-disable-next-line
                          if (data.cassetteTypes.id == 102) {
                            coefficient = 0;
                            newFinalSize.cassettePrice = 0;
                            // eslint-disable-next-line
                          } else if (data.cassetteTypes.id == 103) {
                            // eslint-disable-next-line
                            if (newFinalSize.length > 110) {
                              coefficient = 15;
                              newFinalSize.cassettePrice =
                                (fab.priceTable[priceindex.length][priceindex.width] * coefficient) / 100;
                            } else {
                              coefficient = 0;
                              newFinalSize.cassettePrice = 0;
                            }
                            // eslint-disable-next-line
                          } else if (data.cassetteTypes.id == 104) {
                            coefficient = 15;
                            newFinalSize.cassettePrice =
                              (fab.priceTable[priceindex.length][priceindex.width] * coefficient) / 100;
                          }
                          // eslint-disable-next-line
                        } else if (data.categories.id == 102) {
                          // eslint-disable-next-line
                          if (data.cassetteTypes.id == 101) {
                            coefficient = 0;
                            newFinalSize.cassettePrice = 0; // eslint-disable-next-line
                          } else if (data.cassetteTypes.id == 102) {
                            coefficient = 0;
                            newFinalSize.cassettePrice = fab.cassettePrice[priceindex.width]; // eslint-disable-next-line
                          } else if (data.cassetteTypes.id == 103) {
                            if (newFinalSize.length > 110) {
                              coefficient = 15;
                              newFinalSize.cassettePrice =
                                (fab.priceTable[priceindex.length][priceindex.width] * coefficient) / 100 +
                                fab.cassettePrice[priceindex.width];
                            } else {
                              coefficient = 0;
                              newFinalSize.cassettePrice = fab.cassettePrice[priceindex.width];
                            } // eslint-disable-next-line
                          } else if (data.cassetteTypes.id == 104) {
                            coefficient = 15;
                            newFinalSize.cassettePrice =
                              (fab.priceTable[priceindex.length][priceindex.width] * coefficient) / 100 +
                              fab.cassettePrice[priceindex.width];
                          } // eslint-disable-next-line
                        } else if (data.categories.id == 103) {
                          // eslint-disable-next-line
                          if (data.cassetteTypes.id == 102) {
                            coefficient = 0;
                            newFinalSize.cassettePrice = 0; // eslint-disable-next-line
                          } else if (data.cassetteTypes.id == 103) {
                            if (newFinalSize.length > 110) {
                              coefficient = 15;
                              newFinalSize.cassettePrice =
                                (fab.priceTable[priceindex.length][priceindex.width] * coefficient) / 100;
                            } else {
                              coefficient = 0;
                              newFinalSize.cassettePrice = 0;
                            } // eslint-disable-next-line
                          } else if (data.cassetteTypes.id == 104) {
                            coefficient = 15;
                            newFinalSize.cassettePrice =
                              (fab.priceTable[priceindex.length][priceindex.width] * coefficient) / 100;
                          }
                        }
                        newFinalSize.unitPrice =
                          fab.priceTable[priceindex.length][priceindex.width] + newFinalSize.cassettePrice;

                        // console.log(
                        //   "coefficient:",
                        //   coefficient,
                        //   "       newFinalSize.cassettePrice :",
                        //   newFinalSize.cassettePrice,
                        //   "       newFinalSize.unitPrice:",
                        //   newFinalSize.unitPrice
                        // );

                        // console.log(newFinalSize.unitPrice);
                        // console.log("sun light");
                        newFinalSize.unitPrice = newFinalSize.unitPrice * 0.15;
                        // console.log("SunLight Purchase Price: ", newFinalSize.unitPrice);
                        // console.log(newFinalSize.unitPrice);
                        newFinalSize.unitPrice = newFinalSize.unitPrice + newFinalSize.unitPrice * 0.75;
                        // console.log("SunLight Purchase Price: ", newFinalSize.unitPrice);
                        // console.log(newFinalSize.unitPrice);
                        newFinalSize.unitPrice = newFinalSize.unitPrice * 2;
                        // console.log("SunLight Purchase Price: ", newFinalSize.unitPrice);
                        // console.log(newFinalSize.unitPrice);
                        // eslint-disable-next-line
                      } else if (data.manufactures.id == 103) {
                        newFinalSize.cassettePrice = 0;
                        // eslint-disable-next-line
                        if (data.categories.id == 102) {
                          if (
                            // eslint-disable-next-line
                            data.cassetteTypes.id == 102 || // eslint-disable-next-line
                            data.cassetteTypes.id == 104 || // eslint-disable-next-line
                            data.cassetteTypes.id == 106
                          ) {
                            newFinalSize.cassettePrice = fab.cassettePrice.cassette[priceindex.width]; // eslint-disable-next-line
                          } else if (data.cassetteTypes.id == 103) {
                            newFinalSize.cassettePrice =
                              fab.cassettePrice.cassette[priceindex.width] + (newFinalSize.width / 12) * 2; // eslint-disable-next-line
                          } else if (data.cassetteTypes.id == 107) {
                            newFinalSize.cassettePrice = fab.cassettePrice.fascia[priceindex.width];
                          } // eslint-disable-next-line
                        } else if (data.categories.id == 104) {
                          // eslint-disable-next-line
                          if (data.cassetteTypes.id == 109) {
                            // eslint-disable-next-line
                            newFinalSize.cassettePrice = fab.cassettePriceSquare[priceindex.width]; // eslint-disable-next-line
                          } else if (data.cassetteTypes.id == 110) {
                            newFinalSize.cassettePrice = fab.cassettePriceCorner[priceindex.width];
                          }
                        } else {
                          // eslint-disable-next-line
                          if (data.cassetteTypes.id == 103) {
                            newFinalSize.cassettePrice = (newFinalSize.width / 12) * 2; // add 2 doller per fit fo round fabric insert
                          }
                        }

                        newFinalSize.unitPrice =
                          fab.priceTable[priceindex.length][priceindex.width] + newFinalSize.cassettePrice;
                        // console.log(newFinalSize.unitPrice);

                        newFinalSize.unitPrice = newFinalSize.unitPrice * 0.25;
                        // console.log("NewArt Purchase Price: ", newFinalSize.unitPrice);
                        // console.log(newFinalSize.unitPrice);
                        newFinalSize.unitPrice = newFinalSize.unitPrice + newFinalSize.unitPrice * 0.75;
                        // console.log(newFinalSize.unitPrice);
                        newFinalSize.unitPrice = newFinalSize.unitPrice * 2;
                        // console.log(newFinalSize.unitPrice);
                        // eslint-disable-next-line
                      } else if (data.manufactures.id == 102) {
                        newFinalSize.cassettePrice = 0; // eslint-disable-next-line
                        if (data.categories.id == 102) {
                          // eslint-disable-next-line
                          if (data.cassetteTypes.id == 102) {
                            // eslint-disable-next-line
                            newFinalSize.cassettePrice = exchangeRate * (0.0254 * newFinalSize.width) * 5.5; // eslint-disable-next-line
                          } else if (data.cassetteTypes.id == 103) {
                            newFinalSize.cassettePrice = exchangeRate * (0.0254 * newFinalSize.width) * 5.5; // eslint-disable-next-line
                          } else if (data.cassetteTypes.id == 111) {
                            newFinalSize.cassettePrice = exchangeRate * (0.0254 * newFinalSize.width) * 12.8; // eslint-disable-next-line
                          } else if (data.cassetteTypes.id == 104) {
                            newFinalSize.cassettePrice = exchangeRate * (0.0254 * newFinalSize.width) * 7.8; // eslint-disable-next-line
                          } else if (data.cassetteTypes.id == 112) {
                            newFinalSize.cassettePrice = exchangeRate * (0.0254 * newFinalSize.width) * 14; // eslint-disable-next-line
                          } else if (data.cassetteTypes.id == 105) {
                            newFinalSize.cassettePrice = exchangeRate * (0.0254 * newFinalSize.width) * 9; // eslint-disable-next-line
                          } else if (data.cassetteTypes.id == 108) {
                            newFinalSize.cassettePrice = exchangeRate * (0.0254 * newFinalSize.width * 14 + 13);
                          } // eslint-disable-next-line
                        } else if (data.categories.id == 105) {
                          // eslint-disable-next-line
                          if (data.cassetteTypes.id == 113) {
                            newFinalSize.cassettePrice = exchangeRate * (0.0254 * newFinalSize.width) * 5; // eslint-disable-next-line
                          } else if (data.cassetteTypes.id == 114) {
                            newFinalSize.cassettePrice = exchangeRate * (0.0254 * newFinalSize.width) * 5; // eslint-disable-next-line
                          } else if (data.cassetteTypes.id == 115) {
                            newFinalSize.cassettePrice = exchangeRate * (0.0254 * newFinalSize.width) * 7; // eslint-disable-next-line
                          } else if (data.cassetteTypes.id == 116) {
                            newFinalSize.cassettePrice = exchangeRate * (0.0254 * newFinalSize.width) * 12;
                          }
                        }
                        // 2.54*2.54/10000 = 0.00064516
                        if (newFinalSize.width * newFinalSize.length * 0.00064516 > 1) {
                          newFinalSize.unitPrice =
                            (fab.priceTable * exchangeRate * newFinalSize.width * newFinalSize.length * 0.00064516 +
                              newFinalSize.cassettePrice) *
                            1.7; // *1.7 is for shipping cost
                          // console.log("BFC Purchase Price: ", newFinalSize.unitPrice);
                          newFinalSize.unitPrice = (newFinalSize.unitPrice * 0.75 + newFinalSize.unitPrice) * 2;
                          //price Increment
                          newFinalSize.unitPrice = newFinalSize.unitPrice * 1.08;
                        } else {
                          newFinalSize.unitPrice = (fab.priceTable * exchangeRate + newFinalSize.cassettePrice) * 1.7; // *1.7 is for shipping cost
                          // console.log("BFC Purchase Price: ", newFinalSize.unitPrice);
                          newFinalSize.unitPrice = (newFinalSize.unitPrice * 0.75 + newFinalSize.unitPrice) * 2;
                          //price Increment
                          newFinalSize.unitPrice = newFinalSize.unitPrice * 1.08;
                        }
                      } else {
                        // console.log("Others");
                        newFinalSize.cassettePrice = 0; // must be change for each company
                        newFinalSize.unitPrice =
                          fab.priceTable[priceindex.length][priceindex.width] + newFinalSize.cassettePrice;
                      }
                      // add controller price
                      newFinalSize.unitPrice = newFinalSize.unitPrice + newFinalSize.controllerPrice;
                      // last calculate
                      newInputData.unitPrice.inputData = Math.round(newFinalSize.unitPrice);
                      // console.log(newFinalSize.unitPrice);

                      newInputData.totalPrice.inputData = Math.round(
                        newFinalSize.unitPrice * (newInputData.quantity.inputData * 1)
                      );

                      // console.log(newFinalSize);
                      // console.log(newFinalSize);
                      // console.log(newInputData);

                      setInputData({ ...newInputData });
                      setFinalSize({ ...newFinalSize });
                      setValidData(true);
                    } else {
                      console.log("Error - code-5623");
                    }
                  }
                });
              }
            });
          }
        });
      }
    });
    // blinds + Motor + cassette
  };
  // Measurement ///////////////////////////////////////////////////////////////////////////////
  const [isChecked, setIsChecked] = useState({
    IN: true,
    FT: false,
    CM: false,
  });
  const [measure, setMeasure] = useState("IN");
  const measurement = (data) => {
    if (data === "FT") {
      setIsChecked({
        IN: false,
        FT: true,
        CM: false,
      });
      setMeasure("FT");
      dataSetter("lengthDecimals", "", "");
      dataSetter("widthDecimals", "", "");
    } else if (data === "CM") {
      setIsChecked({
        IN: false,
        FT: false,
        CM: true,
      });
      setMeasure("CM");
      dataSetter("lengthDecimals", "", "");
      dataSetter("widthDecimals", "", "");
    } else {
      setIsChecked({
        IN: true,
        FT: false,
        CM: false,
      });
      setMeasure("IN");
    }
    // dataSetter("width", `${size}`, "");
  };

  // Duplicate Items Info //////////////////////////////////////////////////////////////////////
  const [isDuplicate, setIsDuplicate] = useState(false);
  const duplicateItems = (e) => {
    setIsDuplicate(!isDuplicate);
    if (e.target.checked && orderInformation.length > 0) {
      const newInputData = {
        ...orderInformation[orderInformation.length - 1],
        width: {
          requieredState: {
            isRequiered: true,
            theState: false,
          },
          inputData: "",
          id: "",
        },
        widthDecimals: {
          requieredState: {
            isRequiered: false,
            theState: true,
          },
          inputData: "",
          id: "",
        },
        length: {
          requieredState: {
            isRequiered: true,
            theState: false,
          },
          inputData: "",
          id: "",
        },
        lengthDecimals: {
          requieredState: {
            isRequiered: false,
            theState: true,
          },
          inputData: "",
          id: "",
        },
        comment: {
          requieredState: {
            isRequiered: false,
            theState: true,
          },
          inputData: "",
          id: "",
        },
        unitPrice: {
          requieredState: {
            isRequiered: false,
            theState: true,
          },
          inputData: "",
          id: "",
        },
        totalPrice: {
          requieredState: {
            isRequiered: false,
            theState: true,
          },
          inputData: "",
          id: "",
        },
      };
      // setInputData({ ...newInputData });
      // console.log(orderInformation[orderInformation.length - 1]);
      // console.log(newInputData);
      Object.keys(newInputData).forEach((item) => {
        // item !== "motorizds" && item !== "manuals";
        // console.log(newInputData);
        // selectedItemHandler(newInputData[item].inputData, item, newInputData[item].id);
        if (item !== "manuals" && item !== "motorizeds") {
          selectedItemHandler(newInputData[item].inputData, item, newInputData[item].id);
        } else {
          // console.log("check point 1");
          if (newInputData.controller.inputData === "Manual") {
            // console.log("manuals");
            dataSetter("manuals", newInputData.manuals.inputData, newInputData.manuals.id);
            selectedItemHandler(newInputData.manuals.inputData, "manuals", newInputData.manuals.id);
          } else if (newInputData.controller.inputData === "Motorized") {
            dataSetter("motorizeds", newInputData.motorizeds.inputData, newInputData.motorizeds.id);
            selectedItemHandler(newInputData.motorizeds.inputData, "motorizeds", newInputData.motorizeds.id);
            // console.log("motorizeds");
          }
        }

        //  if (item !== "motorizds" && item !== "manuals") {
        //    // console.log(item, newInputData[item].inputData, newInputData[item].id);
        //    // dataSetter(item, newInputData[item].inputData, newInputData[item].id);
        //  } else {
        //    // selectedItemHandler(newInputData[item].inputData, item, newInputData[item].id);
        //  }
        // if (item === "manuals" && inputData.controller.inputData === "Manual") {
        //   // dataSetter("motorizeds", "", "");
        //   // setControllerSetting("manuals");
        //   dataSetter(item, newInputData[item].inputData, newInputData[item].id);
        //   console.log(item, inputData.controller.inputData);
        // }
        // if (item === "motorizds" && inputData.controller.inputData === "Motorized") {
        //   // dataSetter("manuals", "", "");
        //   // setControllerSetting("motorizeds");
        //   dataSetter(item, newInputData[item].inputData, newInputData[item].id);
        //   console.log(item, inputData.controller.inputData, newInputData);
        // }

        // if (item !== "motorizds" && item !== "manuals") {
        //   selectedItemHandler(newInputData[item].inputData, item, newInputData[item].id);
        //   // dataSetter(item, newInputData[item].inputData, newInputData[item].id);
        // } else {
        //   selectedItemHandler(newInputData[item].inputData, item, newInputData[item].id);

        //   // if (newInputData.controller.inputData === "Motorized") {
        //   //   console.log(newInputData[item].inputData);
        //   //   selectedItemHandler(newInputData[item].inputData, "motorizds", newInputData[item].id);

        //   //   newInputData.manuals.inputData = "";
        //   //   newInputData.manuals.requieredState.isRequiered = false;
        //   //   newInputData.manuals.requieredState.theState = true;
        //   //   console.log(item);
        //   // } else if (newInputData.controller.inputData === "Manual") {
        //   //   console.log(newInputData[item].inputData);
        //   //   selectedItemHandler(newInputData[item].inputData, "manuals", newInputData[item].id);

        //   //   newInputData.motorizeds.inputData = "";
        //   //   newInputData.motorizeds.requieredState.isRequiered = false;
        //   //   newInputData.motorizeds.requieredState.theState = true;
        //   //   console.log(item);
        //   // }
        // }
        // dataSetter(item, newInputData[item].inputData, newInputData[item].id);
      });
    }
    const newInputData = { ...inputData };
    setInputData({ ...newInputData });
    setDisabledItems({
      categories: true,
      lightControls: true,
      fabrics: true,
      cassetteTypes: true,
      cassetteColors: true,
      manuals: true,
      motorizeds: true,
    });
  };
  // JSX code  /////////////////////////////////////////////////////////////////////////////////
  return (
    <div className="get-order-info">
      <form
        className="get-info-form"
        onSubmit={(e) => {
          formSubmitHandler(e);
        }}
      >
        <div className="tools">
          <div className="measurement-type">
            <label>
              <input
                type="checkBox"
                data-name="IN"
                checked={isChecked.IN}
                onChange={(e) => measurement(e.target.dataset.name)}
              ></input>
              IN
            </label>
            <label>
              <input
                type="checkBox"
                data-name="CM"
                checked={isChecked.CM}
                onChange={(e) => measurement(e.target.dataset.name)}
              ></input>
              CM
            </label>
            <label>
              <input
                type="checkBox"
                data-name="FT"
                checked={isChecked.FT}
                onChange={(e) => measurement(e.target.dataset.name)}
              ></input>
              FT
            </label>
          </div>
          <div className="duplicate-info">
            <label>
              <input
                type="checkBox"
                data-name="duplicate"
                checked={isDuplicate}
                onChange={(e) => duplicateItems(e)}
              ></input>
              Duplicate Items
            </label>
          </div>
        </div>
        <div className="form-size-wrapper">
          {/* width */}
          <div className="form-width form-size">
            <input
              min="1"
              max="500"
              data-name="width"
              className="form-input"
              type="number"
              autoComplete="off"
              placeholder="Width"
              value={inputData.width.inputData}
              data-id={inputData.width.id}
              onChange={(e) => {
                onChangeHandler(e);
              }}
            ></input>
            {/* width decimal */}
            <div className={`dropdown-parent ${measure !== "IN" ? "disable-item" : ""}`}>
              <div className="form-input-dropdown-wrapper">
                <div className="form-input-dropdown-wrapper-header">
                  <input
                    onClick={(e) => dropDownHandler(e.target.dataset.name)}
                    data-name="widthDecimals"
                    className="form-input-dropdown"
                    type="text"
                    autoComplete="off"
                    placeholder="Fraction"
                    readOnly
                    value={inputData.widthDecimals.inputData}
                    data-id={inputData.widthDecimals.id}
                    onChange={(e) => {
                      onChangeHandler(e);
                    }}
                  ></input>
                  <i className="fa fa-angle-down" style={dropdownStatus.widthDecimals.transform}>
                    {" "}
                  </i>
                </div>
                <div className="dropdown-content" style={dropdownStatus.widthDecimals.display}>
                  <DropDownMaker
                    dropDownList={widthDecimals.widthDecimals}
                    categoryTitle={"widthDecimals"}
                    selectedItemHandler={selectedItemHandler}
                  />
                </div>
              </div>
            </div>
            {/* 
                        <input data-name='widthNumerator' className='form-input' type='number' placeholder='Numerator' value={inputData.widthNumerator.inputData} onChange={(e) => { onChangeHandler(e) }} ></input> /
                        <input data-name='widthDenominator' className='form-input' type='number' placeholder='Denominator' value={inputData.widthDenominator.inputData} onChange={(e) => { onChangeHandler(e) }}></input> */}
          </div>

          {/* length */}
          <div className="form-length form-size">
            <input
              min="1"
              max="500"
              autoComplete="off"
              data-name="length"
              className="form-input"
              type="number"
              placeholder="Length"
              value={inputData.length.inputData}
              data-id={inputData.length.id}
              onChange={(e) => {
                onChangeHandler(e);
              }}
            ></input>

            {/* length decimal */}
            <div className={`dropdown-parent ${measure !== "IN" ? "disable-item" : ""}`}>
              <div className="form-input-dropdown-wrapper">
                <div className="form-input-dropdown-wrapper-header">
                  <input
                    onClick={(e) => dropDownHandler(e.target.dataset.name)}
                    data-name="lengthDecimals"
                    className="form-input-dropdown"
                    type="text"
                    autoComplete="off"
                    placeholder="Fraction"
                    readOnly
                    value={inputData.lengthDecimals.inputData}
                    data-id={inputData.lengthDecimals.id}
                    onChange={(e) => {
                      onChangeHandler(e);
                    }}
                  ></input>
                  <i className="fa fa-angle-down" style={dropdownStatus.lengthDecimals.transform}>
                    {" "}
                  </i>
                </div>
                <div className="dropdown-content" style={dropdownStatus.lengthDecimals.display}>
                  <DropDownMaker
                    dropDownList={lengthDecimals.lengthDecimals}
                    categoryTitle={"lengthDecimals"}
                    selectedItemHandler={selectedItemHandler}
                  />
                </div>
              </div>
            </div>

            {/* <input data-name='lengthNumerator' className='form-input' type='number' placeholder='Numerator' value={inputData.lengthNumerator.inputData} onChange={(e) => { onChangeHandler(e) }}></input> /
                        <input data-name='lengthDenominator' className='form-input' type='number' placeholder='Denominator' value={inputData.lengthDenominator.inputData} onChange={(e) => { onChangeHandler(e) }} ></input> */}
          </div>
        </div>

        {/* Manufacture */}
        <div className="dropdown-parent">
          <div className="form-input-dropdown-wrapper">
            <div className="form-input-dropdown-wrapper-header">
              <input
                onClick={(e) => dropDownHandler(e.target.dataset.name)}
                data-name="manufactures"
                className="form-input-dropdown"
                type="text"
                autoComplete="off"
                placeholder="Manufacture"
                readOnly
                value={inputData.manufactures.inputData}
                data-id={inputData.manufactures.id}
                onChange={(e) => {
                  onChangeHandler(e);
                }}
              ></input>
              <i className="fa fa-angle-down" style={dropdownStatus.manufactures.transform}>
                {" "}
              </i>
            </div>
            <div className="dropdown-content" style={dropdownStatus.manufactures.display}>
              <DropDownMaker
                dropDownList={manufactures.manufactures}
                categoryTitle={"manufactures"}
                selectedItemHandler={selectedItemHandler}
              />
            </div>
          </div>
        </div>

        {/* category */}
        <div className={`dropdown-parent ${!disabledItems.categories ? "disable-item" : ""}`}>
          <div className="form-input-dropdown-wrapper">
            <div className="form-input-dropdown-wrapper-header">
              <input
                onClick={(e) => dropDownHandler(e.target.dataset.name)}
                data-name="categories"
                className="form-input-dropdown"
                type="text"
                autoComplete="off"
                placeholder="Category"
                readOnly
                value={inputData.categories.inputData}
                data-id={inputData.categories.id}
                onChange={(e) => {
                  onChangeHandler(e);
                }}
              ></input>
              <i className="fa fa-angle-down" style={dropdownStatus.categories.transform}>
                {" "}
              </i>
            </div>
            <div className="dropdown-content" style={dropdownStatus.categories.display}>
              <DropDownMaker
                dropDownList={filteredCategory.categories.categories}
                categoryTitle={"categories"}
                selectedItemHandler={selectedItemHandler}
              />
            </div>
          </div>
        </div>

        {/* lightControl */}
        <div className={`dropdown-parent ${!disabledItems.lightControls ? "disable-item" : ""}`}>
          <div className="form-input-dropdown-wrapper">
            <div className="form-input-dropdown-wrapper-header">
              <input
                onClick={(e) => dropDownHandler(e.target.dataset.name)}
                data-name="lightControls"
                className="form-input-dropdown"
                type="text"
                autoComplete="off"
                placeholder="Light Control"
                readOnly
                value={inputData.lightControls.inputData}
                data-id={inputData.lightControls.id}
                onChange={(e) => {
                  onChangeHandler(e);
                }}
              ></input>
              <i className="fa fa-angle-down" style={dropdownStatus.lightControls.transform}>
                {" "}
              </i>
            </div>
            <div className="dropdown-content" style={dropdownStatus.lightControls.display}>
              <DropDownMaker
                dropDownList={filteredCategory.lightControls.lightControls}
                categoryTitle={"lightControls"}
                selectedItemHandler={selectedItemHandler}
              />
            </div>
          </div>
        </div>

        {/* fabric */}
        <div className={`dropdown-parent ${!disabledItems.fabrics ? "disable-item" : ""}`}>
          <div className="form-input-dropdown-wrapper">
            <div className="form-input-dropdown-wrapper-header">
              <input
                onClick={(e) => dropDownHandler(e.target.dataset.name)}
                data-name="fabrics"
                className="form-input-dropdown"
                type="text"
                autoComplete="off"
                placeholder="Fabric"
                readOnly
                value={inputData.fabrics.inputData}
                data-id={inputData.fabrics.id}
                onChange={(e) => {
                  onChangeHandler(e);
                }}
              ></input>
              <i className="fa fa-angle-down" style={dropdownStatus.fabrics.transform}>
                {" "}
              </i>
            </div>
            <div className="dropdown-content" style={dropdownStatus.fabrics.display}>
              <DropDownMaker
                dropDownList={filteredCategory.fabrics.fabrics}
                categoryTitle={"fabrics"}
                selectedItemHandler={selectedItemHandler}
              />
            </div>
          </div>
        </div>

        {/* Area */}
        <div className="dropdown-parent">
          <div className="form-input-dropdown-wrapper">
            <input
              data-name="area"
              className="form-input"
              type="text"
              autoComplete="off"
              maxLength="12"
              placeholder="Area"
              value={inputData.area.inputData}
              data-id={inputData.area.id}
              onChange={(e) => {
                onChangeHandler(e);
              }}
            ></input>
          </div>
        </div>

        {/* QTY */}
        <div className="dropdown-parent">
          <div className="form-input-dropdown-wrapper">
            <input
              data-name="quantity"
              className="form-input"
              type="number"
              autoComplete="off"
              min="1"
              max="5000"
              placeholder="Quantity"
              value={inputData.quantity.inputData}
              data-id={inputData.quantity.id}
              onChange={(e) => {
                onChangeHandler(e);
              }}
            ></input>
          </div>
        </div>

        {/* Placement */}
        <div className="dropdown-parent">
          <div className="form-input-dropdown-wrapper">
            <div className="form-input-dropdown-wrapper-header">
              <input
                onClick={(e) => dropDownHandler(e.target.dataset.name)}
                data-name="placements"
                className="form-input-dropdown"
                type="text"
                autoComplete="off"
                placeholder="Placement"
                readOnly
                value={inputData.placements.inputData}
                data-id={inputData.placements.id}
                onChange={(e) => {
                  onChangeHandler(e);
                }}
              ></input>
              <i className="fa fa-angle-down" style={dropdownStatus.placements.transform}>
                {" "}
              </i>
            </div>
            <div className="dropdown-content" style={dropdownStatus.placements.display}>
              <DropDownMaker
                dropDownList={placements.placements}
                categoryTitle={"placements"}
                selectedItemHandler={selectedItemHandler}
              />
            </div>
          </div>
        </div>

        {/* color */}
        <div className="dropdown-parent">
          <div className="form-input-dropdown-wrapper">
            <input
              data-name="color"
              className="form-input"
              type="text"
              autoComplete="off"
              placeholder="Color"
              value={inputData.color.inputData}
              data-id={inputData.color.id}
              onChange={(e) => {
                onChangeHandler(e);
              }}
            ></input>
          </div>
        </div>
        {/* controller */}
        <div className="dropdown-parent">
          <div className="form-input-dropdown-wrapper">
            <div className="form-input-dropdown-wrapper-header">
              <input
                onClick={(e) => dropDownHandler(e.target.dataset.name)}
                data-name="controller"
                className="form-input-dropdown"
                type="text"
                placeholder="Controller"
                autoComplete="off"
                readOnly
                value={inputData.controller.inputData}
                data-id={inputData.controller.id}
                onChange={(e) => {
                  onChangeHandler(e);
                }}
              ></input>
              <i className="fa fa-angle-down" style={dropdownStatus.controller.transform}>
                {" "}
              </i>
            </div>
            <div className="dropdown-content" style={dropdownStatus.controller.display}>
              <DropDownMaker
                dropDownList={controller.controller}
                categoryTitle={"controller"}
                selectedItemHandler={selectedItemHandler}
              />
            </div>
          </div>
        </div>
        {/* manual */}
        <div className={`dropdown-parent ${controllerSetting === "manuals" ? "" : "disable-item"}`}>
          <div className="form-input-dropdown-wrapper">
            <div className="form-input-dropdown-wrapper-header">
              <input
                onClick={(e) => dropDownHandler(e.target.dataset.name)}
                data-name="manuals"
                className="form-input-dropdown"
                type="text"
                placeholder="Set Manual"
                autoComplete="off"
                readOnly
                value={inputData.manuals.inputData}
                data-id={inputData.manuals.id}
                onChange={(e) => {
                  onChangeHandler(e);
                }}
              ></input>
              <i className="fa fa-angle-down" style={dropdownStatus.manuals.transform}>
                {" "}
              </i>
            </div>
            <div className="dropdown-content" style={dropdownStatus.manuals.display}>
              <DropDownMaker
                dropDownList={manuals.manuals}
                categoryTitle={"manuals"}
                selectedItemHandler={selectedItemHandler}
              />
            </div>
          </div>
        </div>

        {/* motorized */}
        <div className={`dropdown-parent ${controllerSetting === "motorizeds" ? "" : "disable-item"}`}>
          <div className="form-input-dropdown-wrapper">
            <div className="form-input-dropdown-wrapper-header">
              <input
                onClick={(e) => dropDownHandler(e.target.dataset.name)}
                data-name="motorizeds"
                className="form-input-dropdown"
                autoComplete="off"
                type="text"
                placeholder="Set Motorized"
                readOnly
                value={inputData.motorizeds.inputData}
                data-id={inputData.motorizeds.id}
                onChange={(e) => {
                  onChangeHandler(e);
                }}
              ></input>
              <i className="fa fa-angle-down" style={dropdownStatus.motorizeds.transform}>
                {" "}
              </i>
            </div>
            <div className="dropdown-content" style={dropdownStatus.motorizeds.display}>
              <DropDownMaker
                dropDownList={motorizeds.motorizeds}
                categoryTitle={"motorizeds"}
                selectedItemHandler={selectedItemHandler}
              />
            </div>
          </div>
        </div>

        {/* position */}
        <div className="dropdown-parent">
          <div className="form-input-dropdown-wrapper">
            <div className="form-input-dropdown-wrapper-header">
              <input
                onClick={(e) => dropDownHandler(e.target.dataset.name)}
                data-name="positions"
                className="form-input-dropdown"
                autoComplete="off"
                type="text"
                placeholder="Position"
                readOnly
                value={inputData.positions.inputData}
                data-id={inputData.positions.id}
                onChange={(e) => {
                  onChangeHandler(e);
                }}
              ></input>
              <i className="fa fa-angle-down" style={dropdownStatus.positions.transform}>
                {" "}
              </i>
            </div>
            <div className="dropdown-content" style={dropdownStatus.positions.display}>
              <DropDownMaker
                dropDownList={positions.positions}
                categoryTitle={"positions"}
                selectedItemHandler={selectedItemHandler}
              />
            </div>
          </div>
        </div>

        {/* cassette type */}
        <div className={`dropdown-parent ${!disabledItems.cassetteTypes ? "disable-item" : ""}`}>
          <div className="form-input-dropdown-wrapper">
            <div className="form-input-dropdown-wrapper-header">
              <input
                onClick={(e) => dropDownHandler(e.target.dataset.name)}
                data-name="cassetteTypes"
                className="form-input-dropdown"
                autoComplete="off"
                type="text"
                placeholder="Cassette Types"
                readOnly
                value={inputData.cassetteTypes.inputData}
                data-id={inputData.cassetteTypes.id}
                onChange={(e) => {
                  onChangeHandler(e);
                }}
              ></input>
              <i className="fa fa-angle-down" style={dropdownStatus.cassetteTypes.transform}>
                {" "}
              </i>
            </div>
            <div className="dropdown-content" style={dropdownStatus.cassetteTypes.display}>
              <DropDownMaker
                dropDownList={filteredCategory.cassetteTypes.cassetteTypes}
                categoryTitle={"cassetteTypes"}
                selectedItemHandler={selectedItemHandler}
              />
            </div>
          </div>
        </div>

        {/* cassette-color */}
        <div className={`dropdown-parent ${!disabledItems.cassetteColors ? "disable-item" : ""}`}>
          <div className="form-input-dropdown-wrapper">
            <div className="form-input-dropdown-wrapper-header">
              <input
                onClick={(e) => dropDownHandler(e.target.dataset.name)}
                data-name="cassetteColors"
                className="form-input-dropdown"
                autoComplete="off"
                type="text"
                placeholder="Cassette Colors"
                readOnly
                value={inputData.cassetteColors.inputData}
                data-id={inputData.cassetteColors.id}
                onChange={(e) => {
                  onChangeHandler(e);
                }}
              ></input>
              <i className="fa fa-angle-down" style={dropdownStatus.cassetteColors.transform}>
                {" "}
              </i>
            </div>
            <div className="dropdown-content" style={dropdownStatus.cassetteColors.display}>
              <DropDownMaker
                dropDownList={cassetteColors.cassetteColors}
                categoryTitle={"cassetteColors"}
                selectedItemHandler={selectedItemHandler}
              />
            </div>
          </div>
        </div>

        {/* layout */}
        <div className="dropdown-parent">
          <div className="form-input-dropdown-wrapper">
            <div className="form-input-dropdown-wrapper-header">
              <input
                onClick={(e) => dropDownHandler(e.target.dataset.name)}
                data-name="layouts"
                className="form-input-dropdown"
                autoComplete="off"
                type="text"
                placeholder="Layout"
                readOnly
                value={inputData.layouts.inputData}
                data-id={inputData.layouts.id}
                onChange={(e) => {
                  onChangeHandler(e);
                }}
              ></input>
              <i className="fa fa-angle-down" style={dropdownStatus.layouts.transform}>
                {" "}
              </i>
            </div>
            <div className="dropdown-content" style={dropdownStatus.layouts.display}>
              <DropDownMaker
                dropDownList={layouts.layouts}
                categoryTitle={"layouts"}
                selectedItemHandler={selectedItemHandler}
              />
            </div>
          </div>
        </div>

        {/* comment */}
        <div className="dropdown-parent">
          <div className="form-input-dropdown-wrapper">
            <input
              data-name="comment"
              className="form-input"
              autoComplete="off"
              type="text"
              maxLength="19"
              placeholder="Comment"
              value={inputData.comment.inputData}
              data-id={inputData.comment.id}
              onChange={(e) => {
                onChangeHandler(e);
              }}
            ></input>
          </div>
        </div>

        {/* button */}
        <div className="form-button ">
          <button className="button-reset" type="reset" onClick={() => resetForm()}>
            Clear
          </button>
          <button className="button-submit " type="submit" onClick={(e) => formSubmitHandler(e)}>
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default OrderInfo;
