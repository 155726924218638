import "../../styles/InvoiceTableHeading.css";

const InvoiceTableHeading = () => {
  return (
    <>
      <tr>
        <td className="table-header">Blinds</td>
      </tr>
      <tr className="table-heading-parent">
        <th className="table-heading edit">Edit</th>
        <th className="table-heading row">Row</th>
        <th className="table-heading area">Area</th>
        <th className="table-heading quantity">Qty</th>
        <th className="table-heading width">Width</th>
        <th className="table-heading length">Length</th>
        <th className="table-heading placement">Placement</th>
        <th className="table-heading color">Color</th>
        <th className="table-heading manufacture">Factory</th>
        <th className="table-heading category">Category</th>
        <th className="table-heading lightcontrol">Light Control</th>
        <th className="table-heading fabric">Fabric</th>
        {/* <th className="table-heading Manual">Manual</th> */}
        <th className="table-heading Manual">Controller</th>
        {/* <th className="table-heading Motorized">Motorized</th> */}
        <th className="table-heading position">Position</th>
        <th className="table-heading cassette-type">Cassette-T</th>
        <th className="table-heading cassette-color">Cassette-C</th>
        <th className="table-heading layout">Layout</th>
        <th className="table-heading comment">Comment</th>
        <th className="table-heading unit-price">Unit Price</th>
        <th className="table-heading total-price">Total Price</th>
        <th className="table-heading delete">Delete</th>
      </tr>
    </>
  );
};

export default InvoiceTableHeading;
