import { useEffect, useState } from "react";
import ResultFormTableHeader from "./ResultFormTableHeader";
import ResultFormTableSummary from "./ResultFormTableSummary";
import ResultFormRowHeader from "./ResultFormRowHeader";
import ResultFormRowData from "./ResultFormRowData";
import ResultFormSigning from "./ResultFormSigning";
import writeToDB from "../../firebase/writeToDB";

import "../../styles/ExportPages.css";

import html2canvas from "html2canvas";
import jsPDF from "jspdf";

import * as XLSX from "xlsx";
// import readFromDB from "../../firebase/readFromDB";

const SalesContractResult = ({
  customeOrderInformation,
  customerInformation,
  orderInformation,
  accessoriesInformation,
  resultInformation,
  otherInformation,
  exportType,
  status,
}) => {
  // console.log(status);

  const [info, setInfo] = useState({
    customeOrdr: { ...customeOrderInformation },
    customer: { ...customerInformation },
    order: { ...orderInformation },
    accessories: { ...accessoriesInformation },
    result: { ...resultInformation },
    other: { ...otherInformation },
    status: { ...status },
  });
  useEffect(() => {
    setInfo({
      customer: { ...customerInformation },
      customeOrdr: { ...customeOrderInformation },
      order: { ...orderInformation },
      accessories: { ...accessoriesInformation },
      result: { ...resultInformation },
      other: { ...otherInformation },
      status: { ...status },
    });
  }, [
    customerInformation,
    customeOrderInformation,
    orderInformation,
    status,
    accessoriesInformation,
    resultInformation,
    otherInformation,
  ]);

  useEffect(() => {
    // eslint-disable-next-line
    finalHandler();
    // eslint-disable-next-line
  }, [info]);

  // final table result /////////////////////////////////////////////////////////////////
  const [final, setFinal] = useState([]);
  const finalHandler = () => {
    const newFinal = [];
    Object.keys(orderInformation).length > 0 &&
      Object.keys(orderInformation).map((item) => {
        const newItem = {
          item: orderInformation[item].area.inputData,
          quantity: orderInformation[item].quantity.inputData,
          width: `${orderInformation[item].width.inputData} ${orderInformation[item].widthDecimals.inputData}`,
          length: `${orderInformation[item].length.inputData} ${orderInformation[item].lengthDecimals.inputData}`,
          placements: orderInformation[item].placements.inputData,
          color: orderInformation[item].color.inputData,
          manufactures: orderInformation[item].manufactures.inputData,
          categories: orderInformation[item].categories.inputData,
          lightControls: orderInformation[item].lightControls.inputData,
          fabrics: orderInformation[item].fabrics.inputData,
          controller: orderInformation[item].motorizeds.inputData
            ? orderInformation[item].motorizeds.inputData
            : orderInformation[item].manuals.inputData,
          positions: orderInformation[item].positions.inputData,
          cassetteTypes: orderInformation[item].cassetteTypes.inputData,
          cassetteColors: orderInformation[item].cassetteColors.inputData,
          layouts: orderInformation[item].layouts.inputData,
          note: orderInformation[item].comment.inputData,
          unitPrice: orderInformation[item].unitPrice.inputData,
          totalPrice: orderInformation[item].totalPrice.inputData,
        };
        newFinal.push(newItem);
        return null;
      });
    Object.keys(accessoriesInformation).length > 0 &&
      Object.keys(accessoriesInformation).map((item, index) => {
        // console.log(accessoriesInformation[item]);
        const newItem = {
          item: accessoriesInformation[item].accessories.inputData,
          quantity: accessoriesInformation[item].quantity.inputData,
          width: "",
          length: "",
          placements: "",
          color: "",
          manufactures: accessoriesInformation[item].manufactures.inputData,
          categories: accessoriesInformation[item].models.inputData,
          lightControls: "",
          fabrics: "",
          controller: "",
          positions: "",
          cassetteTypes: "",
          cassetteColors: "",
          layouts: "",
          note: "",
          unitPrice: accessoriesInformation[item].unitPrice.inputData,
          totalPrice: accessoriesInformation[item].totalPrice.inputData,
        };
        newFinal.push(newItem);

        return null;
      });
    Object.keys(customeOrderInformation).length > 0 &&
      Object.keys(customeOrderInformation).map((item, index) => {
        // console.log(customeOrderInformation[item]);
        const newItem = {
          item: customeOrderInformation[item].customeitem.inputData,
          quantity: customeOrderInformation[item].quantity.inputData,
          width: "",
          length: "",
          placements: "",
          color: "",
          manufactures: "",
          categories: "",
          lightControls: "",
          fabrics: "",
          controller: "",
          positions: "",
          cassetteTypes: "",
          cassetteColors: "",
          layouts: "",
          note: customeOrderInformation[item].comment.inputData,
          unitPrice: customeOrderInformation[item].unitPrice.inputData,
          totalPrice: customeOrderInformation[item].totalPrice.inputData,
        };
        newFinal.push(newItem);

        return null;
      });
    // console.log(newFinal);

    // console.log(newFinal);
    setFinal([...newFinal]);
    // setFinal([...exportPdfHandler(newFinal)]);
    // console.log(exportPdfHandler(newFinal));
    return null;
  };

  // PDF EXPORT /////////////////////////////////////////////////////////////////////////
  const handlePrint = async () => {
    const dpi = 150; // Adjust as needed
    const scale = dpi / 96; // 96 is the default DPI of most screens
    const pdf = new jsPDF("l", "mm", "a4"); // Initialize PDF document

    // Iterate through each invoice
    for (let i = 1; i <= 5; i++) {
      const invoiceId = `invoice${i}`;
      const invoiceElement = document.getElementById(invoiceId);

      // Check if the invoice element exists
      if (invoiceElement) {
        // Add a page for each invoice except the first one
        if (i !== 1) {
          pdf.addPage();
        }

        // Apply CSS styles to the invoice element
        invoiceElement.style.width = "100%";
        invoiceElement.style.padding = "0";
        invoiceElement.style.margin = "0";
        invoiceElement.style.boxSizing = "border-box";
        invoiceElement.style.fontFamily = "'Poppins', sans-serif";
        invoiceElement.style.color = "#1e1e1e";

        await html2canvas(invoiceElement, {
          scale: scale,
          // pageStyle: additionalStyles,
          useCORS: true, // Add this option to use additional CSS styles
        }).then((canvas) => {
          const imgData = canvas.toDataURL("image/png");
          const imgWidth = pdf.internal.pageSize.getWidth(); // A4 width in mm (landscape mode)
          const imgHeight = (canvas.height * imgWidth) / canvas.width; // Adjust height to maintain aspect ratio
          pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight);
        });
      }
    }

    // Save the PDF with a custom name

    if (exportType === "FACTORY") {
      pdf.save(`${exportType} - ${info.customer.quotationNumber} - BlindsFactory.pdf`);
    } else {
      pdf.save(`${exportType} - ${info.customer.quotationNumber} - ${info.customer.name} - BlindsFactory.pdf`);
    }
  };

  // Save as File  ///////////////////////////////////////////////////////////////////////
  const handleDownload = () => {
    info.result.subTotal * 1 > 0
      ? writeToDB(JSON.stringify(info), "orders", info.customer.quotationNumber)
      : alert("Check Info Details");

    // console.log(info);
    // console.log(info);
    const fileName = `APP-File - ${info.customer.quotationNumber} - ${info.customer.name}`;
    // Convert JSON data to Blob
    const blob = new Blob([JSON.stringify(info)], { type: "application/json" });
    const url = URL.createObjectURL(blob);

    // Create anchor element
    const link = document.createElement("a");
    link.href = url;
    link.download = fileName;
    // Simulate click on anchor element to trigger download
    link.click();
    // Clean up by revoking the URL
    URL.revokeObjectURL(url);
  };

  // Export Excell ///////////////////////////////////////////////////////////////////////
  const handleExcelExport = () => {
    const fileName = `Factory - PO-${info.customer.quotationNumber} - BlindsFactory.xlsx`;

    // Create a new workbook
    const wb = XLSX.utils.book_new();

    const newData = final.map((item) => {
      delete item.unitPrice;
      delete item.totalPrice;
      return item;
    });
    // console.log(newData);
    // Convert your JSON data to worksheet
    const ws = XLSX.utils.json_to_sheet(newData);

    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    // Save the workbook as a file
    XLSX.writeFile(wb, fileName);
  };

  ///////////////////////////////////////////////////////////////////////////////////////

  // JSX  ////////////////////////////////////////////////////////////////////////////////
  return (
    <>
      {/* Export Button ================================================================= */}
      <div className="export-button">
        <div onClick={handlePrint}>
          <i className="fa fa-file-pdf"></i>
          <button>Export PDF</button>
        </div>
        <div onClick={handleDownload}>
          <i className="fa fa-save"></i>
          <button>Save File</button>
        </div>
        <div onClick={handleExcelExport}>
          <i className="fa fa-file-excel"></i>
          <button>Export Excel</button>
        </div>
      </div>
      {/* Result Form =================================================================== */}
      <div className="form-container">
        {/* For Lenght  0 to 20 ========================================== */}
        {final.length > 0 && final.length <= 20 && (
          <div className="export invoice" id="invoice1">
            <div className="export-wrapper">
              <ResultFormTableHeader formTitle={exportType} info={info} />
              <div className="export-body">
                {/* table info */}
                <div className="export-body-blinds">
                  <table>
                    <ResultFormRowHeader formTitle={exportType} final={final} />
                    <tbody>
                      {final.map((order, index) => {
                        // console.log("order", order);
                        return <ResultFormRowData order={order} index={index} key={index} formTitle={exportType} />;
                      })}
                    </tbody>
                  </table>
                </div>
                {/* final result */}
                <ResultFormTableSummary formTitle={exportType} info={info} pageNumber={"1 / 1"} />
              </div>
            </div>
          </div>
        )}
        {/* For Lenght  20 to 45 ========================================= */}
        {final.length > 20 && final.length <= 45 && (
          <>
            <div className="export invoice" id="invoice1">
              <div className="export-wrapper">
                <ResultFormTableHeader formTitle={exportType} info={info} />
                <div className="export-body">
                  {/* table info */}
                  <div className="export-body-blinds">
                    <table>
                      <ResultFormRowHeader formTitle={exportType} final={final} />
                      <tbody>
                        {final.map((order, index) => {
                          // console.log("order", order);
                          if (index < 25) {
                            return <ResultFormRowData order={order} index={index} key={index} formTitle={exportType} />;
                          } else {
                            return <></>;
                          }
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
                <ResultFormSigning pageNumber={"1 / 2"} formTitle={exportType} />
              </div>
            </div>
            <div className="export invoice" id="invoice2">
              <div className="export-wrapper">
                <ResultFormTableHeader formTitle={exportType} info={info} />
                <div className="export-body">
                  {/* table info */}
                  <div className="export-body-blinds">
                    <table>
                      <ResultFormRowHeader formTitle={exportType} final={final} />
                      <tbody>
                        {final.map((order, index) => {
                          if (index >= 25) {
                            return <ResultFormRowData order={order} index={index} key={index} formTitle={exportType} />;
                          } else {
                            return <></>;
                          }
                        })}
                      </tbody>
                    </table>
                  </div>
                  {/* final result */}
                  <ResultFormTableSummary formTitle={exportType} info={info} pageNumber={"2 / 2"} />
                </div>
              </div>
            </div>
          </>
        )}
        {/* For Lenght  45 to 70 ========================================= */}
        {final.length > 45 && final.length <= 70 && (
          <>
            <div className="export invoice" id="invoice1">
              <div className="export-wrapper">
                <ResultFormTableHeader formTitle={exportType} info={info} />
                <div className="export-body">
                  {/* table info */}
                  <div className="export-body-blinds">
                    <table>
                      <ResultFormRowHeader formTitle={exportType} final={final} />
                      <tbody>
                        {final.map((order, index) => {
                          // console.log("order", order);
                          if (index < 25) {
                            return <ResultFormRowData order={order} index={index} key={index} formTitle={exportType} />;
                          } else {
                            return <></>;
                          }
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
                <ResultFormSigning pageNumber={"1 / 3"} formTitle={exportType} />
              </div>
            </div>
            <div className="export invoice" id="invoice2">
              <div className="export-wrapper">
                <ResultFormTableHeader formTitle={exportType} info={info} />
                <div className="export-body">
                  {/* table info */}
                  <div className="export-body-blinds">
                    <table>
                      <ResultFormRowHeader formTitle={exportType} final={final} />
                      <tbody>
                        {final.map((order, index) => {
                          // console.log("order", order);
                          if (index >= 25 && index < 50) {
                            return <ResultFormRowData order={order} index={index} key={index} formTitle={exportType} />;
                          } else {
                            return <></>;
                          }
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
                <ResultFormSigning pageNumber={"2 / 3"} formTitle={exportType} />
              </div>
            </div>
            <div className="export invoice" id="invoice3">
              <div className="export-wrapper">
                <ResultFormTableHeader formTitle={exportType} info={info} />
                <div className="export-body">
                  {/* table info */}
                  <div className="export-body-blinds">
                    <table>
                      <ResultFormRowHeader formTitle={exportType} final={final} />
                      <tbody>
                        {final.map((order, index) => {
                          // console.log("order", order);
                          if (index >= 50) {
                            return <ResultFormRowData order={order} index={index} key={index} formTitle={exportType} />;
                          } else {
                            return <></>;
                          }
                        })}
                      </tbody>
                    </table>
                  </div>
                  {/* final result */}
                  <ResultFormTableSummary formTitle={exportType} info={info} pageNumber={"3 / 3"} />
                </div>
              </div>
            </div>
          </>
        )}
        {/* For Lenght  70 to 95 ========================================= */}
        {final.length > 70 && final.length <= 95 && (
          <>
            <div className="export invoice" id="invoice1">
              <div className="export-wrapper">
                <ResultFormTableHeader formTitle={exportType} info={info} />
                <div className="export-body">
                  {/* table info */}
                  <div className="export-body-blinds">
                    <table>
                      <ResultFormRowHeader formTitle={exportType} final={final} />
                      <tbody>
                        {final.map((order, index) => {
                          // console.log("order", order);
                          if (index < 25) {
                            return <ResultFormRowData order={order} index={index} key={index} formTitle={exportType} />;
                          } else {
                            return <></>;
                          }
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
                <ResultFormSigning pageNumber={"1 / 4"} formTitle={exportType} />
              </div>
            </div>
            <div className="export invoice" id="invoice2">
              <div className="export-wrapper">
                <ResultFormTableHeader formTitle={exportType} info={info} />
                <div className="export-body">
                  {/* table info */}
                  <div className="export-body-blinds">
                    <table>
                      <ResultFormRowHeader formTitle={exportType} final={final} />
                      <tbody>
                        {final.map((order, index) => {
                          // console.log("order", order);
                          if (index >= 25 && index < 50) {
                            return <ResultFormRowData order={order} index={index} key={index} formTitle={exportType} />;
                          } else {
                            return <></>;
                          }
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
                <ResultFormSigning pageNumber={"2 / 4"} formTitle={exportType} />
              </div>
            </div>
            <div className="export invoice" id="invoice3">
              <div className="export-wrapper">
                <ResultFormTableHeader formTitle={exportType} info={info} />
                <div className="export-body">
                  {/* table info */}
                  <div className="export-body-blinds">
                    <table>
                      <ResultFormRowHeader formTitle={exportType} final={final} />
                      <tbody>
                        {final.map((order, index) => {
                          // console.log("order", order);
                          if (index >= 50 && index < 75) {
                            return <ResultFormRowData order={order} index={index} key={index} formTitle={exportType} />;
                          } else {
                            return <></>;
                          }
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
                <ResultFormSigning pageNumber={"3 / 4"} formTitle={exportType} />
              </div>
            </div>
            <div className="export invoice" id="invoice4">
              <div className="export-wrapper">
                <ResultFormTableHeader formTitle={exportType} info={info} />
                <div className="export-body">
                  {/* table info */}
                  <div className="export-body-blinds">
                    <table>
                      <ResultFormRowHeader formTitle={exportType} final={final} />
                      <tbody>
                        {final.map((order, index) => {
                          // console.log("order", order);
                          if (index >= 75) {
                            return <ResultFormRowData order={order} index={index} key={index} formTitle={exportType} />;
                          } else {
                            return <></>;
                          }
                        })}
                      </tbody>
                    </table>
                  </div>
                  {/* final result */}
                  <ResultFormTableSummary formTitle={exportType} info={info} pageNumber={"4 / 4"} />
                </div>
              </div>
            </div>
          </>
        )}
        {/* For Lenght  95 to 120 ======================================== */}
        {final.length > 95 && final.length <= 120 && (
          <>
            <div className="export invoice" id="invoice1">
              <div className="export-wrapper">
                <ResultFormTableHeader formTitle={exportType} info={info} />
                <div className="export-body">
                  {/* table info */}
                  <div className="export-body-blinds">
                    <table>
                      <ResultFormRowHeader formTitle={exportType} final={final} />
                      <tbody>
                        {final.map((order, index) => {
                          // console.log("order", order);
                          if (index < 25) {
                            return <ResultFormRowData order={order} index={index} key={index} formTitle={exportType} />;
                          } else {
                            return <></>;
                          }
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
                <ResultFormSigning pageNumber={"1 / 5"} formTitle={exportType} />
              </div>
            </div>
            <div className="export invoice" id="invoice2">
              <div className="export-wrapper">
                <ResultFormTableHeader formTitle={exportType} info={info} />
                <div className="export-body">
                  {/* table info */}
                  <div className="export-body-blinds">
                    <table>
                      <ResultFormRowHeader formTitle={exportType} final={final} />
                      <tbody>
                        {final.map((order, index) => {
                          // console.log("order", order);
                          if (index >= 25 && index < 50) {
                            return <ResultFormRowData order={order} index={index} key={index} formTitle={exportType} />;
                          } else {
                            return <></>;
                          }
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
                <ResultFormSigning pageNumber={"2 / 5"} formTitle={exportType} />
              </div>
            </div>
            <div className="export invoice" id="invoice3">
              <div className="export-wrapper">
                <ResultFormTableHeader formTitle={exportType} info={info} />
                <div className="export-body">
                  {/* table info */}
                  <div className="export-body-blinds">
                    <table>
                      <ResultFormRowHeader formTitle={exportType} final={final} />
                      <tbody>
                        {final.map((order, index) => {
                          // console.log("order", order);
                          if (index >= 50 && index < 75) {
                            return <ResultFormRowData order={order} index={index} key={index} formTitle={exportType} />;
                          } else {
                            return <></>;
                          }
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
                <ResultFormSigning pageNumber={"3 / 5"} formTitle={exportType} />
              </div>
            </div>
            <div className="export invoice" id="invoice4">
              <div className="export-wrapper">
                <ResultFormTableHeader formTitle={exportType} info={info} />
                <div className="export-body">
                  {/* table info */}
                  <div className="export-body-blinds">
                    <table>
                      <ResultFormRowHeader formTitle={exportType} final={final} />
                      <tbody>
                        {final.map((order, index) => {
                          // console.log("order", order);
                          if (index >= 75 && index < 100) {
                            return <ResultFormRowData order={order} index={index} key={index} formTitle={exportType} />;
                          } else {
                            return <></>;
                          }
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
                <ResultFormSigning pageNumber={"4 / 5"} formTitle={exportType} />
              </div>
            </div>
            <div className="export invoice" id="invoice5">
              <div className="export-wrapper">
                <ResultFormTableHeader formTitle={exportType} info={info} />
                <div className="export-body">
                  {/* table info */}
                  <div className="export-body-blinds">
                    <table>
                      <ResultFormRowHeader formTitle={exportType} final={final} />
                      <tbody>
                        {final.map((order, index) => {
                          // console.log("order", order);
                          if (index >= 100) {
                            return <ResultFormRowData order={order} index={index} key={index} formTitle={exportType} />;
                          } else {
                            return <></>;
                          }
                        })}
                      </tbody>
                    </table>
                  </div>
                  {/* final result */}
                  <ResultFormTableSummary formTitle={exportType} info={info} pageNumber={"5 / 5"} />
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default SalesContractResult;
