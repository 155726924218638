import "../../styles/AppFooter.css";

const AppFooter = () => {
  return (
    <footer className="footer">
      {/* <div className="copyright">
        <a href="www.finotech.ca">Designed FINOTEC</a>
      </div> */}
      <div className="copyright">
        Copyright 2024 &copy;
        <a href="www.nilshades.app">NilShades.App</a>
      </div>
    </footer>
  );
};

export default AppFooter;
