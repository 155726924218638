import "../../styles/DropDownMaker.css";

const DropDownMaker = ({ dropDownList, categoryTitle, selectedItemHandler }) => {
  const onClickHandler = (e) => {
    selectedItemHandler(e.target.dataset.name, categoryTitle, e.target.dataset.id);
  };

  // console.log(dropDownList, categoryTitle, selectedItemHandler);
  // console.log(categoryTitle, ":", dropDownList)

  // console.log(categoryTitle, ":          ", dropDownList)

  return (
    <div className="dropdown-wrapper">
      <div className="dropdown">
        <ul>
          {dropDownList.map((element) => {
            return (
              <li key={element.id} data-name={element.title} data-id={element.id} onClick={(e) => onClickHandler(e)}>
                {element.title}
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};
export default DropDownMaker;
