import { BrowserRouter as Router } from "react-router-dom";
import { useEffect, useState } from "react";
import AppHeader from "./components/layouts/AppHeader.js";
import AppFooter from "./components/layouts/AppFooter.js";
import AppMain from "./components/layouts/AppMain.js";
import "./styles/App.css";
import Login from "./components/others/Login.js";
import WelcomePage from "./components/pages/WelcomePage.js";

function App() {
  const [loginState, setLoginState] = useState(false);
  const [authenticatedUser, setAutenticatedUser] = useState({
    role: null,
    userId: null,
    uid: null,
  });
  const [user, setUser] = useState({
    username: "",
    password: "",
    name: "",
    phone: "",
    address: "",
    postal: "",
  });
  // useEffect(() => {
  //   console.log(authenticatedUser.role);
  // }, [authenticatedUser]);

  // Get User Name /////////////////////////////////////////////////////////
  const [PoNumber, setPoNumber] = useState({
    poNumber: "",
    po: "",
    username: "",
    userId: "",
  });

  // Reset Data ///////////////////////////////////////////////////////////////
  const [resetData, setResetData] = useState(false);

  // Permission Handler //////////////////////////////////////////////////////
  const [permission, setPermission] = useState(null);
  useEffect(() => {
    setPermission(authenticatedUser.role * 1);
  }, [authenticatedUser]);

  return (
    <Router>
      <Login
        setAutenticatedUser={setAutenticatedUser}
        user={user}
        setUser={setUser}
        loginState={loginState}
        setLoginState={setLoginState}
        setPoNumber={setPoNumber}
        PoNumber={PoNumber}
      />

      {loginState &&
        (permission >= 107 ? (
          <WelcomePage />
        ) : (
          <div className="app" style={{ display: loginState ? "flex" : "none" }}>
            <AppHeader setResetData={setResetData} authenticatedUser={authenticatedUser} />
            <AppMain
              authenticatedUser={authenticatedUser}
              PoNumber={PoNumber}
              setPoNumber={setPoNumber}
              resetData={resetData}
              setResetData={setResetData}
              setAutenticatedUser={setAutenticatedUser}
              user={user}
              setUser={setUser}
            />
            <AppFooter />
          </div>
        ))}
    </Router>
  );
}

export default App;
