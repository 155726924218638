const manufactures = require("../../dataBase/manufactures.json");
const categories = require("../../dataBase/categories.json");
const lightControls = require("../../dataBase/lightControls.json");
const fabrics = require("../../dataBase/fabrics.json");
const positions = require("../../dataBase/positions.json");
const layouts = require("../../dataBase/layouts.json");
const placements = require("../../dataBase/placements.json");
const motorizeds = require("../../dataBase/motorizeds.json");
const manuals = require("../../dataBase/manuals.json");
const controller = require("../../dataBase/controller.json");
const cassetteTypes = require("../../dataBase/cassetteTypes.json");
const widthDecimals = require("../../dataBase/widthDecimals.json");
const lengthDecimals = require("../../dataBase/lengthDecimals.json");
const cassetteColors = require("../../dataBase/cassetteColors.json");
const accessories = require("../../dataBase/accessories.json");
// eslint-disable-next-line
export default {
  manufactures,
  cassetteColors,
  categories,
  cassetteTypes,
  lightControls,
  widthDecimals,
  lengthDecimals,
  fabrics,
  positions,
  layouts,
  placements,
  motorizeds,
  manuals,
  accessories,
  controller,
};
