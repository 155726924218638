import { Link } from "react-router-dom";
import "../../styles/AppMenu.css";
import { useEffect, useState } from "react";
import {
  ROLE_DEVELOPPER,
  ROLE_MANAGER,
  ROLE_ADMINISTRATOR,
  ROLE_INSTALLER,
  ROLE_MEASURER,
  ROLE_SALES,
  ROLE_ACCOUNTANT,
  ROLE_CONSULTANT,
  ROLE_SUPPLIER,
  ROLE_DEALER,
  ROLE_FRANCHISE,
  ROLE_CUSTOMER,
  ROLE_TRAINER,
} from "../../dataBase/users/roleTypes";
const AppMenu = ({ setResetData, authenticatedUser }) => {
  const [permission, setPermission] = useState({
    addCustomer: false,
    addBlinds: false,
    addAccessories: false,
    addCustomeOrder: false,
    checkout: false,
    customersList: false,
    ordersList: false,
    report: false,
    resetAll: false,
  });

  // Permission Handler
  const permissionHandler = (role = 111) => {
    if (role === ROLE_DEVELOPPER || role === ROLE_MANAGER) {
      setPermission({
        addCustomer: true,
        addBlinds: true,
        addAccessories: true,
        addCustomeOrder: true,
        checkout: true,
        customersList: true,
        ordersList: true,
        report: true,
        resetAll: true,
      });
    } else if (role === ROLE_ADMINISTRATOR) {
      setPermission({
        addCustomer: true,
        addBlinds: true,
        addAccessories: true,
        checkout: true,
        addCustomeOrder: true,
        customersList: true,
        ordersList: true,
        report: false,
        resetAll: true,
      });
    } else if (role === ROLE_SALES || role === ROLE_TRAINER || role === ROLE_FRANCHISE || role === ROLE_DEALER) {
      setPermission({
        addCustomer: true,
        addBlinds: true,
        addAccessories: true,
        addCustomeOrder: true,

        checkout: true,
        customersList: false,
        ordersList: true,
        report: false,
        resetAll: true,
      });
    } else if (role === ROLE_INSTALLER || role === ROLE_MEASURER) {
      setPermission({
        addCustomer: false,
        addBlinds: false,
        addAccessories: false,
        addCustomeOrder: false,

        checkout: false,
        customersList: false,
        ordersList: true,
        report: false,
        resetAll: false,
      });
    } else if (role === ROLE_ACCOUNTANT || role === ROLE_CONSULTANT) {
      setPermission({
        addCustomer: false,
        addBlinds: false,
        addAccessories: false,
        addCustomeOrder: false,
        checkout: false,
        customersList: false,
        ordersList: false,
        report: true,
        resetAll: false,
      });
    } else if (role === ROLE_CUSTOMER) {
      setPermission({
        addCustomer: false,
        addBlinds: false,
        addAccessories: false,
        addCustomeOrder: false,

        checkout: false,
        customersList: false,
        ordersList: false,
        report: false,
        resetAll: false,
      });
    } else if (role === ROLE_SUPPLIER) {
      setPermission({
        addCustomer: false,
        addBlinds: false,
        addAccessories: false,
        addCustomeOrder: false,
        checkout: false,
        customersList: false,
        ordersList: true,
        report: true,
        resetAll: false,
      });
    }
  };

  useEffect(() => {
    permissionHandler(authenticatedUser.role);
  }, [authenticatedUser]);

  const onResetHandler = () => {
    setResetData(true);
  };
  return (
    <div className="menu ">
      <div className="menu-container">
        {permission.addCustomer && (
          <Link to="/customer" className="menu-button">
            <i className="fa fa-user"></i>
            Add Customer
          </Link>
        )}

        {permission.addBlinds && (
          <Link to="/order" className="menu-button">
            <i className="fa-solid fa-person-booth"></i>
            Add Blinds
          </Link>
        )}

        {permission.addAccessories && (
          <Link to="/accessories" className="menu-button">
            <i className="fa-solid fa-mobile-retro"></i>
            Add Accessories
          </Link>
        )}

        {permission.addCustomeOrder && (
          <Link to="/custome" className="menu-button">
            <i className="fa-solid fa-circle-nodes"></i> Add Custom Item
          </Link>
        )}

        {permission.checkout && (
          <Link to="/result" className="menu-button">
            <i className="fa fa-file-export"></i>
            Checkout
          </Link>
        )}

        {permission.customersList && (
          <Link to="/customers-list" className="menu-button">
            <i className="fa fa-users"></i>
            Customers List
          </Link>
        )}

        {permission.ordersList && (
          <Link to="/orders-list" className="menu-button">
            <i className="fa fa-list"></i>
            Orders List
          </Link>
        )}

        {permission.report && (
          <Link to="/report" className="menu-button">
            <i className="fa fa-file"></i>
            Report
          </Link>
        )}

        {permission.resetAll && (
          <Link to="/" className="menu-button reset-btn" onClick={() => onResetHandler()}>
            <i className="fa fa-refresh"></i>
            Reset All
          </Link>
        )}
      </div>
      <div className="logo">
        <img src={"resources/images/fav-icon.png"} alt="Nili Group"></img>
      </div>
    </div>
  );
};

export default AppMenu;
