import { database, ref, onValue } from "./config";

const readFromDB = (type, id) => {
  return new Promise((resolve, reject) => {
    const starCountRef = ref(database, `${type}`);
    onValue(
      starCountRef,
      (snapshot) => {
        const data = snapshot.val();
        // console.log(data); // Log the data to the console
        resolve(data); // Resolve the promise with the data
      },
      (error) => {
        console.error(error); // Handle the error
        reject(error); // Reject the promise with the error
      }
    );
  });
};

export default readFromDB;
