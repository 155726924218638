import React from "react";
import "../../styles/welcomePage.css";

const WelcomePage = () => {
  return (
    <div className="welcome-container">
      {/* Header Section with Logo */}
      <div className="header-section">
        <img src={"resources/images/nilshades-logo.png"} alt="Nili Group" className="logo" />
        <h1 className="main-title">Smart Blinds & Shades Measurement Application</h1>
        <h2 className="subtitle">Empowering Your Windows with Cutting-Edge Technology</h2>
      </div>

      {/* No Permission Message */}
      <div className="no-permission">
        <h2>Access Denied</h2>
        <p>It looks like you don’t have the necessary permissions to access this feature.</p>
        <p>
          Please contact an administrator at <a href="mailto:info@blindsfactory.ca">info@blindsfactory.ca</a> for
          assistance, or call us at <a href="tel:4375002209">437-500-2209</a>.
        </p>
      </div>
    </div>
  );
};

export default WelcomePage;
