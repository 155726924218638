import {
  getAuth,
  createUserWithEmailAndPassword,
  signOut,
  sendPasswordResetEmail,
  sendEmailVerification,
  signInWithEmailAndPassword,
  applyActionCode,
} from "firebase/auth";

import { app } from "./config";

const actionCodeSettings = {
  url: "https://nilshades.app",
  handleCodeInApp: false,
};

const auth = getAuth(app);

const userSignIn = async (username, password) => {
  try {
    const userCredential = await signInWithEmailAndPassword(auth, username, password);
    const user = userCredential.user;
    return user;
  } catch (error) {
    alert(error.code);
    return null;
  }
};

const userSignOut = async () => {
  try {
    await signOut(auth);
  } catch (error) {
    alert("Sign out Error ");
  }
};

const userSignUp = async (username, password, name) => {
  try {
    const userCredential = await createUserWithEmailAndPassword(auth, username, password);
    const user = userCredential.user;
    user.displayName = name;
    await sendEmailVerification(user, actionCodeSettings);
    alert("Email Verification Sent. Before Login Please Verify Your Email");
    return user;
  } catch (error) {
    alert(error.code);
    return null;
  }
};

const userResetPassword = async (user) => {
  try {
    await sendPasswordResetEmail(user);
    alert("Reset Password Link Sent");
  } catch (error) {
    alert(error.code);
  }
};

const userEmailVerification = async (user) => {
  try {
    // Send email verification with the redirect URL
    await sendEmailVerification(user, actionCodeSettings);
  } catch (error) {
    console.error("Error sending verification email:", error.message);
  }
};

const userEmailVerified = async (oobCode, mode) => {
  // Check if the mode is `verifyEmail` and if the `oobCode` is available
  if (mode === "verifyEmail" && oobCode) {
    // Verify the email by applying the action code
    try {
      await applyActionCode(auth, oobCode);
      alert("Email verified successfully! Please log in.");
    } catch (error) {
      alert("Failed to verify email. The code might be invalid or expired.");
    }
  } else {
    alert("No email verification in progress.");
  }
};

export { userSignIn, userSignOut, userSignUp, userEmailVerified, userResetPassword, userEmailVerification };
