import React from "react";

const ResultFormSigning = ({ formTitle }) => {
  return (
    <div className="sign-area">
      <div className="signing">
        <h4>Blinds Factory.CA</h4>
        <p>Signature</p>
      </div>
      <div className="signing">
        <h4>Customer Agreement</h4>
        <p>Signature</p>
        <p style={{ fontSize: "6px" }}>By confirming this ,I agree with product ordered as reviewed on this form</p>
      </div>
      {formTitle !== "Delivery Note" ? <span>H.S.T.NO. 7288 92506 RT0001</span> : <></>}
    </div>
  );
};

export default ResultFormSigning;
