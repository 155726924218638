import React from "react";

const ResultFormTableHeader = ({ formTitle, info }) => {
  return (
    <>
      <div className="export-header">
        <div className="header-info">
          {/* <h3>
                SALES CONTRACT &nbsp;-&nbsp; P.O.No.&nbsp; {info.customer &&
                  info.customer.quotationNumber}   </h3> */}
          <h3>
            {formTitle === "QUOTATION + SIZE" ? "QUOTATION" : formTitle} &nbsp;&nbsp;|&nbsp; N.O.{" "}
            {info.customer && info.customer.quotationNumber}{" "}
          </h3>
          <div className="info-side">
            <div>
              <strong>Consultation Date :</strong>
              <span> {info.customer && formTitle !== "FACTORY" ? info.customer.counsultationDate : " --- "}</span>
            </div>
            <div>
              <strong> Name :</strong>
              <span> {info.customer && formTitle !== "FACTORY" ? info.customer.name : " --- "}</span>
            </div>
            <div>
              <strong> Order Date :</strong>
              <span> {info.customer && formTitle !== "FACTORY" ? info.customer.orderDate : " --- "}</span>
            </div>
            <div>
              <strong> Address :</strong>
              <span> {info.customer && formTitle !== "FACTORY" ? info.customer.address : " --- "}</span>
            </div>
            <div>
              <strong> Installation Date :</strong>
              <span>
                {" "}
                {info.customer && formTitle !== "FACTORY" ? info.customer.deliveryInstallationDate : " --- "}
              </span>
            </div>
            <div>
              <strong> Postal Code :</strong>
              <span> {info.customer && formTitle !== "FACTORY" ? info.customer.postalCode : " --- "}</span>
            </div>
            <div>
              <strong>Sales Rep :</strong>
              <span> {info.customer && formTitle !== "FACTORY" ? info.customer.salesRep : " --- "}</span>
            </div>
            <div>
              <strong> City :</strong>
              <span> {info.customer && formTitle !== "FACTORY" ? info.customer.city : " --- "}</span>
            </div>
            <div>
              <strong> Refferral Source :</strong>
              <span> {info.customer && formTitle !== "FACTORY" ? info.customer.refferalSource : " --- "}</span>
            </div>
            <div>
              <strong> Email :</strong>
              <span> {info.customer && formTitle !== "FACTORY" ? info.customer.email : " --- "}</span>
            </div>
            <div>
              <strong> Province :</strong>
              <span> {info.customer && formTitle !== "FACTORY" ? info.customer.province : " --- "}</span>
            </div>
            <div>
              <strong> Phone Number :</strong>
              <span> {info.customer && formTitle !== "FACTORY" ? info.customer.phone : " --- "}</span>
            </div>
          </div>
        </div>
        <div className="header-company">
          <img src="resources/images/Blinds-factory-logo.png" alt="Blinds Factory"></img>
          {/* <img src={"resources/images/Nili-group-logo.png"} alt="Nili Group"></img> */}
          <address>767 Creditstone Rd Concord,On Canada L4K5P9</address>
          <div>
            <a href="https://blindsfactory.ca">www.blindsfactory.ca | </a>
            <a href="mailto:info@blindsfactory.ca">info@blindsfactory.ca | </a>
            <a href="tel:+14375002209">+1 (437) 500-2209</a>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResultFormTableHeader;
