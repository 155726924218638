import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import writeToDB from "../../firebase/writeToDB";
import readFromDB from "../../firebase/readFromDB";
import { userSignIn } from "../../firebase/authentication";
const CustomerInfo = ({
  setAutenticatedUser,
  setPoNumber,
  setCustomerInformation,
  customerInformation,
  PoNumber,
  user,
}) => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: "",
    address: "",
    postalCode: "",
    city: "",
    province: "",
    email: "",
    phone: "",
    counsultationDate: "",
    orderDate: "",
    salesRep: PoNumber.userId,
    quotationNumber: "",
    refferalSource: "",
    deliveryInstallationDate: "",
  });

  ///////////////////////////////////////////////////////////////////
  const [editedOrder, setEditedOrder] = useState(false);
  useEffect(() => {
    if (Object.keys(customerInformation).length > 0) {
      if (customerInformation.phone !== "") {
        // console.log(" data inside");
        // console.log("existance order");
        setEditedOrder(true);
        // console.log(customerInformation);
        setFormData({ ...customerInformation });

        // setFormData({ ...customerInformation, salesRep: PoNumber.userId });
      }
    }

    // eslint-disable-next-line
  }, [customerInformation]);

  const poHandler = () => {
    const fetchData = async () => {
      try {
        const data = await readFromDB("counters"); // Await the promise to resolve
        let poCode = data.poCounters;
        writeToDB(poCode + 1, "counters", "poCounters");

        poCode = poCode * 1;
        poCode = poCode + 1001;

        const authenticatedUser = await userSignIn(user.username, user.password);

        // console.log(authenticatedUser);
        // state = true;
        const now = new Date();
        const year = now.getFullYear().toString().slice(-2);
        const month = ("0" + (now.getMonth() + 1)).slice(-2);
        const day = ("0" + now.getDate()).slice(-2);

        const dateTime = `${year}${month}${day}`;

        const newPONumber = `${poCode}-${dateTime}-${PoNumber.userId}`;
        // console.log(formData);
        await setCustomerInformation({ ...formData, quotationNumber: newPONumber });
        setPoNumber({ ...PoNumber, username: authenticatedUser.email, po: poCode, poNumber: newPONumber });
        const userData = await readFromDB(`users/${authenticatedUser.uid}`);
        // console.log(userData);
        setAutenticatedUser({
          role: userData.role,
          userId: userData.userId,
          uid: authenticatedUser.uid,
        });

        // !state && alert("Wrong Username or Password");
      } catch (error) {
        console.error("Error fetching data:", error); // Handle any errors
      }
    };
    fetchData(); // Invoke the async function
  };

  const addNewCustomer = async () => {
    if (editedOrder) {
      try {
        const userDB = {
          id: formData.phone,
          name: formData.name,
          phone: formData.phone,
          email: formData.email,
          postalCode: formData.postalCode,
          address: formData.address,
          province: formData.province,
          city: formData.city,
          salesRep: formData.salesRep,
          quotationNumber: formData.quotationNumber,
          refferalSource: formData.refferalSource,
        };
        // console.log(userDB);
        if (formData.phone && formData.name) {
          writeToDB(userDB, "customers", userDB.phone);
          setCustomerInformation({ ...formData });
          navigate("/result");
        } else {
          alert("Check Fields2");
        }
      } catch (error) {}
    } else {
      try {
        const userDB = {
          id: formData.phone,
          name: formData.name,
          phone: formData.phone,
          email: formData.email,
          postalCode: formData.postalCode,
          address: formData.address,
          province: formData.province,
          city: formData.city,
          salesRep: PoNumber.userId,

          // quotationNumber: formData.quotationNumber,
          refferalSource: formData.refferalSource,
        };
        // console.log(userDB);
        if (formData.phone && formData.name) {
          writeToDB(userDB, "customers", userDB.phone);
          setCustomerInformation({ ...formData, salesRep: PoNumber.userId });
          poHandler();
          navigate("/result");
        } else {
          alert("Check Fields");
        }
      } catch (error) {}
    }
  };

  const formSubmitHandler = (e) => {
    e.preventDefault();
    // getCustomerInfoHandler(formData);
    addNewCustomer();
    setEditedOrder(false);
  };

  const handleInputChange = (e) => {
    const newFormData = { ...formData };
    const { name, value } = e.target;
    if (newFormData.hasOwnProperty(name)) {
      newFormData[name] = value;
    }
    editedOrder ? setFormData({ ...newFormData }) : setFormData({ ...newFormData, salesRep: PoNumber.userId });
  };

  const resetFunctionHandler = () => {
    setFormData({
      ...formData,
      name: "",
      address: "",
      postalCode: "",
      city: "",
      province: "",
      email: "",
      phone: "",
      counsultationDate: "",
      orderDate: "",
      salesRep: PoNumber.userId,
      // quotationNumber: "",
      refferalSource: "",
      deliveryInstallationDate: "",
    });
  };

  return (
    <>
      <form
        className="get-info-form"
        onSubmit={(e) => {
          formSubmitHandler(e);
        }}
      >
        {/* Name */}
        <div className="dropdown-parent">
          <div className="form-input-dropdown-wrapper">
            <input
              className="form-input"
              type="text"
              placeholder="Name"
              name="name"
              autoComplete="off"
              value={formData.name}
              required
              onChange={(e) => {
                handleInputChange(e);
              }}
            ></input>
          </div>
        </div>
        {/* Address */}
        <div className="dropdown-parent">
          <div className="form-input-dropdown-wrapper">
            <input
              className="form-input"
              type="text"
              autoComplete="off"
              placeholder="Address"
              name="address"
              value={formData.address}
              onChange={(e) => {
                handleInputChange(e);
              }}
            ></input>
          </div>
        </div>
        {/* postalcode */}
        <div className="dropdown-parent">
          <div className="form-input-dropdown-wrapper">
            <input
              className="form-input"
              autoComplete="off"
              type="text"
              placeholder="Postal Code"
              maxLength={6}
              name="postalCode"
              value={formData.postalCode}
              onChange={(e) => {
                handleInputChange(e);
              }}
            ></input>
          </div>
        </div>
        {/* city */}
        <div className="dropdown-parent">
          <div className="form-input-dropdown-wrapper">
            <input
              className="form-input"
              type="text"
              autoComplete="off"
              placeholder="City"
              name="city"
              value={formData.city}
              onChange={(e) => {
                handleInputChange(e);
              }}
            ></input>
          </div>
        </div>
        {/* province */}
        <div className="dropdown-parent">
          <div className="form-input-dropdown-wrapper">
            <input
              className="form-input"
              autoComplete="off"
              type="text"
              placeholder="Province"
              name="province"
              value={formData.province}
              onChange={(e) => {
                handleInputChange(e);
              }}
            ></input>
          </div>
        </div>
        {/* email */}
        <div className="dropdown-parent">
          <div className="form-input-dropdown-wrapper">
            <input
              className="form-input"
              autoComplete="off"
              type="text"
              placeholder="Email"
              name="email"
              value={formData.email}
              onChange={(e) => {
                handleInputChange(e);
              }}
            ></input>
          </div>
        </div>
        {/* Phone */}
        <div className="dropdown-parent">
          <div className="form-input-dropdown-wrapper">
            <input
              className="form-input"
              type="text"
              required
              maxLength={10}
              autoComplete="off"
              placeholder="Phone Number"
              name="phone"
              value={formData.phone}
              onChange={(e) => {
                handleInputChange(e);
              }}
            ></input>
          </div>
        </div>
        {/*Sales Rep */}
        <div className="dropdown-parent" style={{ display: "none" }}>
          <div className="form-input-dropdown-wrapper">
            <input
              className="form-input form-input-sales-rep"
              autoComplete="off"
              type="text"
              placeholder="Sales Rep"
              name="salesRep"
              value={PoNumber.username}
              onChange={(e) => {
                handleInputChange(e);
              }}
              disabled
            ></input>
          </div>
        </div>
        {/* Refferal Source */}
        <div className="dropdown-parent">
          <div className="form-input-dropdown-wrapper">
            <input
              className="form-input"
              autoComplete="off"
              type="text"
              placeholder="Refferal Source"
              name="refferalSource"
              value={formData.refferalSource}
              onChange={(e) => {
                handleInputChange(e);
              }}
            ></input>
          </div>
        </div>
        {/* P.O Number */}
        {/* <div className="dropdown-parent">
          <div className="form-input-dropdown-wrapper">
            <input
              className="form-input input-po-number "
              autoComplete="off"
              type="text"
              placeholder=""
              name="quotationNumber"
              value={formData.quotationNumber}
              onChange={(e) => {
                handleInputChange(e);
              }}
              disabled
            ></input>
          </div>
        </div> */}
        {/* Consultaition Date */}
        <div className="dropdown-parent">
          <div className="form-input-dropdown-wrapper form-input-dropdown-wrapper-date">
            <span>Consultaition Date</span>
            <input
              className="form-input"
              type="date"
              autoComplete="off"
              placeholder="Consultaition Date"
              name="counsultationDate"
              value={formData.counsultationDate}
              onChange={(e) => {
                handleInputChange(e);
              }}
            ></input>
          </div>
        </div>
        {/* Order Date */}
        <div className="dropdown-parent">
          <div className="form-input-dropdown-wrapper form-input-dropdown-wrapper-date">
            <span>Order Date</span>
            <input
              className="form-input"
              type="date"
              placeholder="Order Date"
              name="orderDate"
              autoComplete="off"
              value={formData.orderDate}
              onChange={(e) => {
                handleInputChange(e);
              }}
            ></input>
          </div>
        </div>
        {/* Installation Date */}
        <div className="dropdown-parent">
          <div className="form-input-dropdown-wrapper form-input-dropdown-wrapper-date">
            <span>Installation Date</span>
            <input
              className="form-input"
              autoComplete="off"
              type="date"
              placeholder="Installation Date"
              name="deliveryInstallationDate"
              value={formData.deliveryInstallationDate}
              onChange={(e) => {
                handleInputChange(e);
              }}
            ></input>
          </div>
        </div>
        {/* button */}
        <div className="form-button">
          <button className="button-reset" type="reset" onClick={() => resetFunctionHandler()}>
            Clear
          </button>
          <button className="button-submit" type="submit" onClick={(e) => formSubmitHandler(e)}>
            Submit
          </button>
        </div>
      </form>
    </>
  );
};

export default CustomerInfo;
