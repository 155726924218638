import "../../styles/CustomePage.css";
import { useNavigate } from "react-router";

import { useState, useEffect } from "react";
const Custome = ({
  customeOrderInformationHandler,
  editOrderHandlerCustome,
  editOrderCustome,
  setCustomeOrderInformation,
  customeOrderInformation,
}) => {
  // console.log(customeOrderInformation);
  const navigate = useNavigate();

  const inputDataItem = {
    customeitem: {
      requieredState: {
        isRequiered: true,
        theState: false,
      },
      inputData: "",
      id: "",
    },
    quantity: {
      requieredState: {
        isRequiered: true,
        theState: false,
      },
      inputData: "",
      id: "",
    },
    discount: {
      requieredState: {
        isRequiered: false,
        theState: true,
      },
      inputData: "",
      id: "",
    },
    comment: {
      requieredState: {
        isRequiered: false,
        theState: true,
      },
      inputData: "",
      id: "",
    },
    unitPrice: {
      requieredState: {
        isRequiered: true,
        theState: false,
      },
      inputData: "",
      id: "",
    },
    totalPrice: {
      requieredState: {
        isRequiered: false,
        theState: true,
      },
      inputData: "",
      id: "",
    },
  };

  // Edit Order  //////////////////////////////////////////////////////////////////////////
  const [editedOrder, setEditedOrder] = useState(false);
  useEffect(() => {
    if (editOrderCustome.index) {
      setEditedOrder(true);
      // const newInputData = { ...inputData };
      // console.log(newInputData);
      // newInputData.customeitem.inputData = editOrderCustome.customeItem;
      dataSetter("customeitem", editOrderCustome.customeItem, "");
      // newInputData.quantity.inputData = editOrderCustome.quantity;
      dataSetter("quantity", editOrderCustome.quantity, "");

      // newInputData.unitPrice.inputData = editOrderCustome.unitPrice;
      dataSetter("unitPrice", editOrderCustome.unitPrice, "");

      // newInputData.comment.inputData = editOrderCustome.comment;
      dataSetter("comment", editOrderCustome.comment, "");

      // newInputData.discount.inputData = editOrderCustome.discount;
      dataSetter("discount", editOrderCustome.discount, "");

      // newInputData.totalPrice.inputData = editOrderCustome.totalPrice;
      dataSetter("totalPrice", editOrderCustome.totalPrice, "");

      setDisabledItems({
        ustomeitem: true,
        quantity: true,
        unitPrice: true,
        discount: true,
        comment: true,
        totalPrice: true,
      });
      // setInputData({ ...newInputData });
      requiedHandler();
      // setIsRequiered(true);
    }
    // eslint-disable-next-line
  }, [editOrderCustome]);

  // data setter /////////////////////////////////////////////////////////////////////////////////
  const dataSetter = (dataName, dataValue, elementId) => {
    const newInputData = { ...inputData };
    // console.log(dataName);
    newInputData[dataName].inputData = dataValue;
    newInputData[dataName].id = elementId;
    newInputData[dataName].inputData
      ? (newInputData[dataName].requieredState.theState = true)
      : (newInputData[dataName].requieredState.theState = false);
    // console.log(newInputData);
    setInputData(newInputData);
    requiedHandler();
  };

  // element disabler //////////////////////////////////////////////////////////////////////////////
  const disabledItem = {
    customeitem: true,
    quantity: false,
    unitPrice: false,
    discount: false,
    comment: false,
    totalPrice: false,
  };
  const [disabledItems, setDisabledItems] = useState({ ...disabledItem });
  const disableHandler = (item) => {
    // console.log("item", item);
    const newDisabledItems = { ...disabledItems };
    if (item === "customeitem" && inputData.customeitem.inputData) {
      newDisabledItems["quantity"] = true;
    }
    if (item === "quantity" && inputData.quantity.inputData) {
      newDisabledItems["unitPrice"] = true;
    }
    if (item === "unitPrice" && inputData.unitPrice.inputData) {
      newDisabledItems["comment"] = true;
    }
    if (item === "comment" && inputData.comment.inputData) {
      newDisabledItems["totalPrice"] = true;
    }
    if (!inputData.customeitem.inputData) {
      newDisabledItems["quantity"] = false;
    }
    if (!inputData.quantity.inputData) {
      newDisabledItems["unitPrice"] = false;
    }
    if (!inputData.unitPrice.inputData) {
      newDisabledItems["comment"] = false;
    }
    if (!inputData.comment.inputData) {
      newDisabledItems["discount"] = false;
    }
    // console.log("newDisabledItems", newDisabledItems);
    setDisabledItems({ ...newDisabledItems });
  };

  // On Change Handler //////////////////////////////////////////////////
  const [inputData, setInputData] = useState({ ...inputDataItem });
  const onChangeHandler = (e) => {
    const newInputData = { ...inputData };
    dataSetter(e.target.dataset.name, e.target.value, "");
    const totalPrice = priceCalculator(inputData);
    newInputData.totalPrice.inputData = totalPrice.totalPrice;
    disableHandler(e.target.dataset.name);
    setInputData({ ...newInputData });

    // if (newInputData.quantity.inputData === "") {
    //   // newInputData.discount.inputData = "";
    //   setInputData({ ...newInputData });
    //   // dataSetter("discount", "", "");
    // }
  };

  // Reset Handler  //////////////////////////////////////////////////////
  const resetFunctionHandler = () => {
    setIsRequiered(false);
    setInputData({ ...initialInputData });
    setDisabledItems({ ...disabledItem });
  };

  // is require handler + input Data Handler //////////////////////////////////////////////////////
  const initialInputData = { ...inputDataItem };
  const [isRequiered, setIsRequiered] = useState(false);
  const requiedHandler = () => {
    setIsRequiered(true);
    const newInputData = { ...inputData };
    // console.log(newInputData);
    const requierds = Object.keys(newInputData).map((item) => {
      // console.log(newInputData[item]);
      if (newInputData[item].requieredState.isRequiered) {
        return newInputData[item].requieredState.theState;
      } else {
        return true;
      }
    });
    // console.log(requierds);
    requierds.find((item) => {
      if (item === false) {
        setIsRequiered(false);
      }
      return null;
    });
    // console.log(isRequiered)
  };

  // Price Calculator ////////////////////////////////////////////////////
  const priceCalculator = (data) => {
    let totalPrice = 0;
    if (data.discount.inputData * 1 > 0) {
      totalPrice = Math.round(
        data.unitPrice.inputData * data.quantity.inputData -
          (data.unitPrice.inputData * data.quantity.inputData * data.discount.inputData) / 100
      );
    } else {
      totalPrice = Math.round(data.unitPrice.inputData * data.quantity.inputData);
    }
    return { totalPrice };
  };

  // Form Submit Handler ////////////////////////////////////////////////
  const [invoiceData, setInvoiceData] = useState([]);
  const formSubmitHandler = (e) => {
    e.preventDefault();

    requiedHandler();
    if (!isRequiered) {
      alert("Fill all required fields");
    } else {
      if (!editedOrder) {
        setIsRequiered(false);
        // console.log(invoiceData);
        const newInvoiceData = [...invoiceData];
        const newInputData = { ...inputData };
        const price = priceCalculator(newInputData);
        // newInputData.unitPrice.inputData = price.unitPrice;
        newInputData.totalPrice.inputData = price.totalPrice;
        newInvoiceData.push({ ...newInputData });
        setInvoiceData([...newInvoiceData]);
        // console.log(newInvoiceData);
        resetFunctionHandler();
      } else {
        const newCustomeOrderInformation = [...customeOrderInformation];
        const newInputData = { ...inputData };
        const price = priceCalculator(newInputData);
        // newInputData.unitPrice.inputData = price.unitPrice;
        console.log(newCustomeOrderInformation);

        newInputData.totalPrice.inputData = price.totalPrice;
        newCustomeOrderInformation[editOrderCustome.index * 1] = { ...newInputData };

        setCustomeOrderInformation([...newCustomeOrderInformation]);

        editOrderHandlerCustome({
          customeitem: "",
          quantity: "",
          unitPrice: "",
          discount: "",
          comment: "",
          totalPrice: "",
        });
        setEditedOrder(false);
        navigate("/result");
      }
    }
  };

  useEffect(() => {
    if (invoiceData.length > 0) {
      customeOrderInformationHandler(invoiceData);
      setInvoiceData([]);
    }
    // eslint-disable-next-line
  }, [invoiceData]);

  return (
    <form className="custome-wrapper">
      {/* Title */}
      <input
        data-name="customeitem"
        required
        className="form-input"
        type="text"
        placeholder="Title"
        value={inputData.customeitem.inputData}
        data-id={inputData.customeitem.id}
        onChange={(e) => {
          onChangeHandler(e);
        }}
      ></input>
      {/* Quantity */}
      <input
        data-name="quantity"
        className="form-input"
        type="number"
        required
        placeholder="Quantity"
        value={inputData.quantity.inputData}
        data-id={inputData.quantity.id}
        onChange={(e) => {
          onChangeHandler(e);
        }}
      ></input>
      {/* Fee */}
      <input
        data-name="unitPrice"
        className="form-input"
        type="number"
        required
        placeholder="Unit Price"
        value={inputData.unitPrice.inputData}
        data-id={inputData.unitPrice.id}
        onChange={(e) => {
          onChangeHandler(e);
        }}
      ></input>
      {/* Comment */}
      <input
        data-name="comment"
        className="form-input"
        type="text"
        placeholder="Comment"
        value={inputData.comment.inputData}
        data-id={inputData.comment.id}
        onChange={(e) => {
          onChangeHandler(e);
        }}
      ></input>
      {/* Discount */}
      <input
        data-name="discount"
        className="form-input"
        type="text"
        placeholder="Discount %"
        value={inputData.discount.inputData}
        data-id={inputData.discount.id}
        onChange={(e) => {
          onChangeHandler(e);
        }}
      ></input>
      {/* Total */}
      <div className="total-price-wrapper">
        $
        <input
          readOnly
          data-name="totalPrice"
          className="form-input total-price"
          type="number"
          placeholder="0"
          value={inputData.totalPrice.inputData}
          data-id={inputData.totalPrice.id}
          onChange={(e) => {
            onChangeHandler(e);
          }}
        ></input>
      </div>
      {/* button */}
      <div className="form-button">
        <button className="button-reset custome-button-cancel" type="reset" onClick={() => resetFunctionHandler()}>
          Clear
        </button>
        <button className="button-submit custome-button-submit" type="submit" onClick={(e) => formSubmitHandler(e)}>
          Submit
        </button>
      </div>
    </form>
  );
};

export default Custome;
