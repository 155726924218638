import { useState, useEffect } from "react";
import readFromDB from "../../firebase/readFromDB";
import "../../styles/customersList.css";
const CustomersList = () => {
  const [userData, setUserData] = useState({});
  // Fetch order data from the database
  const fetchData = async () => {
    try {
      const data = await readFromDB("customers"); // Await the promise to resolve
      if (data) {
        const parsedData = Object.keys(data).reduce((acc, key) => {
          acc[key] = data[key]; // Parse each JSON string
          return acc;
        }, {});
        setUserData(parsedData); // Update state with parsed data
      }
    } catch (error) {
      console.error("Error fetching data:", error); // Handle any errors
    }
  };

  useEffect(() => {
    fetchData(); // Fetch data when the component mounts
  }, []); // Empty dependency array ensures this runs only once when the component mounts

  return (
    <div className="user-list-wrapper">
      <ul className="user-list">
        {Object.keys(userData).map((key) => {
          const user = userData[key];
          return (
            <li key={key}>
              <span className="user-list-name"> {user.name}</span>
              <span className="user-list-phone"> {user.phone}</span>
              <span className="user-list-email"> {user.email}</span>
              <span className="user-list-postal"> {user.postalCode}</span>
              <span className="user-list-city"> {user.city}</span>
              <span className="user-list-province"> {user.province}</span>
              <span className="user-list-address"> {user.address}</span>
              <span className="user-list-salesrep"> {user.salesRep}</span>
              <span className="user-list-refferal"> {user.refferalSource}</span>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default CustomersList;
