import React from "react";

const ResultFormRowData = ({ order, index, formTitle }) => {
  return (
    <tr key={index}>
      <td className="export-fields export-row">{index + 1}</td>
      <td className="export-fields export-area">{order.item}</td>
      <td className="export-fields export-quantity">{order.quantity}</td>
      <td className="export-fields export-width">{formTitle !== "QUOTATION" ? `${order.width}` : "---"}</td>
      <td className="export-fields export-length">{formTitle !== "QUOTATION" ? `${order.length}` : "---"}</td>
      <td className="export-fields export-placement">{order.placements}</td>
      <td className="export-fields export-color">{order.color}</td>
      <td className="export-fields export-manufacture">{order.manufactures}</td>
      <td className="export-fields export-category">{order.categories}</td>
      <td className="export-fields export-light-control">{order.lightControls}</td>
      <td className="export-fields export-fabric">{order.fabrics}</td>
      <td className="export-fields export-controller"> {order.controller}</td>
      <td className="export-fields export-position">{order.positions}</td>
      <td className="export-fields export-cassette-types">{order.cassetteTypes}</td>
      <td className="export-fields export-cassette-color">{order.cassetteColors}</td>
      <td className="export-fields export-layout">{order.layouts}</td>
      <td className="export-fields export-comments">{order.note}</td>
      {formTitle !== "FACTORY" && formTitle !== "Delivery Note" ? (
        <td className="export-fields export-unit-price">{formTitle !== "FACTORY" ? `$${order.unitPrice}` : "---"}</td>
      ) : (
        <></>
      )}
      {formTitle !== "FACTORY" && formTitle !== "Delivery Note" ? (
        <td className="export-fields export-total-price">{formTitle !== "FACTORY" ? `$${order.totalPrice}` : "---"}</td>
      ) : (
        <></>
      )}
    </tr>
  );
};

export default ResultFormRowData;
