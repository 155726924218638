// import { useState } from "react";

// import GetCustomerInfo from "../GetCustomerInfo.js";
// import GetOrderForm from "../GetOrderForm.js";
// import Invoice from '../Invoice.js';
import { useState } from "react";
import { Route, Routes } from "react-router-dom";

import "../../styles/AppMain.css";

import OrderInfo from "../pages/OrderInfo.js";
import CustomerInfo from "../pages/CustomerInfo.js";
import Invoice from "../pages/Invoice.js";
import NotFound from "../pages/NotFound.js";
import Dashboard from "../pages/Dashboard.js";
import Accessories from "../pages/Accessories.js";
import SalesContractResult from "../output/SalesContractResult.js";
import OrderLists from "../pages/OrderLists.js";
import CustomersList from "../pages/CustomersList.js";
import Custome from "../pages/Custome.js";

const AppMain = ({
  PoNumber,
  authenticatedUser,
  setAutenticatedUser,
  setPoNumber,
  resetData,
  setResetData,
  user,
  setUser,
}) => {
  // customer information //////////////////////////////////////////////////
  const [customerInformation, setCustomerInformation] = useState({});

  // order information /////////////////////////////////////////////////////
  const [orderInformation, setOrderInformation] = useState({});
  const orderInformationHandler = (orderInfo) => {
    if (orderInformation.length > 0) {
      const newOrderInfo = [...orderInformation];
      newOrderInfo.push(...orderInfo);
      setOrderInformation(newOrderInfo);
    } else {
      setOrderInformation(orderInfo);
    }
  };

  // other info invoice information ////////////////////////////////////////

  const [otherInformation, setotherInformation] = useState({
    discount: 0,
    payment: 0,
    installation: 250,
    delivery: 0,
    tax: 13,
    paymentFee: 0,
    paymentType: "etransfer",
  });
  const otherInformationHandler = (otherAmounts) => {
    // console.log(otherAmounts);
    setotherInformation(otherAmounts);
  };

  // Accessories invoice info handler
  const [accessoriesInformation, setAccessoriesInformation] = useState({});
  const accessoriesInformationHandler = (accessoriesInfo) => {
    if (accessoriesInformation.length > 0) {
      const newAccessoriesInformationInfo = [...accessoriesInformation];
      newAccessoriesInformationInfo.push(...accessoriesInfo);
      setAccessoriesInformation(newAccessoriesInformationInfo);
    } else {
      setAccessoriesInformation(accessoriesInfo);
    }
  };

  // Custome Order invoice info handler
  const [customeOrderInformation, setCustomeOrderInformation] = useState({});
  const customeOrderInformationHandler = (customeOrderInfo) => {
    if (customeOrderInformation.length > 0) {
      const newCustomeOrderInformation = [...customeOrderInformation];
      newCustomeOrderInformation.push(...customeOrderInfo);
      setCustomeOrderInformation(newCustomeOrderInformation);
    } else {
      setCustomeOrderInformation(customeOrderInfo);
    }
  };

  // Order Status /////////////////////////////////////////////////////////////
  const [status, setStatus] = useState({
    status: "quotation",
    poNumber: null,
    salesRep: PoNumber.userId,
    payment: false,
  });

  // Edit Order  //////////////////////////////////////////////////////////////
  //Blinds
  const [editOrderBlinds, setEditOrderBlinds] = useState({});
  const editOrderHandlerBlinds = (editedOrder) => {
    setEditOrderBlinds(editedOrder);
  };
  // Accessories
  const [editOrderAccessories, setEditOrderAccessories] = useState({});
  const editOrderHandlerAccessories = (editedOrder) => {
    setEditOrderAccessories(editedOrder);
  };
  // Custome Order
  const [editOrderCustome, setEditOrderCustome] = useState({});
  const editOrderHandlerCustome = (editedOrder) => {
    setEditOrderCustome(editedOrder);
  };
  // is new or editedt order checker
  const [editedOrder, setEditedOrder] = useState(false);
  const isEditedOrderHandler = (data) => {
    setEditedOrder(data);
  };

  // table of result information
  const [resultInformation, setResultInformation] = useState({});
  const resultInformationHandler = (data) => {
    setResultInformation(data);
  };

  // Export PDF type //////////////////////////////////////////////////////////
  const [exportType, setExportType] = useState("");

  // Set Uploaded Data  ///////////////////////////////////////////////////////
  const updatedData = (uploadedData) => {
    if (Object.keys(uploadedData).length > 0) {
      setCustomerInformation(uploadedData.customer);
      setOrderInformation(Object.values(uploadedData.order));
      setAccessoriesInformation(Object.values(uploadedData.accessories));

      if (uploadedData?.customeOrder) {
        setCustomeOrderInformation(Object.values(uploadedData.customeOrder));
        // console.log("ploadedData.customeOrder", Object.values(uploadedData.customeOrder));
      } else if (uploadedData?.customeOrdr) {
        setCustomeOrderInformation(Object.values(uploadedData.customeOrdr));
        // console.log("ploadedData.customeOrdr", Object.values(uploadedData.customeOrdr));
      }
      setotherInformation(uploadedData.other);
    }
  };

  // Delete Order ////////////////////////////////////////////////////////////
  const [deletePopup, setDeletePopup] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState({
    index: null,
    type: "",
  });
  const deleteOrder = (index, type) => {
    if (type === "order") {
      setDeletePopup(true);
      setDeleteIndex({
        index: index,
        type: type,
      });
    }
    if (type === "accessory") {
      setDeletePopup(true);
      setDeleteIndex({
        index: index,
        type: type,
      });
    }
    if (type === "custome") {
      setDeletePopup(true);
      setDeleteIndex({
        index: index,
        type: type,
      });
    }
  };

  const deleteClickHandler = (data, index, type) => {
    if (data === "true") {
      if (type === "order") {
        const newOrder = [...orderInformation];
        // console.log(index, type, newOrder);
        newOrder.splice(index, 1);
        setOrderInformation([...newOrder]);
      }
      if (type === "accessory") {
        const newAccessory = [...accessoriesInformation];
        // console.log(index, type, newAccessory);
        newAccessory.splice(index, 1);
        setAccessoriesInformation([...newAccessory]);
      }
      if (type === "custome") {
        const newCustomeOrder = [...customeOrderInformation];
        // console.log(index, type, newCustomeOrder);
        // newCustomeOrder.splice(index, 1);
        // console.log(newCustomeOrder, index);
        // delete newCustomeOrder[Object.keys(newCustomeOrder)[index]];
        // setCustomeOrderInformation(newCustomeOrder);
        newCustomeOrder.splice(index, 1);
        setCustomeOrderInformation([...newCustomeOrder]);
      }
      setDeleteIndex({
        index: null,
        type: "",
      });
      setDeletePopup(false);
    } else if (data === "false") {
      setDeletePopup(false);
    }
  };

  // Order List Selection /////////////////////////////////////////////////////
  const [ordersList, setOrdersList] = useState({
    status: false,
    poNumber: "",
  });

  // JSX //////////////////////////////////////////////////////////////////////
  return (
    <div className="AppMain">
      <div className="delete-popup" style={deletePopup ? { display: "flex" } : { display: "none" }}>
        <span>Are You Sure for Delete?</span>
        <div>
          <button
            data-value="true"
            onClick={(e) => deleteClickHandler(e.target.dataset.value, deleteIndex.index, deleteIndex.type)}
          >
            YES
          </button>
          <button
            data-value="false"
            onClick={(e) => deleteClickHandler(e.target.dataset.value, deleteIndex.index, deleteIndex.type)}
          >
            No
          </button>
        </div>
      </div>
      <Routes>
        <Route
          path="/"
          exact
          element={
            <Dashboard
              setOrderInformation={setOrderInformation}
              setotherInformation={setotherInformation}
              setAccessoriesInformation={setAccessoriesInformation}
              setCustomeOrderInformation={setCustomeOrderInformation}
              setEditOrderBlinds={setEditOrderBlinds}
              setResultInformation={setResultInformation}
              resetData={resetData}
              setResetData={setResetData}
              PoNumber={PoNumber}
              setPoNumber={setPoNumber}
              setStatus={setStatus}
              setCustomerInformation={setCustomerInformation}
            />
          }
        ></Route>
        {/* <Route path="/Window-Calculator" exact element={<Dashboard />}></Route> */}
        <Route
          path="/order"
          exact
          element={
            <OrderInfo
              orderInformationHandler={orderInformationHandler}
              setEditOrderBlinds={setEditOrderBlinds}
              editOrderBlinds={editOrderBlinds}
              setOrderInformation={setOrderInformation}
              orderInformation={orderInformation}
              editedOrder={editedOrder}
              isEditedOrderHandler={isEditedOrderHandler}
            />
          }
        ></Route>
        <Route
          path="/customer"
          exact
          element={
            <CustomerInfo
              editOrderHandlerBlinds={editOrderHandlerBlinds}
              customerInformation={customerInformation}
              setCustomerInformation={setCustomerInformation}
              PoNumber={PoNumber}
              setPoNumber={setPoNumber}
              setAutenticatedUser={setAutenticatedUser}
              user={user}
              setUser={setUser}
            />
          }
        ></Route>
        <Route
          path="/custome"
          exact
          element={
            <Custome
              customeOrderInformationHandler={customeOrderInformationHandler}
              editOrderHandlerCustome={editOrderHandlerCustome}
              editOrderCustome={editOrderCustome}
              setCustomeOrderInformation={setCustomeOrderInformation}
              customeOrderInformation={customeOrderInformation}
            />
          }
        ></Route>
        <Route
          path="/result"
          exact
          element={
            <Invoice
              orderInformation={orderInformation}
              customerInformation={customerInformation}
              otherInformationHandler={otherInformationHandler}
              otherInformation={otherInformation}
              accessoriesInformation={accessoriesInformation}
              editOrderHandlerBlinds={editOrderHandlerBlinds}
              editOrderHandlerAccessories={editOrderHandlerAccessories}
              isEditedOrderHandler={isEditedOrderHandler}
              resultInformationHandler={resultInformationHandler}
              setExportType={setExportType}
              setUpdatedData={updatedData}
              deleteOrder={deleteOrder}
              setOrdersList={setOrdersList}
              ordersList={ordersList}
              status={status}
              customeOrderInformation={customeOrderInformation}
              editOrderHandlerCustome={editOrderHandlerCustome}
              editOrderCustome={editOrderCustome}
              setStatus={setStatus}
              PoNumber={PoNumber}
              resultInformation={resultInformation}
            />
          }
        ></Route>
        <Route
          path="/accessories"
          exact
          element={
            <Accessories
              accessoriesInformationHandler={accessoriesInformationHandler}
              editOrderHandlerAccessories={editOrderHandlerAccessories}
              editOrderAccessories={editOrderAccessories}
              setAccessoriesInformation={setAccessoriesInformation}
              accessoriesInformation={accessoriesInformation}
            />
          }
        ></Route>
        <Route
          path="/orders-list"
          exact
          element={
            <OrderLists
              setStatus={setStatus}
              status={status}
              authenticatedUser={authenticatedUser}
              setOrdersList={setOrdersList}
            />
          }
        ></Route>{" "}
        <Route path="/customers-list" exact element={<CustomersList></CustomersList>}></Route>
        <Route
          path="/export"
          exact
          element={
            <SalesContractResult
              customeOrderInformation={customeOrderInformation}
              accessoriesInformation={accessoriesInformation}
              customerInformation={customerInformation}
              orderInformation={orderInformation}
              otherInformation={otherInformation}
              resultInformation={resultInformation}
              exportType={exportType}
              status={status}
            />
          }
        ></Route>
        <Route path="*" element={<NotFound />}></Route>
      </Routes>
    </div>
  );
};

export default AppMain;
