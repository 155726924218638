import { database, set, ref } from "./config";

const writeToDB = (data, type, id) => {
  if (type) {
    set(ref(database, `${type}/${id}`, id), data);
    // console.log("Send Successed");
  } else {
    alert("Send Failed");
  }
};

export default writeToDB;
