import { useEffect, useState } from "react";
import InvoiceTableHeading from "./InvoiceTableHeading";
import InvoiceTableRowData from "./InvoiceTableRowData";
import "../../styles/InvoiceTable.css";
import InvoiceTableRowDataAccessories from "./InvoiceTableRowDataAccessories";
import InvoiceTableHeadingAccessories from "./InvoiceTableHeadingAccessories";
import InvoiceTableHeadingCustomeOrder from "./InvoiceTableHeadingCustomeOrder";
import InvoiceTableRowCustomeOrder from "./InvoiceTableRowCustomeOrder";
const InvoiceTable = ({
  orderInformation,
  otherAmounts,
  accessoriesInformation,
  customeOrderInformation,
  editOrderHandlerBlinds,
  editOrderBlinds,
  editOrderAccessories,
  editOrderCustome,
  editOrderHandlerAccessories,
  editOrderHandlerCustome,
  isEditedOrderHandler,
  resultInformationHandler,
  deleteOrder,
}) => {
  // Object.keys(customeOrderInformation).map((item) => {
  //   console.log(customeOrderInformation[item]);
  // });
  // console.log(accessoriesInformation);
  useEffect(() => {
    subTotalPriceCalculator(orderInformation, accessoriesInformation, customeOrderInformation);
    // eslint-disable-next-line
  }, [orderInformation, otherAmounts, accessoriesInformation, customeOrderInformation]);

  // Invoice ////////////////////////////////////////////////////////////////////////
  const [invoice, setInvoice] = useState({
    subTotal: 0,
    discount: 0,
    delievery: 0,
    customeItems: 0,
    totalBeforeTax: 0,
    installation: 0,
    accessories: 0,
    tax: 0,
    paymentFee: 0,
    total: 0,
    payment: 0,
    balance: 0,
  });

  // console.log(orderInformation);
  // Sub TOTAL PRICE /////////////////////////////////////////////////////
  const subTotalPriceCalculator = (data, acce, customeOrder) => {
    const newInvoice = { ...invoice };
    // console.log("data", data);
    if (data.length > 0) {
      const total = data.map((price) => {
        return price.totalPrice.inputData;
      });
      // console.log(total);
      const subTotal = total.reduce((sum, price) => {
        return sum + price;
      }, 0);
      // console.log(subTotal);

      newInvoice.subTotal = Math.round(subTotal);
    } else {
      newInvoice.subTotal = 0;
    }

    if (acce.length > 0) {
      const totalac = acce.map((price) => {
        return price.totalPrice.inputData;
      });
      // console.log(total);
      const subTotalac = totalac.reduce((sum, price) => {
        return sum + price;
      }, 0);

      newInvoice.accessories = Math.round(subTotalac);
    } else {
      newInvoice.accessories = 0;
    }

    if (customeOrder.length > 0) {
      const totalcu = customeOrder.map((price) => {
        return price.totalPrice.inputData;
      });
      const subTotalcu = totalcu.reduce((sum, price) => {
        return sum + price;
      }, 0);
      newInvoice.customeItems = Math.round(subTotalcu);
    } else {
      newInvoice.customeItems = 0;
    }

    // console.log(newInvoice.subTotal);
    // discountCalculator(newInvoice.subTotal, newInvoice.customeItems);
    newInvoice.delievery = delieveryCalculator(newInvoice.delievery);
    newInvoice.installation = installationCalculator(newInvoice.installation);
    newInvoice.discount = discountCalculator(newInvoice.subTotal, 0);
    newInvoice.totalBeforeTax =
      newInvoice.subTotal * 1 +
      otherAmounts.installation * 1 +
      otherAmounts.delivery * 1 -
      newInvoice.discount * 1 +
      newInvoice.accessories * 1 +
      newInvoice.customeItems * 1;
    newInvoice.tax = taxCalculator(newInvoice.totalBeforeTax);
    newInvoice.paymentFee = paymentFeeCalculator(newInvoice.totalBeforeTax + newInvoice.tax);
    newInvoice.total = newInvoice.totalBeforeTax + newInvoice.tax + newInvoice.paymentFee;
    newInvoice.payment = paymentCalculator(newInvoice.total);
    newInvoice.balance = newInvoice.total - newInvoice.payment;
    // console.log(newInvoice);
    setInvoice({ ...newInvoice });
  };

  // DISCOUNT /////////////////////////////////////////////////////////////////
  const discountCalculator = (dataOne, dataTwo) => {
    // console.log(data);
    const newInvoice = { ...invoice };
    newInvoice.discount = Math.round(((dataOne * 1 + dataTwo * 1) * otherAmounts.discount) / 100);
    // console.log(newInvoice.discount);
    // console.log(newInvoice);
    // taxCalculator(newInvoice.subTotal);
    setInvoice({ ...newInvoice });
    return newInvoice.discount;
  };

  // Delievery /////////////////////////////////////////////////////////////////
  const delieveryCalculator = (data) => {
    const newInvoice = { ...invoice };
    newInvoice.delievery = Math.round((data * otherAmounts.delivery) / 100);
    // console.log(newInvoice.delievery);
    // console.log(newInvoice);
    setInvoice({ ...newInvoice });
    // return 450;
    return newInvoice.delievery;
  };

  // Installation /////////////////////////////////////////////////////////////////
  const installationCalculator = (data) => {
    const newInvoice = { ...invoice };
    newInvoice.installation = Math.round((data * otherAmounts.installation) / 100);
    // console.log(newInvoice.installation);
    // console.log(newInvoice);
    setInvoice({ ...newInvoice });
    // return 550;
    return newInvoice.installation;
  };

  // HST/GST /////////////////////////////////////////////////////////////////
  const taxCalculator = (data) => {
    // console.log(data);
    const newInvoice = { ...invoice };
    newInvoice.tax = Math.round((data * otherAmounts.tax) / 100);
    // console.log(newInvoice.tax);
    // console.log(newInvoice);

    // setInvoice({ ...newInvoice });
    return newInvoice.tax;
  };

  // Payment Fee /////////////////////////////////////////////////////////////////
  const paymentFeeCalculator = (data) => {
    const newInvoice = { ...invoice };
    newInvoice.paymentFee = Math.round((data * otherAmounts.paymentFee) / 100);
    // console.log(newInvoice.paymentFee);
    // console.log(newInvoice);
    setInvoice({ ...newInvoice });
    return newInvoice.paymentFee;
  };

  //   Payment /////////////////////////////////////////////////////////////////////
  const paymentCalculator = (data) => {
    const newInvoice = { ...invoice };
    newInvoice.payment = Math.round(otherAmounts.payment);
    // console.log("newInvoice.payment:", newInvoice.payment);
    return newInvoice.payment;
  };

  useEffect(() => {
    resultInformationHandler({ ...invoice });
    // eslint-disable-next-line
  }, [invoice]);

  //  JSX ////////////////////////////////////////////////////////////////////////
  return (
    <>
      <div className="info-table ">
        <div className="info-table-container ">
          {/* blinds table */}
          <table className="invoice-table ">
            {orderInformation.length > 0 && (
              <thead>
                <InvoiceTableHeading key="heading" />
              </thead>
            )}
            <tbody>
              {orderInformation.length > 0 ? (
                orderInformation.map((order, index) => (
                  <InvoiceTableRowData
                    orderInformation={order}
                    index={index}
                    key={index + 1}
                    editOrderHandlerBlinds={editOrderHandlerBlinds}
                    editOrderBlinds={editOrderBlinds}
                    isEditedOrderHandler={isEditedOrderHandler}
                    deleteOrder={deleteOrder}
                  />
                ))
              ) : (
                <></>
              )}
            </tbody>

            {accessoriesInformation?.length > 0 && (
              <thead>
                <InvoiceTableHeadingAccessories key="heading" />
              </thead>
            )}

            <tbody>
              {accessoriesInformation?.length > 0 ? (
                accessoriesInformation.map((order, index) => (
                  <InvoiceTableRowDataAccessories
                    accessoriesInformation={order}
                    index={index}
                    key={index + 1}
                    editOrderHandlerAccessories={editOrderHandlerAccessories}
                    deleteOrder={deleteOrder}
                    editOrderAccessories={editOrderAccessories}
                  />
                ))
              ) : (
                <></>
              )}
            </tbody>
            {customeOrderInformation?.length > 0 && (
              <thead>
                <InvoiceTableHeadingCustomeOrder key="heading" />
              </thead>
            )}
            <tbody>
              {customeOrderInformation?.length > 0 ? (
                customeOrderInformation.map((order, index) => (
                  <InvoiceTableRowCustomeOrder
                    customeOrderInfo={order}
                    index={index}
                    key={index + 1}
                    editOrderHandlerCustome={editOrderHandlerCustome}
                    deleteOrder={deleteOrder}
                    editOrderCustome={editOrderCustome}
                  />
                ))
              ) : (
                <></>
              )}
            </tbody>

            {/* {Object.keys(customeOrderInformation).length > 0 && (
              <thead>
                <InvoiceTableHeadingCustomeOrder key="heading" />
              </thead>
            )}
            <tbody>
              {Object.keys(customeOrderInformation).length > 0 ? (
                Object.keys(customeOrderInformation).map((order, index) => (
                  <InvoiceTableRowCustomeOrder
                    customeOrderInfo={customeOrderInformation[order]}
                    index={index}
                    key={index + 1}
                    editOrderCustome={editOrderCustome}
                    deleteOrder={deleteOrder}
                    editOrderHandlerCustome={editOrderHandlerCustome}
                  />
                ))
              ) : (
                <></>
              )}
            </tbody> */}
          </table>

          {/* accessories table */}

          {/* final result */}
          <div className="invoice-result">
            {/* Sub Total */}
            <div className="result-line">
              <div className="result-line-title result-line-title-first">Blinds Sub Total</div>
              <div className="result-line-title result-line-title-second"></div>
              <div className="result-line-title result-line-title-last">${invoice.subTotal}</div>
            </div>
            {/* Custome Items */}
            <div className="result-line">
              <div className="result-line-title result-line-title-first">Custom Items</div>
              <div className="result-line-title result-line-title-second"></div>
              <div className="result-line-title result-line-title-last">${invoice.customeItems}</div>
            </div>
            {/* Delievery */}
            <div className="result-line">
              <div className="result-line-title result-line-title-first">Other</div>
              <div className="result-line-title result-line-title-second"></div>
              <div className="result-line-title result-line-title-last">${otherAmounts.delivery}</div>
            </div>
            {/* Installation */}
            <div className="result-line">
              <div className="result-line-title result-line-title-first">Installation</div>
              <div className="result-line-title result-line-title-second"></div>
              <div className="result-line-title result-line-title-last">${otherAmounts.installation}</div>
            </div>
            {/* Discount */}
            <div className="result-line" style={{ color: "#04A040", fontWeight: "800" }}>
              <div className="result-line-title result-line-title-first">Discount</div>
              <div className="result-line-title result-line-title-second">%{otherAmounts.discount}</div>
              <div className="result-line-title result-line-title-last">${invoice.discount}</div>
            </div>

            {/* Accessories */}
            <div className="result-line">
              <div className="result-line-title result-line-title-first">Accessories</div>
              <div className="result-line-title result-line-title-second"></div>
              <div className="result-line-title result-line-title-last">${invoice.accessories}</div>
            </div>
            {/* Total Before Tax */}
            <div className="result-line">
              <div className="result-line-title result-line-title-first" style={{ fontWeight: 850 }}>
                Total Before Tax
              </div>
              <div className="result-line-title result-line-title-second"></div>
              <div className="result-line-title result-line-title-last" style={{ fontWeight: 850 }}>
                ${invoice.totalBeforeTax}
              </div>
            </div>
            {/* HST/GST */}
            <div className="result-line">
              <div className="result-line-title result-line-title-first">HST/GST</div>
              <div className="result-line-title result-line-title-second">%{otherAmounts.tax}</div>
              <div className="result-line-title result-line-title-last">${invoice.tax} </div>
            </div>
            <div className="result-line" style={{ border: "none" }}>
              <div className="result-line-title result-line-title-first">Payment Fee</div>
              <div className="result-line-title result-line-title-second">%{otherAmounts.paymentFee}</div>
              <div className="result-line-title result-line-title-last">${invoice.paymentFee} </div>
            </div>
            {/* final */}
            <div className="result-divider"></div>
            {/* Total */}
            <div className="result-line">
              <div className="result-line-title result-line-title-first">
                <strong>Total</strong>
              </div>
              <div className="result-line-title result-line-title-second"></div>

              <div className="result-line-title result-line-title-last">
                <strong>${invoice.total}</strong>
              </div>
            </div>
            {/* payment */}
            <div className="result-line">
              <div className="result-line-title result-line-title-first">
                <strong>Payment</strong>
              </div>
              <div className="result-line-title result-line-title-second">
                <strong>%{Math.round((otherAmounts.payment / invoice.total) * 100)}</strong>
              </div>

              <div className="result-line-title result-line-title-last">
                {" "}
                <strong>${invoice.payment}</strong>
              </div>
            </div>
            {/* balance */}
            <div className="result-line" style={{ border: "none", color: "crimson", fontSize: "12px" }}>
              <div className="result-line-title result-line-title-first">
                <strong>Balance Due</strong>
              </div>
              <div className="result-line-title result-line-title-second"></div>

              <div className="result-line-title result-line-title-last">
                <strong>${invoice.balance}</strong>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default InvoiceTable;
