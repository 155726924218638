import "../../styles/InvoiceTableRowData.css";
import { useState } from "react";
import { useNavigate } from "react-router";

const InvoiceTableRowData = ({
  orderInformation,
  index,
  editOrderHandlerBlinds,
  editOrderBlinds,
  isEditedOrderHandler,
  deleteOrder,
}) => {
  const navigate = useNavigate();
  const [rowInfo, setRowInfo] = useState({ ...editOrderBlinds });
  // console.log("orderinfo", orderInformation);
  const onClickHandler = (e) => {
    e.preventDefault();
    // console.log(orderInformation);
    isEditedOrderHandler(true);
    const newRowInfo = { ...rowInfo };
    newRowInfo.index = e.target.dataset.index;
    newRowInfo.area = e.target.dataset.area;
    newRowInfo.quantity = e.target.dataset.quantity;
    newRowInfo.width = e.target.dataset.width;
    newRowInfo.widthDecimals = e.target.dataset.widthdecimals;
    newRowInfo.length = e.target.dataset.length;
    newRowInfo.lengthDecimals = e.target.dataset.lengthdecimals;
    newRowInfo.placements = e.target.dataset.placements;
    newRowInfo.color = e.target.dataset.color;
    newRowInfo.manufactures = e.target.dataset.manufactures;
    newRowInfo.categories = e.target.dataset.categories;
    newRowInfo.lightControls = e.target.dataset.lightcontrols;
    newRowInfo.fabrics = e.target.dataset.fabrics;
    newRowInfo.manuals = e.target.dataset.manuals;
    newRowInfo.motorizeds = e.target.dataset.motorizeds;
    newRowInfo.positions = e.target.dataset.positions;
    newRowInfo.cassetteTypes = e.target.dataset.cassettetypes;
    newRowInfo.cassetteColors = e.target.dataset.cassettecolors;
    newRowInfo.layouts = e.target.dataset.layouts;
    newRowInfo.comment = e.target.dataset.comment;
    newRowInfo.unitPrice = e.target.dataset.unitprice;
    newRowInfo.totalPrice = e.target.dataset.totalprice;

    editOrderHandlerBlinds({ ...newRowInfo });
    setRowInfo({ ...newRowInfo });
    navigate(`/order`);
  };
  // console.log("orderInformation:", orderInformation);

  // Delete order ////////////////////////////////////////////////////////
  const onClickHandlerDelete = (e) => {
    e.preventDefault();
    deleteOrder(e.target.dataset.index, "order");
    // console.log("index:", e.target.dataset.index);
  };

  return (
    <tr className="table-data-parent">
      <td
        className="table-data edit"
        onClick={(e) => {
          onClickHandler(e);
        }}
        data-index={index}
        data-area={orderInformation.area.inputData}
        data-quantity={orderInformation.quantity.inputData}
        data-width={orderInformation.width.inputData}
        data-widthdecimals={orderInformation.widthDecimals.id}
        data-length={orderInformation.length.inputData}
        data-lengthdecimals={orderInformation.lengthDecimals.id}
        data-placements={orderInformation.placements.id}
        data-color={orderInformation.color.inputData}
        data-manufactures={orderInformation.manufactures.id}
        data-categories={orderInformation.categories.id}
        data-lightcontrols={orderInformation.lightControls.id}
        data-fabrics={orderInformation.fabrics.id}
        data-manuals={orderInformation.manuals.id}
        data-motorizeds={orderInformation.motorizeds.id}
        data-positions={orderInformation.positions.id}
        data-cassettetypes={orderInformation.cassetteTypes.id}
        data-cassettecolors={orderInformation.cassetteColors.id}
        data-layouts={orderInformation.layouts.id}
        data-comment={orderInformation.comment.inputData}
        data-unitprice={orderInformation.unitPrice.inputData}
        data-totalprice={orderInformation.totalPrice.inputData}
      >
        <i className=" fa fa-edit edit-icon"></i>
      </td>

      <td className="table-data row">{index + 1}</td>
      <td className="table-data area">{orderInformation.area.inputData}</td>
      <td className="table-data quantity">{orderInformation.quantity.inputData}</td>
      <td className="table-data width">
        {orderInformation.width.inputData}
        {orderInformation.widthDecimals.inputData && `+${orderInformation.widthDecimals.inputData}`}
      </td>

      <td className="table-data length">
        {orderInformation.length.inputData}
        {orderInformation.lengthDecimals.inputData && `+${orderInformation.lengthDecimals.inputData}`}
      </td>
      <td className="table-data placement">{orderInformation.placements.inputData}</td>
      <td className="table-data color">{orderInformation.color.inputData}</td>
      <td className="table-data manufacture">{orderInformation.manufactures.inputData}</td>
      <td className="table-data category">{orderInformation.categories.inputData}</td>
      <td className="table-data lightcontrol">{orderInformation.lightControls.inputData}</td>
      <td className="table-data fabric">{orderInformation.fabrics.inputData}</td>
      <td className="table-data Manual">
        {orderInformation.manuals.inputData
          ? orderInformation.manuals.inputData
          : orderInformation.motorizeds.inputData}
      </td>
      <td className="table-data position">{orderInformation.positions.inputData}</td>
      <td className="table-data cassette-type">{orderInformation.cassetteTypes.inputData}</td>
      <td className="table-data cassette-color">{orderInformation.cassetteColors.inputData}</td>
      <td className="table-data layout">{orderInformation.layouts.inputData}</td>
      <td className="table-data comment">{orderInformation.comment.inputData}</td>
      <td className="table-data unit-price"> $ {orderInformation.unitPrice.inputData}</td>
      <td className="table-data total-price">$ {orderInformation.totalPrice.inputData}</td>
      <td
        className="table-data delete"
        onClick={(e) => {
          onClickHandlerDelete(e);
        }}
        data-index={index}
      >
        <i className=" fa fa-trash edit-icon"></i>
      </td>
    </tr>
  );
};
export default InvoiceTableRowData;
