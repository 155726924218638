import React from "react";

const ResultFormRowHeader = ({ final, formTitle }) => {
  return (
    <thead>
      {final.length > 0 && (
        <tr className="export-table-heading export-table-heading-first">
          <th className="export-fields export-row">Row</th>
          <th className="export-fields export-area">Item/Area</th>
          <th className="export-fields export-quantity">QTY</th>
          <th className="export-fields export-width">Width</th>
          <th className="export-fields export-length">Length</th>
          <th className="export-fields export-placement">PLC</th>
          <th className="export-fields export-color">CLR</th>
          <th className="export-fields export-manufacture">MAF</th>
          <th className="export-fields export-category">TYPE</th>
          <th className="export-fields export-light-control">LC</th>
          <th className="export-fields export-fabric">Fabric</th>
          <th className="export-fields export-controller">Controller</th>
          <th className="export-fields export-position">POS</th>
          <th className="export-fields export-cassette-types">Cassette</th>
          <th className="export-fields export-cassette-color">CST-C</th>
          <th className="export-fields export-layout">Layout</th>
          <th className="export-fields export-comments">Note</th>
          {formTitle !== "FACTORY" && formTitle !== "Delivery Note" ? (
            <th className="export-fields export-unit-price">Unit</th>
          ) : (
            <></>
          )}
          {formTitle !== "FACTORY" && formTitle !== "Delivery Note" ? (
            <th className="export-fields export-total-price">Total</th>
          ) : (
            <></>
          )}
        </tr>
      )}
    </thead>
  );
};

export default ResultFormRowHeader;
