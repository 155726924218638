import "../../styles/CustomerInformation.css";

const CustomerInformation = ({ customerInformation }) => {
  // console.log(customerInformation);

  const customer = { ...customerInformation };
  // console.log(customer);
  return (
    <div className="customer-information">
      <h1 style={{ marginBottom: "15px" }}>Result</h1>

      <div className="information">
        <div className="info-right">
          <span>
            <strong>Consultation Date:</strong>&ensp; {customer.counsultationDate}{" "}
          </span>
          <span>
            <strong>Order Date:</strong> &ensp;{customer.orderDate}
          </span>
          <span>
            <strong>Installation Date:</strong> &ensp;{customer.deliveryInstallationDate}{" "}
          </span>
          <span>
            <strong>Quotation Number:</strong> &ensp; {customer.quotationNumber}{" "}
          </span>
          <span>
            <strong>Sales Rep:</strong> &ensp; {customer.salesRep}{" "}
          </span>

          <span>
            <strong>Refferral Source:</strong>&ensp;{customer.refferalSource}
          </span>
        </div>
        <div className="info-left">
          <span>
            <strong>Name/Company:</strong>&ensp; {customer.name}
          </span>
          <span>
            <strong>Address:</strong> &ensp;{customer.address}
          </span>
          <span>
            <strong>Postal Code:</strong>&ensp; {customer.postalCode}
          </span>
          <span>
            <strong>City:</strong>&ensp;{customer.city}
          </span>
          <span>
            <strong>Province:</strong>&ensp; {customer.province}{" "}
          </span>
          <span>
            <strong>Email:</strong> &ensp; {customer.email}
          </span>
          <span>
            <strong>Phone Number:</strong>&ensp; {customer.phone}
          </span>
        </div>
        {/* <div className="invoice-header-last">
          <img src={logo} alt="logo"></img>
          <p>
            767 Creditstone Rd Concord,On Canada L4K5P9 <br></br>
            Email: info@blindsfactory.ca&ensp; |&ensp; www.blindsfactory.ca&ensp; | &ensp;tel: +1(437) 500-2209
          </p>
        </div> */}
      </div>
    </div>
  );
};

export default CustomerInformation;
